import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useAtom } from 'jotai';
import { useLocation, Link } from "react-router-dom";
import { Button, Table } from 'semantic-ui-react'
import {
  currenciesAtom,
  currencyAtom,
  languageAtom,
  productsByIdAtom,
  webpSupportedAtom,
} from '../state.js';
import { cartAtom } from '../atoms/cartAtom.js';
import usePrice from '../hooks/usePrice.js';
import { primaryThumbnailUrl } from '../utils/thumbnailUrl';
import { formattedPrice } from '../utils/price';

const SideCartRow = ({ item }) => {
  const [productsById] = useAtom(productsByIdAtom);
  const [currencies] = useAtom(currenciesAtom);
  const [currency] = useAtom(currencyAtom);
  const [language] = useAtom(languageAtom);
  const [cart, setCart] = useAtom(cartAtom);
  const [webpSupported] = useAtom(webpSupportedAtom);

  const { calculatedPrice } = usePrice();

  const [dimmerActive, setDimmerActive] = useState(false);

  const thumbnail = (product) => {
    if (product?.images && product?.images?.length > 0) {
      return primaryThumbnailUrl(product.images, webpSupported, product.url);
    } else {
      return '/assets/placeholder.png';
    }
  };

  const handleChangeQuantity = (e, quantity) => {
    e.stopPropagation();
    const cartCopy = { ...cart };
    const cartItem = cartCopy.items?.find(line => line.id === item.id);
    if (cartItem) {
      if (cartItem.quantity + quantity < 0) {
        return;
      } else if (cartItem.quantity + quantity > productsById[item.id]?.stock) {
        cartItem.quantity = productsById[item.id]?.stock;
        cartCopy.uuid = uuidv4();
        cartCopy.checkingOut = false;
        setCart(cartCopy);
        return;
      }
      cartItem.quantity += quantity;
    } else {
      if (!cartCopy.items) {
        cartCopy.items = [];
      }
      cartCopy.items.push({ url: item.id, quantity: quantity });
    }
    cartCopy.uuid = uuidv4();
    cartCopy.checkingOut = false;

    setCart(cartCopy);
  }

  const handleRemoveFromCart = (id) => {
    const cartCopy = { ...cart };
    const cartItem = cartCopy.items?.find(item => item.id === id);
    if (cartItem) {
      cartCopy.items = cartCopy.items.filter(item => item.id !== id);
    }
    cartCopy.uuid = uuidv4();
    cartCopy.checkingOut = false;
    setCart(cartCopy);
  }

  return (
    <Table.Row>
      <Table.Cell textAlign='center' style={{ paddingLeft: '4px', paddingRight: '2px' }} className='no-select'>
        <div style={{ width: '40px', height: '40px', overflow: 'hidden', display: 'inline-block' }}>
          <Link to={'/products/' + item.url}>
          <img
            src={thumbnail(productsById[item.id])}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              transform: 'scale(1.2)',
              transformOrigin: 'center center',
            }}
          />
          </Link>
        </div>
      </Table.Cell>
      <Table.Cell style={{ paddingLeft: '4px', paddingRight: '6px' }}>
        <Link 
          to={'/products/' + productsById[item.id]?.url}
          style={{ 
            color: (item.quantity > productsById[item.id]?.stock) ? 'red' : 'black', 
            textDecoration: (item.quantity > productsById[item.id]?.stock) ? 'line-through' : 'none',
          }}
        >
          <p style={{ marginBottom: '0.2em' }}>{productsById[item.id]?.part_number}</p>
          <p style={{ marginBottom: '0px', fontSize: '0.75em' }}>{productsById[item.id]?.name}</p>
        </Link>
      </Table.Cell>
      <Table.Cell textAlign='center' style={{ margin: '0px', padding: '0px' }}>
        <Button negative circular icon='minus' size='mini' disabled={item.quantity < 1} onClick={(e) => handleChangeQuantity(e, -1)} />
      </Table.Cell>
      <Table.Cell textAlign='center' style={{ margin: '0px', padding: '0px', paddingLeft: '0px' }} className='no-select'>
        {item.quantity}
      </Table.Cell>
      <Table.Cell textAlign='center' style={{ margin: '0px', padding: '0px', paddingLeft: '4px' }}>
        <Button positive circular icon='plus' size='mini' disabled={item.quantity >= productsById[item.id]?.stock} onClick={(e) => handleChangeQuantity(e, 1)} />
      </Table.Cell>
      <Table.Cell textAlign='right' singleLine className='no-select'>
        {item.quantity > 0 ?
          formattedPrice(calculatedPrice(productsById[item.id], item.quantity), currencies[currency]?.symbol, currencies[currency]?.right_side)
          :
          <Button negative size='mini' icon='trash' onClick={() => handleRemoveFromCart(item.id)} />
        }
      </Table.Cell>
    </Table.Row>
  )
}

export default SideCartRow;
