import { atom } from 'jotai';
import {
    productsAtom,
} from '../state.js';
import { userAtom } from './authAtom.js';

export const newProductsAtom = atom((get) => {
    const products = get(productsAtom);
    const user = get(userAtom);

    const newProducts = Object.values(products).filter(product => product.stock > 0 && product.published).sort((a, b) => {
        const aValue = new Date(a.created_at);
        const bValue = new Date(b.created_at);
        return bValue - aValue;
    });

    newProducts.splice(15);

    return newProducts;
});
