import React, { useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { Link, useLocation } from 'react-router-dom';
import { Form, Icon, Image, Input, Label, Menu, Modal, Popup, Segment, Transition } from 'semantic-ui-react'
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { fullBundleFinishedAtom } from '../state.js';
import { cartAtom } from '../atoms/cartAtom.js';
import { searchProductsOptionsAtom } from '../atoms/searchProductsAtom.js';
import { authenticatedAtom, userAtom, userPopupOpenAtom } from '../atoms/authAtom';
import {
  cartVisible,
  cartWidth,
  leftMenuWidth,
  mobileSize,
  responsiveAtom,
} from '../atoms/responsiveAtom';
import MobileSearch from './MobileSearch';
import SearchResults from './SearchResults';
import User from './User';

const TopBar = ({ product }) => {
  const [fullBundleFinished] = useAtom(fullBundleFinishedAtom);
  const [searchProductsOptions, setSearchProductOptions] = useAtom(searchProductsOptionsAtom);
  const [responsive, setResponsive] = useAtom(responsiveAtom);
  const [cart] = useAtom(cartAtom);
  const [cartLabelAnimation, setCartLabelAnimation] = useState(false);
  const [authenticated] = useAtom(authenticatedAtom);
  const [popupSearchResultsOpen, setPopupSearchResultsOpen] = useState(false);
  const [user] = useAtom(userAtom);
  const [userPopupOpen, setUserPopupOpen] = useAtom(userPopupOpenAtom);

  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    setPopupSearchResultsOpen(false);
  }, [location]);

  useEffect(() => {
    setCartLabelAnimation(!cartLabelAnimation);
  }, [cart]);

  if (window.innerWidth < mobileSize) {
    return (
      <Menu fluid inverted attached='top' style={{ position: 'fixed', top: '-1px', height: '66px', borderRadius: 0, zIndex: 1000 }} widths={5}>
        <Menu.Item>
          <Link to='/' onClick={() => setResponsive({ ...responsive, cartVisible: false, leftMenuVisible: false })}>
            <Image src='/assets/logos/latera-symbol.svg' style={{ height: '5vh', objectFit: 'contain' }} />
          </Link>
        </Menu.Item>
        <Menu.Item onClick={() => {
          setResponsive({ ...responsive, cartVisible: false, leftMenuVisible: !responsive.leftMenuVisible });
        }} >
          <Icon name='sidebar' size='big' style={{ margin: '0px' }} />
        </Menu.Item>
        <Modal
          trigger={
            <Menu.Item
              onClick={() => setPopupSearchResultsOpen(!popupSearchResultsOpen)}
            >
              <Icon name='search' size='big' style={{ margin: '0px' }} />
            </Menu.Item>
          }
          closeIcon
          size='fullscreen'
          centered={false}
          open={popupSearchResultsOpen}
          onClose={() => setPopupSearchResultsOpen(false)}
        >
          <Modal.Content style={{ overflow: 'auto', height: `${window.innerHeight - 50}px` }}>
            <MobileSearch />
          </Modal.Content>
        </Modal>
        {/* <Menu.Item onClick={() => setResponsive({ ...responsive, cartVisible: !responsive.cartVisible })} >
          <Flag name='eu' className='medium' style={{ margin: '0px' }} />
        </Menu.Item> */}
        <Modal
          trigger={
            <Menu.Item
              onClick={() => setUserPopupOpen(!userPopupOpen)}
            >
              <Icon name='user' size='big' style={{ margin: '0px' }} />
            </Menu.Item>
          }
          open={userPopupOpen}
          onClose={() => setUserPopupOpen(false)}
          closeIcon={true}
          content={<User />}
        />
        <Menu.Item
          onClick={() => {
            setResponsive({ ...responsive, leftMenuVisible: false, cartVisible: !responsive.cartVisible });
          }}
        // style={{ backgroundColor: responsive.cartVisible ? '#a30311' : 'transparent' }}
        >
          <Icon name='cart' size='big' />
          {cart?.items?.length > 0 && (
            <Transition visible={cartLabelAnimation} animation='jiggle' duration={500}>
              <Label circular color='red' size='mini' style={{ position: 'absolute', top: '10px', right: '10px' }}>{cart?.items?.length}</Label>
            </Transition>
          )}
        </Menu.Item>
      </Menu>
    );
  } else {
    return (
      <Segment inverted attached='top' style={{ position: 'fixed', top: '-1px', height: '101px', borderRadius: 0, zIndex: 1000, border: 'none', borderBottom: '4px solid #e30613' }}>
        <div className="top-header">
          <div className="top-gradient-line left"></div>
          <Link to='/' style={{ margin: '0px', padding: '0px' }}><img src="/assets/logos/latera-logo-white.svg" alt="Logo" style={{ height: '60px' }} /></Link>
          <div className="top-gradient-line right"></div>

          <Menu borderless compact inverted style={{ position: 'fixed', top: '52px', left: '15px' }}>
            <Menu.Item style={{ margin: '0px', padding: '0px' }}>
              <Popup
                trigger={
                  <Form onSubmit={() => setPopupSearchResultsOpen(true)}>
                    <Input
                      icon='search'
                      loading={!fullBundleFinished}
                      placeholder={t('search')}
                      onChange={(e) => {
                        setSearchProductOptions(e.target.value);
                        if (e.target.value === '' && popupSearchResultsOpen) {
                          setPopupSearchResultsOpen(false);
                        }
                        if (e.target.value !== '' && !popupSearchResultsOpen) {
                          setPopupSearchResultsOpen(true);
                        }
                      }}
                      value={searchProductsOptions}
                      onClick={() => setPopupSearchResultsOpen(true)}
                      style={{ margin: '0px', padding: '0px', width: '16em' }}
                    />
                  </Form>
                }
                flowing
                hoverable
                position='bottom left'
                on='focus'
                style={{ maxWidth: '81vw', maxHeight: '67vh', overflow: 'auto' }}
                eventsEnabled={true}
                open={(searchProductsOptions.length > 1) && popupSearchResultsOpen}
                onClose={() => setPopupSearchResultsOpen(false)}
              >
                <SearchResults />
              </Popup>
            </Menu.Item>
          </Menu>

          <Menu borderless compact inverted style={{ position: 'fixed', top: '52px', right: '15px' }}>
            <Popup
              trigger={
                <Menu.Item
                  onClick={() => setUserPopupOpen(!userPopupOpen)}
                  style={{ paddingBottom: '0px', paddingTop: '0px', paddingLeft: '10px', paddingRight: '10px' }}
                >
                  <Icon name='user' size='big' style={{ margin: '0px' }} />
                </Menu.Item>
              }
              content={<User />}
              on='click'
              onClose={() => setUserPopupOpen(false)}
              open={userPopupOpen}
              hideOnScroll
            />
            <Menu.Item
              onClick={() => setResponsive({ ...responsive, cartVisible: !responsive.cartVisible })}
              style={{
                paddingBottom: '0px',
                paddingTop: '0px',
                paddingLeft: '10px',
                paddingRight: '10px',
              }}
            >
              <Icon name='cart' size='big' style={{ margin: '0px' }} />
              {cart?.items?.length > 0 && (
                <Transition visible={cartLabelAnimation} animation='jiggle' duration={500}>
                  <Label circular color='red' size='mini' style={{ position: 'absolute', top: '0px', right: '0px' }}>{cart?.items?.length}</Label>
                </Transition>
              )}
            </Menu.Item>
          </Menu>
        </div>
      </Segment>
    )
  }
};

export default React.memo(TopBar);
