import React from 'react';
import { useAtom } from 'jotai';
import { Table } from 'semantic-ui-react';
import {
} from '../state.js';
import { lowStockProductsAtom, lowStockOptionsAtom } from '../atoms/lowStockProductsAtom.js';
import { userAtom } from '../atoms/authAtom';
import Title from '../components/Title.js';

const AdminInventoryReport = () => {
  const [lowStockProducts] = useAtom(lowStockProductsAtom);
  const [lowStockOptions, setLowStockOptions] = useAtom(lowStockOptionsAtom);
  const [user] = useAtom(userAtom);

  if (!user?.admin) {
    return (
      <></>
    );
  }

  return (
    <>
      <Title title='Inventory Report' />
      <Table selectable striped sortable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              sorted={lowStockOptions.column === 'part_number' ? lowStockOptions.direction : null}
              onClick={() => setLowStockOptions(
                (lowStockOptions) => ({
                  ...lowStockOptions,
                  column: 'part_number',
                  direction: lowStockOptions.direction === 'ascending' ? 'descending' : 'ascending',
                })
              )}
            >
              Part #
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={lowStockOptions.column === 'name' ? lowStockOptions.direction : null}
              onClick={() => setLowStockOptions(
                (lowStockOptions) => ({
                  ...lowStockOptions,
                  column: 'name',
                  direction: lowStockOptions.direction === 'ascending' ? 'descending' : 'ascending',
                })
              )}
            >
              Name
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={lowStockOptions.column === 'location' ? lowStockOptions.direction : null}
              onClick={() => setLowStockOptions(
                (lowStockOptions) => ({
                  ...lowStockOptions,
                  column: 'location',
                  direction: lowStockOptions.direction === 'ascending' ? 'descending' : 'ascending',
                })
              )}
            >
              Location
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={lowStockOptions.column === 'stock' ? lowStockOptions.direction : null}
              onClick={() => setLowStockOptions(
                (lowStockOptions) => ({
                  ...lowStockOptions,
                  column: 'stock',
                  direction: lowStockOptions.direction === 'ascending' ? 'descending' : 'ascending',
                })
              )}
            >
              Stock
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={lowStockOptions.column === 'desired_stock' ? lowStockOptions.direction : null}
              onClick={() => setLowStockOptions(
                (lowStockOptions) => ({
                  ...lowStockOptions,
                  column: 'desired_stock',
                  direction: lowStockOptions.direction === 'ascending' ? 'descending' : 'ascending',
                })
              )}
            >
              Desired
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {lowStockProducts?.map((product) => (
            <Table.Row key={product.id}>
              <Table.Cell>{product.part_number}</Table.Cell>
              <Table.Cell>{product.name}</Table.Cell>
              <Table.Cell>{product.location}</Table.Cell>
              <Table.Cell>{product.stock}</Table.Cell>
              <Table.Cell>{product.desired_stock}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
}

export default AdminInventoryReport;
