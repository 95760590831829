import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useAtom } from 'jotai';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { userAtom } from '../atoms/authAtom.js';
import { Button, Icon, Menu, Table, Dimmer, Loader, Modal, Form } from 'semantic-ui-react';
import ErrorPage from '../ErrorPage.js';
import AdminApiTableForm from './AdminApiTableForm.js';

const AdminApiTable = ({ endpoint, fields, link, DetailComponent, newEnabled }) => {
  const { t } = useTranslation();

  const [user] = useAtom(userAtom);

  const [data, setData] = useState([]);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [formOpen, setFormOpen] = useState(false);
  const [loadError, setLoadError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [object, setObject] = useState({});
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const [search, setSearch] = useState('');
  const [sortField, setSortField] = useState('');
  const [sortOrder, setSortOrder] = useState('');

  const closeForm = () => {
    setFormOpen(false);
  };

  const fetchData = async (params = {}) => {
    setLoading(true);
    try {
      const response = await fetch(`/api/search/${endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          search: search,
          sort: sortField,
          order: sortOrder,
        }),
      });
      const data = await response.json();
      setData(data[endpoint]);
      setLoading(false);
    } catch (error) {
      setLoadError(error);
      setLoading(false);
      console.error('Error fetching data', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [endpoint, refreshTrigger]);

  const handleSearchSubmit = () => {
    setRefreshTrigger(refreshTrigger + 1);
  };

  const handleSort = useCallback((field) => {
    const newSortOrder = sortField === field && sortOrder === 'asc' ? 'desc' : 'asc';
    setSortField(field);
    setSortOrder(newSortOrder);
    setRefreshTrigger(refreshTrigger + 1);
  }, [sortField, sortOrder, refreshTrigger]);

  const TableRows = useMemo(() => {
    return data?.map(item => (
      <Table.Row key={item.id}>
        {fields.filter(field => field.inTable).map(field => {
          let cellContent = item[field.key];
  
          if (field.mapping) {
            const mappedObject = field.mapping.find(mappingItem => mappingItem.id === item[field.key]);
            if (mappedObject) {
              cellContent = mappedObject?.name;
            }
          }

          if (field.type === 'timestamp') {
            if (cellContent) {
              cellContent = new Date(cellContent).toLocaleString();
            } else {
              cellContent = '-';
            }
          }

          if (link) {
            return (
              <Table.Cell key={field.key}>
                <Link to={`/admin/${endpoint}/${item.id}`}>
                  {cellContent}
                </Link>
              </Table.Cell>
            );
          }
          
          return (
            <Table.Cell key={field.key}>
              <a onClick={() => {
                setObject(item);
                setDetailsOpen(true);
              }}>
                {cellContent}
              </a>
            </Table.Cell>
          );
        })}
      </Table.Row>
    ));
  }, [data, fields]);
  
  const TableHeaders = useMemo(() => {
    return fields.filter(field => field.inTable).map(field => (
      <Table.HeaderCell
        key={field.key}
        sorted={sortField === field.key ? (sortOrder === 'asc' ? 'ascending' : 'descending') : null}
        onClick={() => handleSort(field.key)}
      >
        {t(`admin-${endpoint}-${field.key}`, field.label)}
      </Table.HeaderCell>
    ));
  }, [fields, sortField, sortOrder, handleSort, t, endpoint]);

  return (
    <>
      <Menu>
        {newEnabled && (
          <Menu.Item name='add'>
            <Button
              positive
              icon 
              labelPosition='left'
              onClick={() => {
                setObject(null);
                setFormOpen(true)
              }}
            >
              <Icon name='add' />
              {t('new')}
            </Button>
          </Menu.Item>
        )}
        <Menu.Item name='search' position='right'>
          <Form onSubmit={(e) => {
            e.preventDefault();
            handleSearchSubmit();
          }}>
            <Form.Input
              placeholder={t('Search')}
              action={{
                color: 'green',
                icon: 'search',
              }}
              value={search}
              onChange={(event) => {
                setSearch(event.target.value);
              }}
            />
          </Form>
        </Menu.Item>
        <Menu.Item name='refresh'>
          <Button icon color='green' onClick={() => fetchData()}>
            <Icon name='refresh' />
          </Button>
        </Menu.Item>
      </Menu>

      <Dimmer.Dimmable dimmed={loading}>
        <Dimmer active={loading} inverted>
          <Loader>{t('Loading')}</Loader>
        </Dimmer>

        <Table selectable striped sortable fixed>
          <Table.Header>
            <Table.Row>
              {TableHeaders}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {TableRows}
          </Table.Body>
        </Table>
      </Dimmer.Dimmable>

      <Modal open={detailsOpen} onClose={() => setDetailsOpen(false)}>
        <Modal.Header>{t('Details')}</Modal.Header>
        <Modal.Content>
          <DetailComponent object={object} />
        </Modal.Content>
        <Modal.Actions>
          <Button color='black' onClick={() => setDetailsOpen(false)}>
            {t('Close')}
          </Button>
        </Modal.Actions>
      </Modal>

      <AdminApiTableForm
        open={formOpen}
        closeForm={closeForm}
        endpoint={endpoint}
        fields={fields}
        object={object}
        refreshTrigger={fetchData}
      />
    </>
  );
};

export default AdminApiTable;
