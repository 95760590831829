import React, { useEffect, useMemo, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { Link } from 'react-router-dom';
import { useAtom } from 'jotai';
import { useParams } from 'react-router-dom';
import { Breadcrumb, Divider, Grid, Header, Icon, Image, Message, MessageHeader, Segment, Table } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next';
import {
  bundleFinishedAtom,
  languageAtom,
  brandsAtom,
  productsAtom,
  productGroupsAtom,
} from './state.js';
import { filteredProductsOptionsAtom } from './atoms/filteredProductsAtom.js';
import { userAtom } from './atoms/authAtom';
import ProductPageImages from './components/ProductPageImages.js';
import AddToCart from './components/AddToCart.js';
import B2BPrices from './components/B2BPrices.js';
import PriceLabel from './components/PriceLabel.js';
import PriceLabelBig from './components/PriceLabelBig.js';
import ProductAdminMenu from './components/ProductAdminMenu.js';
import ProductDownloads from './components/ProductDownloads.js';
import ProductFits from './components/ProductFits.js';
import ProductList from './components/ProductList.js';

const ProductPage = () => {
  const { t } = useTranslation();
  const { tag } = useParams();

  const [bundleFinished, setBundleFinished] = useAtom(bundleFinishedAtom);
  const [language] = useAtom(languageAtom);

  const [brands] = useAtom(brandsAtom);
  const [products] = useAtom(productsAtom);
  const [productGroups] = useAtom(productGroupsAtom);

  const [filteredProductsOptions, setFilteredProductOptions] = useAtom(filteredProductsOptionsAtom);

  const [user] = useAtom(userAtom);

  const [brand, setBrand] = useState(null);
  const [groups, setGroups] = useState([]);
  const [product, setProduct] = useState(null);
  const [replacedBy, setReplacedBy] = useState(null);

  const [description, setDescription] = useState(null);
  const [inStock, setInStock] = useState(false);

  useEffect(() => {
    if (bundleFinished) {
      unstable_batchedUpdates(() => {
        const product = products[tag];
        if (!product) return;
        setProduct(product);

        if (product.stock > 0) {
          setInStock(true);
        } else {
          setInStock(false);
        }

        if (product.replaced_by) {
          const replaced_by_product = Object.values(products).find(p => p.id === product.replaced_by);
          setReplacedBy(replaced_by_product);
        }

        const groups = Object.values(productGroups).filter(group => product.product_group_ids.includes(group.id));
        setGroups(groups);

        const brand = Object.values(brands).find(brand => brand.id === product?.brand_id);
        setBrand(brand);
        if (product.descriptions && product.descriptions[language]) {
          setDescription(product.descriptions[language]);
        } else if (product.descriptions && product.descriptions[1]) {
          setDescription(product.descriptions[1]);
        } else {
          setDescription(null);
        }

        if (product.primary && filteredProductsOptions.parent_id !== product.id) {
          setFilteredProductOptions({
            search: '',
            sort: 'part_number_ascending',
            brand_id: null,
            parent_id: product.id,
            tag: '',
            sub_tag: '',
            fits: null,
          });
        }
      });
    }
  }, [bundleFinished, tag, brands, products]);

  if (!product) {
    return (<></>)
  }

  const stockStatus = (inStock) => {
    if (inStock) {
      return (
        <span style={{ color: 'green' }}>
          <Icon circular color='green' inverted name='checkmark' />
          {t('in_stock')}
        </span>
      );
    } else {
      return (
        <span style={{ color: 'red' }}>
          <Icon circular color='red' inverted name='close' />
          {t('out_of_stock')}
        </span>
      );
    }
  }

  return (
    <>
      <meta itemProp="sku" content={product?.part_number} />
      <meta itemProp="mpn" content={product?.barcode} />
      <Segment basic textAlign='center'>
        <Image
          src={brand ? `/images/brands/${brand?.id}.png` : null}
          alt={brand ? brand?.name : null}
          centered
          size='big'
        />
        <Header as='h1'>
          {product?.name}
          <Header.Subheader>
            {product?.part_number}
          </Header.Subheader>
        </Header>
        <Breadcrumb>
          {groups?.map((group, index) => {
            return (
              <React.Fragment key={index}>
                <Breadcrumb.Section key={group.tag}>
                  <Link to={`/tags/${group.tag}`}>{group.names[language]}</Link>
                </Breadcrumb.Section>
                {index !== groups.length - 1 && <Breadcrumb.Divider />}
              </React.Fragment>
            )
          })}
        </Breadcrumb>
        <ProductAdminMenu product={product} />
      </Segment>
      <Grid stackable doubling>
        <Grid.Column width={6} textAlign='center' style={{ minWidth: '32vh' }}>
          <Segment>
            <ProductPageImages images={product?.images} productUrl={product?.url} />
          </Segment>
        </Grid.Column>
        <Grid.Column width={4} align='center' style={{ minWidth: '20em' }}>
          <Segment basic compact textAlign='center' style={{ minWidth: '14em' }}>
            {!product.published && <Segment inverted color='red'><Header inverted as='h1' >Not published</Header></Segment>}
            {/* {user?.b2b ? (<B2BPrices product={product} />) : (<PriceLabelBig product={product} color={labelColor} size='massive' />)} */}
            <PriceLabelBig product={product} />
            <Divider hidden />
            {product?.replaced_by != null && product?.replaced_by !== 0 && (
              <Message negative>
                <MessageHeader>{t('replaced_by')}</MessageHeader>
                <p><Link to={`/products/${replacedBy?.url}`}>{replacedBy?.name}</Link></p>
              </Message>
            )}
            <AddToCart product={product} />
          </Segment>
          <Table unstackable>
            <Table.Body>
              <Table.Row>
                <Table.Cell>{t('online')}</Table.Cell>
                <Table.Cell>{stockStatus(inStock)}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>{t('pick_up')}</Table.Cell>
                <Table.Cell>{stockStatus(inStock)}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          {!product.primary && <ProductFits product={product} />}
          {product.downloads && product.downloads.length > 0 && (
            <ProductDownloads product={product} />
          )}
        </Grid.Column>
        <Grid.Column width={6} style={{ minWidth: '26vh' }}>
          <Segment secondary>
            <Header dividing as='h3'>{t('description')}</Header>
            <div dangerouslySetInnerHTML={{ __html: description }} />
          </Segment>
        </Grid.Column>
      </Grid>
      {product?.primary && (
        <>
          <Segment basic textAlign='center'>
            <Header as='h2'>
              {t('related_products')}
            </Header>
          </Segment>
          <ProductList primary={product?.primary} />
        </>
      )}
    </>
  )
}

export default ProductPage;
