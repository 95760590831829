import React, { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { userAtom } from '../atoms/authAtom.js';
import { Button, Modal, Form, Input, Dropdown, Checkbox } from 'semantic-ui-react';

const AdminCustomerDetails = ({ object }) => {
  if (!object) {
    return (
      <></>
    );
  }

  return (
    <>
      <h1>{object.name}</h1>
    </>
  );
}

export default AdminCustomerDetails;
