import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Divider, Form, Grid, Header, Icon, Segment, Step } from 'semantic-ui-react'
import Title from './components/Title';
import RequestOTP from './components/RequestOTP';
import ResetPassword from './components/ResetPassword';

const ResetPasswordPage = ({ }) => {
  const queryClient = useQueryClient()

  return (
    <>
      <Title title="Reset Password" />

      <Step.Group stackable='tablet' fluid style={{ maxWidth: '80em' }}>
        <Step>
          <Icon name='mail' />
          <Step.Content>
            <Step.Description><Header as='h3'>First, request your reset code</Header></Step.Description>
            <Divider />
            <RequestOTP />
          </Step.Content>
        </Step>
        <Step>
          <Icon name='user secret' />
          <Step.Content>
            <Step.Description><Header as='h3'>Second, reset your password</Header></Step.Description>
            <Divider />
            <ResetPassword />
          </Step.Content>
        </Step>
      </Step.Group>
    </>
  )
}

export default ResetPasswordPage;
