import { unstable_batchedUpdates } from 'react-dom';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Form, Message } from 'semantic-ui-react'

const RequestOTP = ({ }) => {
  const [email, setEmail] = useState('');
  const [emailValid, setEmailValid] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const queryClient = useQueryClient();

  async function requestOTPQuery(email) {
    const endpoint = '/password_reset';
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ "email": email })
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    return response.json();
  }

  const mutation = useMutation({
    mutationFn: (data) => requestOTPQuery(data),
    onSuccess: (data) => {
      unstable_batchedUpdates(() => {
        setSuccess(true);
        setError(false);
      })
    },
    onError: (error) => {
      unstable_batchedUpdates(() => {
        setError(true);
        setSuccess(false);
      })
    },
    onLoading: () => {
      unstable_batchedUpdates(() => {
      })
    },
  });

  useEffect(() => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

    if (emailRegex.test(email)) {
      setEmailValid(true);
    } else {
      setEmailValid(false);
    }
  }, [email]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (emailValid) {
      mutation.mutate(email);
    }
  }

  return (
    <Form onSubmit={handleSubmit} style={{ minWidth: '18em', maxWidth: '25em' }}>
      <Message negative hidden={!error} onDismiss={() => setError(false)}>
        <Message.Header>Error</Message.Header>
        <p>Something went wrong. Please try again later.</p>
      </Message>
      <Message positive hidden={!success} onDismiss={() => setSuccess(false)}>
        <Message.Header>Success</Message.Header>
        <p>Check your e-mail for the password reset code.</p>
      </Message>
      <Form.Input
        name='email'
        value={email}
        error={!emailValid}
        onChange={(e) => setEmail(e.target.value)}
        icon='mail'
        iconPosition='left'
        placeholder='E-mail address'
      />
      <Form.Button
        positive
        fluid
        size='large'
        disabled={!emailValid}
        loading={mutation.isLoading}
      >
        Get reset code
      </Form.Button>
    </Form>
  )
}

export default RequestOTP;
