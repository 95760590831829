import { useEffect } from 'react';
import { useAtom } from 'jotai';
import { webpSupportedAtom } from '../state';

const useWebpSupport = () => {
  const [webpSupported, setWebpSupport] = useAtom(webpSupportedAtom);

  useEffect(() => {
    const checkWebpSupport = () => {
      return new Promise((resolve) => {
        const webp = new Image();
        webp.onload = webp.onerror = function () {
          resolve(webp.height === 2);
        };
        webp.src =
          'data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA';
      });
    };

    const testWebpSupport = async () => {
      const support = await checkWebpSupport();
      setWebpSupport(support);
    };

    testWebpSupport();
  }, [webpSupported, setWebpSupport]);
};

export default useWebpSupport;
