import React, { useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useAtom } from 'jotai';
import { useParams } from 'react-router-dom';
import { Card, Dimmer, Form, Header, Input, Segment, Transition, Loader } from 'semantic-ui-react'
import InfiniteScroll from 'react-infinite-scroller';
import {
  bundleFinishedAtom,
  fullBundleFinishedAtom,
  brandsAtom,
  languageAtom,
  productsByIdAtom,
  productGroupsAtom,
  currentProductGroupTagAtom,
} from './state.js';
import { filteredProductsAtom, filteredProductsOptionsAtom } from './atoms/filteredProductsAtom.js';
import { relevantBrandsAtom } from './atoms/relevantBrandsAtom.js';
import ProductList from './components/ProductList.js';
import Title from './components/Title.js';

const TagPage = () => {
  const [bundleFinished, setBundleFinished] = useAtom(bundleFinishedAtom);
  const [fullBundleFinished, setFullBundleFinished] = useAtom(fullBundleFinishedAtom);
  const [language] = useAtom(languageAtom);
  const [productsById, setProductsById] = useAtom(productsByIdAtom);
  const [productGroups, setProductGroups] = useAtom(productGroupsAtom);

  const [group, setGroup] = useState(null);
  const [filteredProducts] = useAtom(filteredProductsAtom);
  const [filteredProductsOptions, setFilteredProductOptions] = useAtom(filteredProductsOptionsAtom);
  const [relevantBrands] = useAtom(relevantBrandsAtom);
  const [itemsCount, setItemsCount] = useState(100);

  const { tag } = useParams();

  useEffect(() => {
    if (bundleFinished) {
      unstable_batchedUpdates(() => {
        setItemsCount(40);
        setGroup(productGroups[tag]);
        setFilteredProductOptions({
          search: '',
          sort: 'part_number_ascending',
          brand_id: null,
          parent_id: null,
          tag: tag || '',
          sub_tag: '',
          fits: null,
        });
      });
    }
  }, [bundleFinished, tag]);

  if (!group) {
    return (<></>)
  }

  return (
    <React.Fragment>
      <Title title={group.names[language]} />
      <Loader size='huge' inline active={!fullBundleFinished} />
      <ProductList />
    </React.Fragment>
  );
};

export default TagPage;
