import React, { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Form, Table } from 'semantic-ui-react';
import {
  currenciesAtom,
  priceListsAtom,
} from '../state.js';

const AdminPriceRow = ({ price, showVat, vatPercentage, purchasePrice }) => {
  const [currencies] = useAtom(currenciesAtom);
  const [priceLists] = useAtom(priceListsAtom);

  const [changeLoading, setChangeLoading] = useState(false);
  const [newPrice, setNewPrice] = useState(price.price);

  const calculatedPrice = (price, vatPercentage) => {
    const calculated = Math.round(price * (1 + (vatPercentage / 100)) * 100) / 100;
    return calculated % 1 === 0 ? calculated.toFixed(0) : calculated.toFixed(2);
  };

  const calculcatedMargin = (price, purchasePrice) => {
    const margin = Math.round(((price - purchasePrice) / price) * 10000) / 100;
    return margin % 1 === 0 ? margin.toFixed(0) : margin.toFixed(2);
  };

  const changePrice = async (data) => {
    const [priceId, v] = data;
    const p = parseFloat(v);
    const response = await fetch(`/api/prices/${priceId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ "price": p }),
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  }

  const changePriceMutation = useMutation({
    mutationFn: changePrice,
    onMutate: (data) => {
      setChangeLoading(true);
    },
    onSuccess: (data) => {
      setChangeLoading(false);
    },
  });

  useEffect(() => {
    setNewPrice(calculatedPrice(price.price, (showVat ? vatPercentage : 0)));
  }, [showVat, vatPercentage, price.price]);

  return (
    <Table.Row
      key={price.id}
    >
      <Table.Cell>{priceLists[price.price_list_id]?.name}</Table.Cell>
      <Table.Cell>{currencies[priceLists[price.price_list_id].currency_id].iso4217}</Table.Cell>
      <Table.Cell>
        {calculatedPrice(price.price, (showVat ? vatPercentage : 0))}
      </Table.Cell>
      <Table.Cell verticalAlign='middle'>
        <Form onSubmit={(e) => {
          e.preventDefault();
          let p = parseFloat(newPrice);
          if (showVat) {
            p = p / (1 + (vatPercentage / 100));
          }
          changePriceMutation.mutate([price.id, p])
        }}>
          <Form.Group inline style={{ margin: '0px'}}>
          <Form.Input
            type='number'
            step='0.01'
            value={newPrice}
            onChange={(e) => setNewPrice(Number(e.target.value))}
            style={{ width: '8em' }}
          />
          <Form.Button
            color='green'
            size='small'
            disabled={newPrice === price.price}
            icon='refresh'
          />
          </Form.Group>
        </Form>
      </Table.Cell>
    </Table.Row>
  );
}

export default AdminPriceRow;
