import React, { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { Link } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Button, Form, Message, Segment } from 'semantic-ui-react'
import {
  countryAtom,
  countriesAtom,
} from './state.js';
import Title from './components/Title';

const RegisterPage = ({ }) => {
  const [country, setCountry] = useAtom(countryAtom);
  const [countries] = useAtom(countriesAtom);

  const [name, setName] = useState('');
  const [nameValid, setNameValid] = useState(false);

  const [email, setEmail] = useState('');
  const [emailValid, setEmailValid] = useState(false);

  const [selectedCountry, setSelectedCountry] = useState('');
  const [countryValid, setCountryValid] = useState(false);

  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);

  const [conflict, setConflict] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const queryClient = useQueryClient()

  const mutation = useMutation(RegisterQuery, {
    onSuccess: (data) => {
      setConflict(false);
      setSuccess(true);
      setError(false);
    },
    onError: (error) => {
      if (error.message === '409') {
        setConflict(true);
        setError(false);
        setSuccess(false);
      } else {
        setConflict(false);
        setError(true);
        setSuccess(false);
      }
    },
  });

  function RegisterQuery() {
    const endpoint = '/register';

    return fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ "name": name, "email": email, "country_id": selectedCountry, "password1": password, "password2": passwordConfirm })
    })
      .then(res => {
        if (!res.ok) {
          throw new Error(res.status);
        }
        return res.json();
      })
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    mutation.mutate();
  }

  useEffect(() => {
    if (name.length > 0) {
      setNameValid(true);
    } else {
      setNameValid(false);
    }
  }, [name]);

  useEffect(() => {
    if (email.length > 0) {
      setEmailValid(true);
    } else {
      setEmailValid(false);
    }
  }, [email]);

  useEffect(() => {
    if (selectedCountry > 0) {
      setCountryValid(true);
    } else {
      setCountryValid(false);
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (password === passwordConfirm && password.length > 0 && passwordConfirm.length > 0) {
      setPasswordsMatch(true);
    } else {
      setPasswordsMatch(false);
    }
  }, [password, passwordConfirm]);

  useEffect(() => {
    if (password.length > 6) {
      setPasswordValid(true);
    } else {
      setPasswordValid(false);
    }
  }, [password]);

  return (
    <>
      <Title title="Register" />
      <Segment style={{ maxWidth: '35em' }}>
        <Form onSubmit={handleSubmit}>
          <Message negative hidden={!conflict} onDismiss={() => setError(false)}>
            <Message.Header>Error</Message.Header>
            <p>You already have an account. They are created automatically when you place an order.</p>
            <p>If you don't remember your password you can <Link to='/reset-password'>reset it</Link>.</p>
          </Message>
          <Message negative hidden={!error} onDismiss={() => setError(false)}>
            <Message.Header>Error</Message.Header>
            <p>Something went wrong. Try again later.</p>
          </Message>
          <Message positive hidden={!success} onDismiss={() => setSuccess(false)}>
            <Message.Header>Success</Message.Header>
            <p>Your account has been successfully registered.</p>
          </Message>
          <Form.Input
            label='Name'
            placeholder='Name'
            value={name}
            onChange={e => setName(e.target.value)}
            error={!nameValid}
          />
          <Form.Input
            label='Email'
            placeholder='Email'
            value={email}
            onChange={e => setEmail(e.target.value)}
            error={!emailValid}
          />
          <Form.Select
            label='Country'
            placeholder='Country'
            options={Object.values(countries)?.map(c => ({ key: c.id, text: c.name, value: c.id })) || []}
            value={selectedCountry}
            onChange={(e, { value }) => setSelectedCountry(value)}
            error={!countryValid}
          />
          <Form.Input
            label='Password'
            placeholder='Password'
            type='password'
            value={password}
            onChange={e => setPassword(e.target.value)}
            error={!passwordValid}
          />
          <Form.Input
            label='Confirm Password'
            placeholder='Confirm Password'
            type='password'
            value={passwordConfirm}
            onChange={e => setPasswordConfirm(e.target.value)}
            error={!passwordsMatch}
          />
          <Button
            positive
            fluid
            type='submit'
            disabled={!(nameValid && emailValid && countryValid && passwordValid && passwordsMatch)}
            loading={mutation.isLoading}
          >
            Register
          </Button>
        </Form>
      </Segment>
    </>
  )
}

export default RegisterPage;
