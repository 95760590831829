import { useEffect } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useAtom } from 'jotai';
import { useQueryClient } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import { showVatAtom } from '../state';
import {
  authenticatedAtom,
  userAtom,
} from '../atoms/authAtom';

const useLoginCheck = () => {
  const [authenticated, setAuthenticated] = useAtom(authenticatedAtom);
  const [showVat, setShowVat] = useAtom(showVatAtom);
  const [user, setUser] = useAtom(userAtom);

  const queryClient = useQueryClient()

  async function postLoginCheck(url) {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    return response.json();
  }

  const loginCheckMutation = useMutation({
    mutationFn: () => postLoginCheck(`/login_check`),
    onSuccess: (data) => {
      unstable_batchedUpdates(() => {
        if (data.success) {
          setUser({
            email: data.email,
            name: data.name,
            company: data.company,
            b2b: data.b2b,
            admin: data.admin,
          });
          setAuthenticated(true);
          if (data.b2b) {
            setShowVat(false);
          }
        }
      });
    },
    onError: (error) => {
      unstable_batchedUpdates(() => {
      })
    },
    onLoading: () => {
      unstable_batchedUpdates(() => {
      })
    },
  });

  useEffect(() => {
    loginCheckMutation.mutate();
  }, []);
};

export default useLoginCheck;
