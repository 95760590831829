import { unstable_batchedUpdates } from 'react-dom';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Form, Message } from 'semantic-ui-react'

const ResetPassword = ({ }) => {
  const [email, setEmail] = useState('');
  const [emailValid, setEmailValid] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [passwordResetCode, setPasswordResetCode] = useState('');
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const queryClient = useQueryClient()

  async function resetPasswordQuery({ email, passwordResetCode, password, passwordConfirm }) {
    const endpoint = '/password_reset';
    const response = await fetch(endpoint, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ "email": email, "cookie": passwordResetCode, "password1": password, "password2": passwordConfirm })
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    return response.json();
  }

  const mutation = useMutation({
    mutationFn: (data) => resetPasswordQuery(data),
    onSuccess: (data) => {
      unstable_batchedUpdates(() => {
        setSuccess(true);
        setError(false);
      })
    },
    onError: (error) => {
      unstable_batchedUpdates(() => {
        setError(true);
        setSuccess(false);
      })
    },
    onLoading: () => {
      unstable_batchedUpdates(() => {
      })
    },
  });

  useEffect(() => {
    if (password === passwordConfirm && password.length > 0 && passwordConfirm.length > 0) {
      setPasswordsMatch(true);
    } else {
      setPasswordsMatch(false);
    }
  }, [password, passwordConfirm]);

  useEffect(() => {
    if (password.length > 6) {
      setPasswordValid(true);
    } else {
      setPasswordValid(false);
    }
  }, [password]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (passwordValid) {
      mutation.mutate({ email, passwordResetCode, password, passwordConfirm });
    }
  }

  return (
    <Form autoComplete="off" onSubmit={handleSubmit} style={{ minWidth: '18em', maxWidth: '25em' }}>
      <Message negative hidden={!error} onDismiss={() => setError(false)}>
        <Message.Header>Error</Message.Header>
        <p>Something went wrong. Make sure the code is correct and at most a day old.</p>
      </Message>
      <Message positive hidden={!success} onDismiss={() => setSuccess(false)}>
        <Message.Header>Success</Message.Header>
        <p>Your password has been successfully changed.</p>
      </Message>
      <Form.Input
        name='email'
        type='email'
        required
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        icon='mail'
        iconPosition='left'
        placeholder='E-mail Address'
      />
      <Form.Input
        autoComplete="one-time-code"
        name='passwordResetCode'
        value={passwordResetCode}
        onChange={(e) => setPasswordResetCode(e.target.value)}
        error={passwordResetCode.length < 6}
        icon='user secret'
        iconPosition='left'
        placeholder='Password Reset Code'
      />
      <Form.Input
        name='password'
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        error={!passwordValid}
        icon='lock'
        iconPosition='left'
        placeholder='New Password'
        type='password'
      />
      <Form.Input
        name='passwordConfirm'
        value={passwordConfirm}
        onChange={(e) => setPasswordConfirm(e.target.value)}
        error={!passwordsMatch}
        icon='lock'
        iconPosition='left'
        placeholder='Confirm Password'
        type='password'
      />
      <Form.Button
        positive
        fluid
        size='large'
        loading={false}
        disabled={!(passwordResetCode?.length > 0 && passwordsMatch && passwordValid)}
      >
        Reset Password
      </Form.Button>
    </Form>
  )
}

export default ResetPassword;
