import { atom } from 'jotai';
import {
    productsAtom,
} from '../state.js';
import { userAtom } from './authAtom.js';

export const promotedProductsAtom = atom((get) => {
    const products = get(productsAtom);
    const user = get(userAtom);

    const promotedProducts = Object.values(products).filter(product => product.stock > 0 && product.published).sort((a, b) => {
        const aValue = a.promoted ? 1 : 0;
        const bValue = b.promoted ? 1 : 0;
        return bValue - aValue;
    });

    promotedProducts.splice(12);

    return promotedProducts;
});
