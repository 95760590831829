import React, { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import {
  suppliersAtom,
} from '../state.js';
import { userAtom } from '../atoms/authAtom';
import { Button, Icon, Menu, Table } from 'semantic-ui-react';
import Title from '../components/Title.js';
import ErrorPage from '../ErrorPage.js';
import AdminApiTable from '../components/AdminApiTable.js';
import AdminPurchaseOrderDetails from '../components/AdminPurchaseOrderDetails.js';

const AdminPurchaseOrders = () => {
  const { t } = useTranslation();

  const [suppliers] = useAtom(suppliersAtom);
  const [user] = useAtom(userAtom);

  if (!user?.admin) {
    return (
      <ErrorPage />
    );
  }

  return (
    <>
      <Title title={t('admin-purchase_orders')} />

      <AdminApiTable
        endpoint='purchase_orders'
        fields={[
          { 
            key: 'id', 
            label: 'ID', 
            type: 'text', 
            inTable: true,
            inForm: false,
          },
          { 
            key: 'supplier_id', 
            label: 'Supplier', 
            type: 'dropdown', 
            inTable: true,
            inForm: true,
            required: true,
            mapping: suppliers,
            options: suppliers?.sort((a, b) => a.name.localeCompare(b.name)).map(supplier => ({ key: supplier.id, value: supplier.id, text: supplier.name })),
          },
          {
            key: 'created_at',
            label: 'Created',
            type: 'timestamp',
            inTable: true,
            inForm: false,
          },
          {
            key: 'ordered_at',
            label: 'Ordered',
            type: 'timestamp',
            inTable: true,
            inForm: false,
          },
        ]}
        link={true}
        // DetailComponent={AdminPurchaseOrderDetails}
        newEnabled={true}
      />
    </>
  );
}

export default AdminPurchaseOrders;
