import { useEffect } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useAtom } from 'jotai';
import { userAtom } from '../atoms/authAtom';
import {
  countriesAtom,
  countryAtom,
  currencyAtom,
  languageAtom,
  showVatAtom,
  specialPricesAtom,
} from '../state.js';

const usePrice = () => {
  const [countries] = useAtom(countriesAtom);
  const [country] = useAtom(countryAtom);
  const [currency] = useAtom(currencyAtom);
  const [language] = useAtom(languageAtom);
  const [showVat] = useAtom(showVatAtom);
  const [specialPrices] = useAtom(specialPricesAtom);
  const [user] = useAtom(userAtom);

  const calculatedPrice = (product, quantity) => {
    let price = null;

    if (specialPrices[product?.id]) {
      price = specialPrices[product?.id];
    } else {
      if (!product?.prices) {
        return null;
      }

      price = product.prices && product.prices[currency] ? product.prices[currency] : null;
    }

    if (quantity === 0) {
      return 0;
    }

    if (showVat && price) {
      if (countries[country]?.vat_percent > 0) {
        price = price * (1 + (countries[country]?.vat_percent / 100)) * (quantity || 1);
      }
    } else {
      price = price * (quantity || 1);
    }

    return price;
  };

  const calculatedRegularPrice = (product, quantity) => {
    let price = null;

    if (!product?.prices) {
      return null;
    }

    price = product.prices && product.prices[currency] ? product.prices[currency] : null;

    if (quantity === 0) {
      return 0;
    }

    if (showVat && price) {
      if (countries[country]?.vat_percent > 0) {
        price = price * (1 + (countries[country]?.vat_percent / 100)) * (quantity || 1);
      }
    }

    return price;
  };

  return {
    calculatedPrice, 
    calculatedRegularPrice,
  };
};

export default usePrice;
