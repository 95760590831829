import React, { useEffect } from 'react';
import { } from 'semantic-ui-react'
import useLoginCheck from '../hooks/useLoginCheck.js';

const LoginCheck = ({ }) => {
  useLoginCheck();

  return (
    <></>
  );
}

export default React.memo(LoginCheck);
