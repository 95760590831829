import { atom } from 'jotai';
import {
  brandsAtom,
  languageAtom,
  productsAtom,
  productGroupsAtom,
  productRelationsAtom,
} from '../state.js';
import { filteredProductsOptionsAtom } from './filteredProductsAtom.js';

export const relevantTagsAtom = atom((get) => {
  const brands = get(brandsAtom);
  const language = get(languageAtom);
  const products = get(productsAtom);
  const productGroups = get(productGroupsAtom);
  const productRelations = get(productRelationsAtom);
  const options = get(filteredProductsOptionsAtom);

  const groupId = options.tag ? productGroups[options.tag]?.id : null;
  const relevantTags = [];
  const relevantGroupIds = [];

  if (options.parent_id) {
    const relations = Object.values(productRelations).filter(relation => relation.parent_product_id === options.parent_id);
    const childIds = relations.map(relation => relation.child_product_id);

    Object.values(products).forEach(product => {
      if (childIds.includes(product.id)) {
        product.product_group_ids.forEach(groupId => {
          if (!relevantGroupIds.includes(groupId)) {
            relevantGroupIds.push(groupId);
          }
        });
      }
    });
  } else {
    Object.values(products).forEach(product => {
      if (groupId && !product.product_group_ids?.includes(groupId)) return;

      product.product_group_ids.forEach(groupId => {
        if (!relevantGroupIds.includes(groupId)) {
          relevantGroupIds.push(groupId);
        }
      });
    });
  }

  relevantGroupIds.forEach(groupId => {
    const productGroup = Object.values(productGroups).find(productGroup => productGroup.id === groupId);
    if (!productGroup) return;

    if (productGroup.related) {
      relevantTags.push(productGroup);
    }
  });

  // Sort the relevant tags array
  relevantTags.sort((a, b) => {
    const aValue = a.names[language] || '';
    const bValue = b.names[language] || '';
    return aValue.localeCompare(bValue);
  });

  return relevantTags;
});

export const relevantTagsSelectAtom = atom((get) => {
  const relevantTags = get(relevantTagsAtom);
  const language = get(languageAtom);

  const relevantTagsSelect = relevantTags.map(group => ({
    key: group.tag,
    text: group.names[language],
    value: group.tag,
  }));

  return relevantTagsSelect;
});
