import React, { useEffect, useRef, useState } from 'react';
import { useAtom } from 'jotai';
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import { Divider, Message, Placeholder } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next';
import {
  checkoutHtmlAtom,
  checkoutErrorAtom,
  checkoutLoadingAtom,
  checkoutSuccessAtom,
} from './state.js';
import { cartAtom } from './atoms/cartAtom.js';
import { userAtom } from './atoms/authAtom';
import Title from './components/Title.js';

const Checkout = ({ }) => {
  const [cart, setCart] = useAtom(cartAtom);
  const [checkoutHtml, setCheckoutHtml] = useAtom(checkoutHtmlAtom);
  const [checkoutLoading, setCheckoutLoading] = useAtom(checkoutLoadingAtom);
  const [checkoutError, setCheckoutError] = useAtom(checkoutErrorAtom);
  const [checkoutSuccess, setCheckoutSuccess] = useAtom(checkoutSuccessAtom);
  const checkoutContainerRef = useRef(null);
  const [user] = useAtom(userAtom);

  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (checkoutHtml && checkoutSuccess && cart?.checkingOut) {
      const checkoutContainer = checkoutContainerRef.current;
      checkoutContainer.innerHTML = checkoutHtml;
      const scriptsTags = checkoutContainer.getElementsByTagName('script');
      for (let i = 0; i < scriptsTags.length; i++) {
        const parentNode = scriptsTags[i].parentNode;
        const newScriptTag = document.createElement('script');
        newScriptTag.type = 'text/javascript';
        newScriptTag.text = scriptsTags[i].text;
        parentNode.removeChild(scriptsTags[i]);
        parentNode.appendChild(newScriptTag);
      }

      window._klarnaCheckout(function (api) {
        api.on({
          'redirect_initiated': function (data) {
            setCart({ "items": [], "uuid": uuidv4(), "checkingOut": false });
          }
        })
      })
    } else {
      const checkoutContainer = checkoutContainerRef.current;
      if (checkoutContainer) {
        checkoutContainer.innerHTML = '';
      }
    }
  }, [checkoutHtml]);

  if (user?.b2b) {
    navigate('/b2b/checkout');
  }

  if (checkoutError) {
    return (
      <Message negative size='big'>
        <Message.Header>{t('something_went_wrong')}</Message.Header>
        <Message.Content>
          {t('try_again_later')}
        </Message.Content>
      </Message>
    )
  }

  if (cart.checkingOut && !checkoutHtml) {
    return (
      <Placeholder fluid>
        <Placeholder.Header image>
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Header>
        <Placeholder.Paragraph>
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Paragraph>
      </Placeholder>
    )
  }

  return (
    <React.Fragment>
      <Title title={t('checkout_title')} />
      <Divider />
      {checkoutError &&
        <Message negative size='big'>
          <Message.Header>{t('something_went_wrong')}</Message.Header>
        </Message>
      }
      {checkoutLoading &&
        <Message info size='big'>
          <Message.Header>{t('checking_out')}</Message.Header>
        </Message>
      }

      {checkoutLoading &&
        <Placeholder fluid>
          <Placeholder.Header image>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Header>
          <Placeholder.Paragraph>
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Paragraph>
        </Placeholder>
      }
      {!checkoutHtml &&
        <Message info size='big'>
          <Message.Header>Start check out using the button in the cart</Message.Header>
        </Message>
      }
      <div ref={checkoutContainerRef} id="klarna-checkout-container"></div>
    </React.Fragment>
  )
}

export default Checkout;
