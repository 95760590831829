import React, { unstable_batchedUpdates, useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Container, Form, Message, Segment } from 'semantic-ui-react'
import {
  countryAtom,
  currencyAtom,
  languageAtom,
  productsByIdAtom,
} from './state';
import { cartAtom } from './atoms/cartAtom';
import { userAtom } from './atoms/authAtom';
import Title from './components/Title.js';
import { use } from 'i18next';

const b2bCheckoutQuery = async (url, method = 'POST', body = null) => {
  const options = {
    method,
    headers: { 'Content-Type': 'application/json' },
    body: body ? JSON.stringify(body) : null,
  };

  const response = await fetch(url, options);
  if (!response.ok) {
    throw new Error('An error occurred while processing your request.');
  }
  return response.json();
};

const B2BCheckout = ({ }) => {
  const [confirmed, setConfirmed] = React.useState(false);
  const [cart, setCart] = useAtom(cartAtom);
  const [country] = useAtom(countryAtom);
  const [currency] = useAtom(currencyAtom);
  const [language] = useAtom(languageAtom);
  const [productsById] = useAtom(productsByIdAtom);
  const [user] = useAtom(userAtom);

  const [error, setError] = useState(null);

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const b2bCheckoutMutation = useMutation({
    mutationFn: (data) => b2bCheckoutQuery('/checkout', 'POST', data),
    onSuccess: (data) => {
      if (data.success) {
        setCart({ "items": [], "checkingOut": false });
        setError(null);
        navigate(`/orders/${data.order?.id}`);
      }
    },
    onError: (error) => {
      setError(error);
    },
  });

  const onSubmit = () => {
    const data = {
      country_id: country,
      currency_id: currency,
      language_id: language,
      cart: cart?.items?.map(item => ({
        product_id: item.id,
        quantity: item.quantity,
      })),
    };

    b2bCheckoutMutation.mutate(data);
  };

  useEffect(() => {
    setError(null);
  }, [cart]);

  if (!user?.b2b) {
    navigate('/checkout');
  }

  return (
    <>
      <Title title="Check Out" />
      <Container text style={{ marginTop: '2em' }}>
        <Message
          error
          hidden={!error}
          header='Error'
          content={error?.message}
        />
        <Segment padded>
          <Form size='big' onSubmit={onSubmit}>
            <Form.Checkbox
              required
              checked={confirmed}
              onChange={() => setConfirmed(!confirmed)}
              label='I confirm that I want to order the contents of my cart.'
            />
            <Form.Button size='big' color='green' disabled={!confirmed}>Place Order</Form.Button>
          </Form>
        </Segment>
      </Container>
    </>
  );
};

export default B2BCheckout;
