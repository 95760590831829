import React, { useEffect, useRef, useState } from 'react';
import { useAtom } from 'jotai';
import { useParams } from 'react-router-dom';
import { Divider, Message } from 'semantic-ui-react'
import { cartAtom } from './atoms/cartAtom.js';
import Title from './components/Title.js';

const OrderConfirmationPage = ({ }) => {
  const [cart, setCart] = useAtom(cartAtom);
  const [confirmationHtml, setConfirmationHtml] = useState(null);
  const [error, setError] = useState(null);
  const confirmationContainerRef = useRef(null);

  const { orderId } = useParams();

  useEffect(() => {
    const fetchConfirmationHtml = async () => {
      const response = await fetch(`/klarna/order-confirmation/${orderId}`);
      if (response.ok) {
        const data = await response.json();
        setError(false);
        setConfirmationHtml(data.html_snippet);
      } else {
        setError(true);
      }
    };
    fetchConfirmationHtml();

    setCart({"items": [], "checkingOut": false});
  }, []);

  useEffect(() => {
    if (confirmationHtml) {
      var confirmationContainer = document.getElementById('klarna-confirmation-container')
      confirmationContainer.innerHTML = confirmationHtml
      var scriptsTags = confirmationContainer.getElementsByTagName('script')
      for (var i = 0; i < scriptsTags.length; i++) {
        var parentNode = scriptsTags[i].parentNode
        var newScriptTag = document.createElement('script')
        newScriptTag.type = 'text/javascript'
        newScriptTag.text = scriptsTags[i].text
        parentNode.removeChild(scriptsTags[i])
        parentNode.appendChild(newScriptTag)
      }
    }
  }, [confirmationHtml]);

  if (error) {
    return (
      <Message negative size='big'>
        <Message.Header>Something went wrong</Message.Header>
        <Message.Content>
          Please try again later
        </Message.Content>
      </Message>
    )
  }

  return (
    <>
      <Title title="Order Confirmation" />
      <Divider />
      <div ref={confirmationContainerRef} id="klarna-confirmation-container"></div>
    </>
  )
}

export default OrderConfirmationPage;
