import { atom } from 'jotai';
import { 
  currencyAtom,
  productsByIdAtom, 
  productGroupsAtom, 
  productRelationsAtom,
} from '../state.js';

export const filteredProductsOptionsAtom = atom({
  search: '',
  sort: 'part_number_ascending',
  brand_id: null,
  parent_id: null,
  tag: '',
});

export const filteredProductsAtom = atom((get) => {
  const currency = get(currencyAtom);
  const productsById = get(productsByIdAtom);
  const productGroups = get(productGroupsAtom);
  const productRelations = get(productRelationsAtom);
  const options = get(filteredProductsOptionsAtom);
  const groupId = options.tag ? productGroups[options.tag]?.id : null;
  const subGroupId = options.sub_tag ? productGroups[options.sub_tag]?.id : null;

  let filteredProducts = [];

  if (options.parent_id) {
    const relations = Object.values(productRelations).filter(relation => relation.parent_product_id === options.parent_id);
    relations.forEach(relation => {
      filteredProducts.push(productsById[relation.child_product_id]);
    });
  } else if (options.fits) {
    productRelations.filter(relation => relation.parent_product_id === options.fits).forEach(relation => {
      filteredProducts.push(productsById[relation.child_product_id]);
    });
  } else {
    filteredProducts = Object.values(productsById);
  }

  const combinedProducts = [];

  // Filter and categorize products
  filteredProducts.forEach(product => {
    if (groupId && !product.product_group_ids?.includes(groupId)) return;
    if (subGroupId && !product.product_group_ids?.includes(subGroupId)) return;
    if (options.brand_id && product.brand_id !== options.brand_id) return;

    const lowerCasePartNumber = product.part_number?.toLowerCase() || '';
    const lowerCaseName = product.name?.toLowerCase() || '';
    const lowerCaseBrand = product.brand_name?.toLowerCase().split(/[- ]/)[0] || '';

    if (options.search) {
      const searchWords = options.search.toLowerCase().split(' ');
      let allWordsIncluded = true;
      searchWords.forEach(word => {
        if (!lowerCasePartNumber.includes(word) && !lowerCaseName.includes(word) && !lowerCaseBrand.includes(word)) {
          allWordsIncluded = false;
        }
      });
      if (!allWordsIncluded) return;
    }

    combinedProducts.push(product);
  });

  // Sort the combined products array
  const sortedProducts = combinedProducts.sort((a, b) => {
    // Discontinued products should be at the end
    if (a.discontinued && a.stock === 0 && (!b.discontinued || b.stock > 0)) return 1;
    if (b.discontinued && b.stock === 0 && (!a.discontinued || a.stock > 0)) return -1;

    if (options.sort === 'part_number_ascending') {
      const aValue = a.part_number || '';
      const bValue = b.part_number || '';
      return aValue.localeCompare(bValue);
    } else if (options.sort === 'part_number_descending') {
      const aValue = a.part_number || '';
      const bValue = b.part_number || '';
      return bValue.localeCompare(aValue);
    } else if (options.sort === 'name_ascending') {
      const aValue = a.name || '';
      const bValue = b.name || '';
      return aValue.localeCompare(bValue);
    } else if (options.sort === 'name_descending') {
      const aValue = a.name || '';
      const bValue = b.name || '';
      return bValue.localeCompare(aValue);
    } else if (options.sort === 'price_ascending') {
      if (!a.prices || !a.prices[currency]) return 1;
      if (!b.prices || !b.prices[currency]) return -1;
      const aValue = a.prices[currency] || 0;
      const bValue = b.prices[currency] || 0;
      return aValue - bValue;
    } else if (options.sort === 'price_descending') {
      if (!a.prices || !a.prices[currency]) return 1;
      if (!b.prices || !b.prices[currency]) return -1;
      const aValue = a.prices[currency] || 0;
      const bValue = b.prices[currency] || 0;
      return bValue - aValue;
    }

    return 0;
  });

  // Return the sorted products
  return sortedProducts;
});
