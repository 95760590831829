import React, { useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useAtom } from 'jotai';
import { Link } from 'react-router-dom';
import { Button, Card, Dimmer, Divider, Grid, Image, Message, Segment } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next';
import {
  brandsAtom,
  webpSupportedAtom,
} from '../state.js';
import { brandLogoUrl } from '../utils/brandLogoUrl.js';
import { primaryThumbnailUrl } from '../utils/thumbnailUrl.js';
import AddToCart from './AddToCart.js';
import PriceLabel from './PriceLabel.js';

const ProductCard = ({ product }) => {
  const [webpSupported] = useAtom(webpSupportedAtom);
  const [brands] = useAtom(brandsAtom);
  const [brand, setBrand] = useState(null);
  const [inStock, setInStock] = useState(false);
  const [labelColor, setLabelColor] = useState('green');
  const [thumbnail, setThumbnail] = useState(null);
  const [dimmerActive, setDimmerActive] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (brands && Object.keys(brands).length > 0) {
      setBrand(Object.values(brands).filter(b => b.id === product.brand_id)[0]);
    }
  }, [brands]);

  useEffect(() => {
    if (product.images && product.images.length > 0) {
      setThumbnail(primaryThumbnailUrl(product.images, webpSupported, product.url));
    } else {
      setThumbnail('/assets/placeholder.png');
    }
  }, [product?.images]);

  useEffect(() => {
    unstable_batchedUpdates(() => {
      if (product.stock > 0) {
        setInStock(true);
        setLabelColor('green');
      } else {
        setInStock(false);
        setLabelColor('grey');
      }
    });
  }, [product?.stock]);

  return (
    <Dimmer.Dimmable
      blurring
      as={Card}
      key={product.part_number}
      dimmed={dimmerActive}
      onMouseEnter={() => setDimmerActive(true)}
      onMouseLeave={() => setDimmerActive(false)}
      onClick={() => setDimmerActive(!dimmerActive)}
    >
      <Image
        disabled={!inStock}
        src={thumbnail}
        wrapped
        className={!product.published ? 'unpublished' : ''}
        style={{ objectFit: 'cover', backgroundColor: 'white' }}
        label={{
          corner: 'right',
          color: labelColor,
          icon: inStock ? 'check' : 'close',
        }}
      />
      <Card.Content>
        <Card.Header style={{ fontSize: '1.1em' }}>{product.name}</Card.Header>
        <Card.Meta>{product.part_number}</Card.Meta>
      </Card.Content>
      <Card.Content extra>
        <Grid>
          <Grid.Row>
            <Grid.Column stretched width={9}>
              <Image src={brandLogoUrl(product.brand_id)} alt={brand?.name} disabled={!inStock} style={{ height: '2em', objectFit: 'contain' }} />
            </Grid.Column>
            <Grid.Column width={7} textAlign='right'>
              <PriceLabel product={product} color={labelColor} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Card.Content>
      <Dimmer active={dimmerActive} inverted>
        <Link to={`/products/${product.url}`}>
          <Button color='black' size='big' fluid>{t('go_to_product_page')}</Button>
        </Link>
        <Divider />
        {product.stock === 0 ? (
          <Message error>
            <Message.Header>{t('out_of_stock')}</Message.Header>
          </Message>
        ) : (
          <AddToCart product={product} />
        )
        }
      </Dimmer>
    </Dimmer.Dimmable>
  );
}

export default React.memo(ProductCard);
