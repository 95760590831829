import React, { Fragment, useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useAtom } from 'jotai';
import { useParams } from 'react-router-dom';
import { Image } from 'semantic-ui-react'
import ProductList from './components/ProductList.js';
import { 
  brandsAtom, 
  bundleFinishedAtom, 
} from './state.js';
import { filteredProductsOptionsAtom } from './atoms/filteredProductsAtom.js';

const BrandPage = ({ }) => {
  const { tag } = useParams();
  const [bundleFinished] = useAtom(bundleFinishedAtom);
  const [brands] = useAtom(brandsAtom);
  const [brand, setBrand] = useState(null);
  const [filteredProductsOptions, setFilteredProductOptions] = useAtom(filteredProductsOptionsAtom);

  useEffect(() => {
    if (bundleFinished) {
      unstable_batchedUpdates(() => {
        setBrand(brands[tag]);
        setFilteredProductOptions({
          search: '',
          sort: 'part_number_ascending',
          brand_id: brands[tag]?.id,
          parent_id: null,
          tag: '',
          sub_tag: '',
          fits: null,
        });
      });
    }
  }, [bundleFinished, tag]);

  return (
    <React.Fragment>
      <Image
        src={brand ? `/images/brands/${brand.id}.png` : null}
        alt={brand ? brand.name : null}
        centered
        size='big'
        style={{ maxHeight: '200px', objectFit: 'contain' }}
      />
      <ProductList disableFilter={true} />
    </React.Fragment>
  )
}

export default BrandPage;
