// Names will be localized in the future
export const AdminMenu = [
  "add_product",
  // "brands",
  // "customers",
  "inventory_report",
  // "invoices",
  "orders",
  // "product_groups",
  "purchase_orders",
  // "suppliers",
]
