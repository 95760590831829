import React, { useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Dimmer, Grid, Icon, Loader, Message, Segment, Transition } from 'semantic-ui-react';
import AdminProductImage from './AdminProductImage';

const AdminProductImages = ({ product }) => {
  const [uploadError, setUploadError] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadAnimationVisible, setUploadAnimationVisible] = useState(false);

  useEffect(() => {
    setUploadError(null);
    setUploading(false);
  }, [product]);

  const uploadImage = async (data) => {
    const [productId, image] = data;
    const formData = new FormData();
    formData.append('files', image);
    formData.append('product_id', productId);
    const response = await fetch(`/api/product_images`, {
      method: 'POST',
      body: formData,
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  }

  const uploadImageMutation = useMutation({
    mutationFn: uploadImage,
    onMutate: (data) => {
      setUploading(true);
    },
    onError: (error, data, context) => {
      setUploading(false);
      setUploadError(error);
    },
    onSuccess: (data) => {
      setUploadError(null);
      setUploading(false);
      setUploadAnimationVisible(false);
    },
  });

  return (
    <>
    {uploadError && (
      <Message negative>
        <Message.Header>Error</Message.Header>
        <p>{uploadError.message}</p>
      </Message>
    )}
    <Grid stackable>
      <Grid.Column width={4} verticalAlign='middle'>
        <Segment 
          placeholder 
          textAlign='center' 
          style={{ cursor: 'pointer' }}
          onDragOver={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setUploadAnimationVisible(!uploadAnimationVisible);
          }}
          onDrop={(e) => {
            e.preventDefault();
            e.stopPropagation();
            const image = e.dataTransfer.files[0];
            uploadImageMutation.mutate([product.id, image]);
          }}
          onClick={() => {
            const input = document.createElement('input');
            input.type = 'file';
            input.accept = 'image/*';
            input.onchange = (e) => {
              const image = e.target.files[0];
              uploadImageMutation.mutate([product.id, image]);
            };
            input.click();
          }}
        >
          <Dimmer active={uploading}>
            <Loader active={uploading} />
          </Dimmer>
          <Transition visible={!uploadAnimationVisible} animation='pulse' duration={500}>
            <div><Icon name='plus' size='massive' /></div>
          </Transition>
        </Segment>
      </Grid.Column>
      {product?.product_images?.map((image, index) => (
        <Grid.Column key={index} width={4} verticalAlign='middle'>
          <AdminProductImage product={product} image={image} />
        </Grid.Column>
      ))}
    </Grid>
    </>
  );
}

export default AdminProductImages;
