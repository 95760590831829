import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import OrderDetails from './components/OrderDetails.js';

const OrderPage = ({ }) => {
  const { orderId } = useParams();

  return (
    <>
      <OrderDetails orderId={orderId} />
    </>
  );
}

export default OrderPage;
