import React, { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { Form, Grid, Header, Message, Modal, Tab, Table } from 'semantic-ui-react';
import {
  productsByIdAtom,
  productRelationsAtom
} from '../state.js';

const AdminPrimaryProductFitsMultiple = ({ primaryProduct }) => {
  const [productsById] = useAtom(productsByIdAtom);
  const [productRelations] = useAtom(productRelationsAtom);

  const [foundProducts, setFoundProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [missingProducts, setMissingProducts] = useState([]);
  const [value, setValue] = useState('');

  useEffect(() => {
    const partsRegex = /(\d{6}-?[A-Z]{0,2})\s([^\n\r]+)/g;
    const matches = [...value.matchAll(partsRegex)];

    const newFoundProducts = [];
    const newMissingProducts = [];

    for (const match of matches) {
      let parsedNumber = match[1].trim();

      switch (parsedNumber[0]) {
        case '1':
        case '2':
          parsedNumber = `HUDY-${parsedNumber}`;
          break;
        case '3':
        case '4':
        case '5':
          parsedNumber = `XRAY-${parsedNumber}`;
          break;
        case '6':
          parsedNumber = `FX-${parsedNumber}`;
          break;
        case '7':
        case '8':
        case '9':
          parsedNumber = `XRAY-${parsedNumber}`;
          break;
      }

      const product = Object.values(productsById).find(product => product.part_number === parsedNumber);
      if (product) {
        if (newFoundProducts.find(p => p.part_number === product.part_number)) {
          continue;
        }
        // Check if this relation already exists
        if (!productRelations.some(r => r.parent_product_id === primaryProduct.id && r.child_product_id === product.id)) {
          newFoundProducts.push(product);
        }
      } else {
        if (newMissingProducts.find(p => p.partNumber === parsedNumber)) {
          continue;
        }
        newMissingProducts.push({
          partNumber: parsedNumber,
          productName: match[2].trim(),
        });
      }
    }

    newFoundProducts.sort((a, b) => a.part_number.localeCompare(b.part_number));
    newMissingProducts.sort((a, b) => a.partNumber.localeCompare(b.partNumber));

    setFoundProducts(newFoundProducts);
    setMissingProducts(newMissingProducts);
  }, [primaryProduct, value]);

  return (
    <Modal
      size='fullscreen'
      closeIcon
      trigger={<Form.Button positive content='.. or add multiple parts' />}
    >
      <Modal.Header>Add multiple related products</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Grid>
            <Grid.Column width={6}>
              <Form
                loading={loading}
              >
                <Form.TextArea
                  placeholder='Part numbers'
                  required
                  style={{ minHeight: '25em' }}
                  value={value}
                  onChange={(e, { value }) => setValue(value)}
                />
                <Form.Button
                  positive
                  content='Add'
                  disabled={missingProducts.length > 0 || foundProducts.length === 0}
                  onClick={(e) => {
                    e.preventDefault();
                    setLoading(true);
                    const relations = foundProducts.map(product => ({
                      parent_product_id: primaryProduct.id,
                      child_product_id: product.id,
                    }));
                    relations.forEach(relation => {
                      // Does this relation already exist?
                      if (!productRelations.some(r => r.parent_product_id === relation.parent_product_id && r.child_product_id === relation.child_product_id)) {
                        fetch('/api/product_relations', {
                          method: 'POST',
                          headers: {
                            'Content-Type': 'application/json',
                          },
                          body: JSON.stringify(relation),
                        });
                      }
                    });
                    setLoading(false);
                  }}
                />
              </Form>
              {missingProducts.length > 0 && (
                <Message
                  warning
                  header='Part numbers not found'
                />
              )}
            </Grid.Column>
            <Grid.Column width={10}>
              {missingProducts.length > 0 && (
                <Table celled color='red' inverted key='missing-table'>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Part number</Table.HeaderCell>
                      <Table.HeaderCell>Name</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body key='missing-body'>
                    {missingProducts.map(product => (
                      <Table.Row key={`missing-${product.partNumber}`}>
                        <Table.Cell>{product.partNumber}</Table.Cell>
                        <Table.Cell>{product.productName}</Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              )}
              <Table celled key='found-table'>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Part number</Table.HeaderCell>
                    <Table.HeaderCell>Name</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body key='found-body'>
                  {foundProducts.map((fisk, i) => (
                    <Table.Row key={`found-${i}-${fisk.part_number}`}>
                      <Table.Cell>{fisk.part_number}</Table.Cell>
                      <Table.Cell>{fisk.name}</Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  )
}

export default AdminPrimaryProductFitsMultiple;
