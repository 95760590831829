import React, { useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { Accordion, Button, Divider, Icon, Menu, Modal } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import {
} from '../state.js';
import { mobileSize, responsiveAtom } from '../atoms/responsiveAtom.js';
import { userAtom } from '../atoms/authAtom';

import AdminPrimaryProductFits from '../admin/AdminPrimaryProductFits.js';
import AdminProductDescriptions from '../admin/AdminProductDescriptions.js';
import AdminProductDetails from '../admin/AdminProductDetails.js';
import AdminProductDownloads from '../admin/AdminProductDownloads.js';
import AdminProductFits from '../admin/AdminProductFits.js';
import AdminProductImages from '../admin/AdminProductImages.js';
import AdminProductTags from '../admin/AdminProductTags.js';
import AdminInventories from '../admin/AdminInventories.js';
import AdminInventoryLog from '../admin/AdminInventoryLog.js';
import AdminPrices from '../admin/AdminPrices.js';

const ProductAdminMenu = ({ product }) => {
  const [user] = useAtom(userAtom);

  const [menuOpen, setMenuOpen] = useState(window.innerWidth > mobileSize);

  const [descriptionsOpen, setDescriptionsOpen] = useState(false);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [downloadsOpen, setDownloadsOpen] = useState(false);
  const [fitsOpen, setFitsOpen] = useState(false);
  const [tagsOpen, setTagsOpen] = useState(false);
  const [imagesOpen, setImagesOpen] = useState(false);
  const [logOpen, setLogOpen] = useState(false);
  const [pricesOpen, setPricesOpen] = useState(false);
  const [stockOpen, setStockOpen] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
  }, []);

  if (!user?.admin) return null;

  return (
    <>
      <Divider hidden />

      {/* Descriptions */}
      <Modal open={descriptionsOpen} onClose={() => setDescriptionsOpen(false)} closeIcon>
        <Modal.Header>
          {product.part_number} - {product.name}
        </Modal.Header>
        <Modal.Content>
          <AdminProductDescriptions product={product} />
        </Modal.Content>
        <Modal.Actions>
          <Button
            negative
            onClick={() => setDescriptionsOpen(false)}
          >
            Close
          </Button>
        </Modal.Actions>
      </Modal>

      {/* Details */}
      <Modal open={detailsOpen} onClose={() => setDetailsOpen(false)} size='huge' closeIcon>
        <Modal.Header>
          {product.part_number} - {product.name}
        </Modal.Header>
        <Modal.Content>
          <AdminProductDetails product={product} />
        </Modal.Content>
        <Modal.Actions>
          <Button
            negative
            onClick={() => setDetailsOpen(false)}
          >
            Close
          </Button>
        </Modal.Actions>
      </Modal>

      {/* Downloads */}
      <Modal open={downloadsOpen} onClose={() => setDownloadsOpen(false)} closeIcon>
        <Modal.Header>
          {product.part_number} - {product.name}
        </Modal.Header>
        <Modal.Content>
          <AdminProductDownloads product={product} />
        </Modal.Content>
        <Modal.Actions>
          <Button
            negative
            onClick={() => setDownloadsOpen(false)}
          >
            Close
          </Button>
        </Modal.Actions>
      </Modal>

      { /* Fits */}
      <Modal open={fitsOpen} onClose={() => setFitsOpen(false)} closeIcon>
        <Modal.Header>
          {product.part_number} - {product.name}
        </Modal.Header>
        <Modal.Content>
          { product.primary ? <AdminPrimaryProductFits product={product} /> : <AdminProductFits product={product} />}
        </Modal.Content>
        <Modal.Actions>
          <Button
            negative
            onClick={() => setFitsOpen(false)}
          >
            Close
          </Button>
        </Modal.Actions>
      </Modal>

      {/* Groups */}
      <Modal open={tagsOpen} onClose={() => setTagsOpen(false)} closeIcon>
        <Modal.Header>
          {product.part_number} - {product.name}
        </Modal.Header>
        <Modal.Content>
          <AdminProductTags product={product} />
        </Modal.Content>
        <Modal.Actions>
          <Button
            negative
            onClick={() => setTagsOpen(false)}
          >
            Close
          </Button>
        </Modal.Actions>
      </Modal>

      {/* Inventories */}
      <Modal open={stockOpen} onClose={() => setStockOpen(false)} closeIcon>
        <Modal.Header>
          {product.part_number} - {product.name}
        </Modal.Header>
        <Modal.Content>
          <AdminInventories product={product} />
        </Modal.Content>
        <Modal.Actions>
          <Button
            negative
            onClick={() => setStockOpen(false)}
          >
            Close
          </Button>
        </Modal.Actions>
      </Modal>

      { /* Images */}
      <Modal open={imagesOpen} onClose={() => setImagesOpen(false)} size='huge' closeIcon>
        <Modal.Header>
          {product.part_number} - {product.name}
        </Modal.Header>
        <Modal.Content>
          <AdminProductImages product={product} />
        </Modal.Content>
        <Modal.Actions>
          <Button
            negative
            onClick={() => setImagesOpen(false)}
          >
            Close
          </Button>
        </Modal.Actions>
      </Modal>

      {/* Inventory Log */}
      <Modal open={logOpen} onClose={() => setLogOpen(false)} closeIcon>
        <Modal.Header>
          {product.part_number} - {product.name}
        </Modal.Header>
        <Modal.Content>
          <AdminInventoryLog product={product} />
        </Modal.Content>
        <Modal.Actions>
          <Button
            negative
            onClick={() => setLogOpen(false)}
          >
            Close
          </Button>
        </Modal.Actions>
      </Modal>

      {/* Prices */}
      <Modal open={pricesOpen} onClose={() => setPricesOpen(false)} closeIcon>
        <Modal.Header>
          Prices for {product.part_number} - {product.name}
        </Modal.Header>
        <Modal.Content>
          <AdminPrices product={product} />
        </Modal.Content>
      </Modal>

      <Accordion>
        <Accordion.Title
          active={menuOpen}
          onClick={() => setMenuOpen(!menuOpen)}
        >
          <Button
            icon
            labelPosition='right'
          >
            Admin Menu
            <Icon name={(menuOpen ? 'up arrow' : 'down arrow')} />
          </Button>
        </Accordion.Title>
        <Accordion.Content active={menuOpen}>
          <Menu inverted fluid compact icon='labeled' widths={9} stackable size='small'>
            <Menu.Item
              name='edit'
              onClick={() => setDetailsOpen(!detailsOpen)}
            >
              <Icon name='edit' />
              Details
            </Menu.Item>
            <Menu.Item
              name='fits'
              onClick={() => setFitsOpen(!fitsOpen)}
            >
              <Icon name='puzzle' />
              Fits
            </Menu.Item>
            <Menu.Item
              name='edit'
              onClick={() => setTagsOpen(!tagsOpen)}
            >
              <Icon name='tags' />
              Groups
            </Menu.Item>
            <Menu.Item
              name='images'
              onClick={() => setImagesOpen(!imagesOpen)}
            >
              <Icon name='image' />
              Images
            </Menu.Item>
            <Menu.Item
              name='prices'
              onClick={() => setPricesOpen(!pricesOpen)}
            >
              <Icon name='money' />
              Prices
            </Menu.Item>
            <Menu.Item
              name='stock'
              onClick={() => setStockOpen(!stockOpen)}
            >
              <Icon name='box' />
              Stock
            </Menu.Item>
            <Menu.Item
              name='log'
              onClick={() => setLogOpen(!logOpen)}
            >
              <Icon name='history' />
              Log
            </Menu.Item>
            <Menu.Item
              name='descriptions'
              onClick={() => setDescriptionsOpen(!descriptionsOpen)}
            >
              <Icon name='book' />
              Descriptions
            </Menu.Item>
            <Menu.Item
              name='files'
              onClick={() => setDownloadsOpen(!downloadsOpen)}
            >
              <Icon name='attach' />
              Files
            </Menu.Item>
          </Menu>
        </Accordion.Content>
      </Accordion>

      <Divider hidden />
    </>
  );
};

export default React.memo(ProductAdminMenu);
