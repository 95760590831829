import React, { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Header, Segment } from 'semantic-ui-react'
import {
} from './state.js';
import Title from './components/Title';

const TermsPage = ({ }) => {
  const { i18n } = useTranslation();

  const currentLanguage = i18n.language;

  if (currentLanguage === 'sv') {
    return (
      <>
        <Title title='Villkor' />
        <Segment>
          <Header as='h3'>Valuta & moms</Header>
          <p>Priser, valuta, moms samt frakt- och andra eventuella kostnader är alltid tydligt synligt i kassan. Med "kr" menar vi svenska kronor (SEK).</p>
          <Header as='h3'>Bilder, beskrivningar & priser</Header>
          <p>Bilder, beskrivningar och priser kan vara fel. Det händer att produkter uppdateras utan vår vetskap, och då kan en produkt se annorlunda ut än på bilden, eller ha förändrade specifikationer. Vi förbehåller oss rätten att ändra produktinformation, bilder och priser utan föregående avisering.</p>
          <Header as='h3'>Betalning</Header>
          <p>Betalning sker med Klarna. Deras villkor finns <a href="https://www.klarna.com/se/villkor/" target="_blank" rel="noreferrer">här</a>. Latera Racing AB tar inget ansvar för eventuella fel hos Klarna.</p>
          <Header as='h3'>Frakt</Header>
          <p>Vi är stolta över våra leveranstider, men kan inte lämna några garantier för hur snabbt fraktbolaget levererar försändelsen.</p>
          <Header as='h3'>Ej uthämtade paket</Header>
          <p>Vi förbehåller oss rätten att debitera kostnaderna för den hantering som uppstår vid ej uthämtade paket (returfrakt, expeditionskostnad etc).</p>
          <Header as='h3'>Garanti</Header>
          <p>Vid garantiärenden måste vi kontaktas på garanti@latera.se innan retur av en vara. Förfarandet kan skilja beroende på tillverkare och typ av produkt.</p>
          <Header as='h3'>Ångerrätt</Header>
          <p>Vi tillämpar som alla andra LODA - Lagen om distansavtal och avtal utanför affärslokaler. Ångerrätt gäller alltså produkter i "oförändrat skick" som skickats på postorder, och under så lång tid som LODA kräver.</p>
          <Header as='h3'>Personuppgifter</Header>
          <p>Vi, Latera Racing AB (559225-8049), behandlar och sparar dina personuppgifter vid köptillfället och / eller om du registrerar ett konto hos oss. Vi delar även personuppgifterna med våra betallösningar och fraktbolag; DHL, Fraktjakt, Klarna, Postnord och UPS.</p>
          <p>Personuppgifterna sparas så länge som behövs för att uppfylla garantiåttaganden och följa bokföringslagen, och förlängs om du återkommande handlar hos oss eller aktivt använder ditt konto och ger oss tillåtelse att fortsätta spara och behandla dina personuppgifter.</p>
          <Header as='h3'>Cookies</Header>
          <p>Cookies används så lite som möjligt, och endast till autentisering och betaltjänster i shoppen.</p>
        </Segment>
      </>
    )
  } else {
    return (
      <>
        <Title title='Terms' />
        <Segment>
          <Header as='h3'>Currency & VAT</Header>
          <p>Prices, currency, VAT as well as shipping and any other possible costs are always clearly visible at checkout. By "kr" we mean Swedish Krona (SEK).</p>
          <Header as='h3'>Images, Descriptions & Prices</Header>
          <p>Images, descriptions, and prices may be incorrect. It happens that products are updated without our knowledge, and then a product may look different than in the picture, or have changed specifications. We reserve the right to change product information, images, and prices without prior notice.</p>
          <Header as='h3'>Payment</Header>
          <p>Payment is made with Klarna. Their terms can be found <a href="https://www.klarna.com/se/villkor/" target="_blank" rel="noreferrer">here</a>. Latera Racing AB takes no responsibility for any errors with Klarna.</p>
          <Header as='h3'>Shipping</Header>
          <p>We are proud of our delivery times, but we cannot offer any guarantees for how quickly the shipping company delivers the shipment.</p>
          <Header as='h3'>Unclaimed Packages</Header>
          <p>We reserve the right to charge the costs for the handling that occurs with unclaimed packages (return shipping, handling fee, etc).</p>
          <Header as='h3'>Warranty</Header>
          <p>In warranty cases, we must be contacted at garanti@latera.se before returning a product. The procedure may vary depending on the manufacturer and type of product.</p>
          <Header as='h3'>Right of Withdrawal</Header>
          <p>We apply, like everyone else, the LODA - the Law on Distance Contracts and Agreements Outside of Business Premises. Thus, the right of withdrawal applies to products in "unchanged condition" that are sent by mail order, and for as long as LODA requires.</p>
          <Header as='h3'>Personal Data</Header>
          <p>We, Latera Racing AB (559225-8049), process and save your personal data at the time of purchase and / or if you register an account with us. We also share the personal data with our payment solutions and shipping companies; DHL, Fraktjakt, Klarna, Postnord, and UPS.</p>
          <p>The personal data is saved as long as necessary to fulfill warranty commitments and comply with the accounting law, and is extended if you shop with us repeatedly or actively use your account and give us permission to continue to save and process your personal data.</p>
          <Header as='h3'>Cookies</Header>
          <p>Cookies are used as little as possible, and only for authentication and payment services in the shop.</p>
        </Segment>
      </>
    )
  }
}

export default TermsPage;
