import React, { useEffect, useState } from 'react';
import { Header } from 'semantic-ui-react'

const ErrorPage = ({ }) => {
  return (
    <Header as='h1'>Error</Header>
  )
}

export default ErrorPage;
