import React, { } from 'react';
import { Link } from 'react-router-dom';
import { useAtom } from 'jotai';
import { Divider, Grid, Header, Icon, Image } from 'semantic-ui-react'
import {
  responsiveAtom,
} from '../atoms/responsiveAtom';
import klarnaLogo from '../assets/klarna-short-white.png';

const Footer = () => {
  const [responsive, setResponsive] = useAtom(responsiveAtom);

  return (
    <Grid columns={3} divided inverted padded stackable>
      <Grid.Row>
        <Grid.Column>
          <Header as='h2' inverted>
            <Icon name='building' />
            <Header.Content>Latera Racing AB</Header.Content>
          </Header>
          <Divider />
          <p><Link to='/terms'>Läs våra köpvillkor</Link></p>
          <p>VAT: SE559225804901</p>
          <Image src={klarnaLogo} />
        </Grid.Column>
        <Grid.Column>
          <Header as='h2' inverted>
            <Icon name='mail' />
            <Header.Content>Kontakt</Header.Content>
          </Header>
          <Divider />
          <p>
            Latera Racing AB<br />
            Arntorpsgatan 6<br />
            442 45  Kungälv<br />
            Sweden<br />
          </p>
          <p><a href='https://facebook.com/lateraracing' target='_blank' rel='noreferrer'>Facebook</a></p>
          <p><a href='mailto:info@latera.se'>info@latera.se</a> | <a href='mailto:b2b@latera.se'>b2b@latera.se</a></p>
        </Grid.Column>
        <Grid.Column>
          <Header as='h2' inverted>
            <Icon name='info' />
            <Header.Content>Om oss</Header.Content>
          </Header>
          <Divider />
          <p>Latera Racing AB grundades 2019 och drivs av RC-entusiasterna Martin Östberg och Kim Lundgren.</p>
          <p>2023 öppnade vi <a href="https://latera-rc-arena.se" target="_blank">Latera RC Arena</a>, vår alldeles egna inomhusbana för 1/10 Touring. Välkomna!</p>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

export default React.memo(Footer);
