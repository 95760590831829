import React, { useState } from 'react';
import { useAtom } from 'jotai';
import { Button, Icon, Modal, Table } from 'semantic-ui-react';
import {
  currenciesAtom,
  productsAtom,
  shipmentMethodsAtom,
  showVatAtom,
} from '../state';
import { userAtom } from '../atoms/authAtom';
import { formattedPrice } from '../utils/price';
import OrderDetails from '../components/OrderDetails.js';

const AdminOrderTableRow = ({ order }) => {
  const [currencies] = useAtom(currenciesAtom);
  const [products] = useAtom(productsAtom);
  const [shipmentMethods] = useAtom(shipmentMethodsAtom);
  const [showVat] = useAtom(showVatAtom);
  const [user] = useAtom(userAtom);

  const [modalOpen, setModalOpen] = useState(false);

  const total = (order) => {
    let total = 0;
    order.order_rows.forEach(item => {
      if (showVat) {
        total += Number(item.subtotal_incl_vat);
      } else {
        total += Number(item.subtotal_excl_vat);
      }
    });
    return total;
  };

  const paid = (order) => {
    if (order?.invoices?.length > 0) {
      return <Icon name='check' color='green' />
    } else {
      return <Icon name='close' color='red' />
    }
  };

  const isShipped = (order) => {
    if (order.shipped) {
      return <Icon name='check' color='green' />
    } else {
      return <Icon name='close' color='red' />
    }
  };

  return (
    <Table.Row key={order.id} onClick={() => setModalOpen(true)}>
      <Modal
        size='fullscreen'
        onClose={() => setModalOpen(false)}
        open={modalOpen}
      >
        <Modal.Content>
          <OrderDetails orderId={order.id} adminMode={true} />
        </Modal.Content>
        <Modal.Actions>
          <Button
            content="Close"
            labelPosition='right'
            icon='x'
            onClick={(e) => {
              e.stopPropagation()
              setModalOpen(false)
            }}
            negative
          />
        </Modal.Actions>
      </Modal>

      <Table.Cell>
        {order.id}
      </Table.Cell>
      <Table.Cell>
        {new Date(order.created_at).toLocaleString()}
      </Table.Cell>
      <Table.Cell>
        {order.customer.name}
      </Table.Cell>
      <Table.Cell>
        {formattedPrice(total(order), currencies[order?.currency_id]?.symbol, currencies[order?.currency_id]?.right_side)}
      </Table.Cell>
      <Table.Cell>
        {paid(order)}
      </Table.Cell>
      <Table.Cell>
        {shipmentMethods[order?.shipment_method_id]?.short_name}
      </Table.Cell>
      <Table.Cell>
        {isShipped(order)}
      </Table.Cell>
    </Table.Row>
  );
}

export default React.memo(AdminOrderTableRow);
