import React, { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { useMutation } from '@tanstack/react-query';
import { Form, Icon, Label } from 'semantic-ui-react';
import {
  languageAtom,
  productGroupsAtom,
} from '../state.js';
import { userAtom } from '../atoms/authAtom.js';
import ErrorPage from '../ErrorPage.js';

const AdminProductTags = ({ product }) => {
  const [language] = useAtom(languageAtom);
  const [productGroups] = useAtom(productGroupsAtom);
  const [user] = useAtom(userAtom);

  const [loading, setLoading] = useState(false);
  const [groups, setGroups] = useState([]);

  const updateProduct = async (data) => {
    const [productId, product] = data;
    const response = await fetch(`/api/products/${productId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(product),
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  }

  const updateProductMutation = useMutation({
    mutationFn: updateProduct,
    onMutate: (data) => {
      setLoading(true);
    },
    onSuccess: (data) => {
      setLoading(false);
    },
  });

  useEffect(() => {
    if (product?.product_group_ids) {
      const groups = Object.values(productGroups).filter(group => product.product_group_ids.includes(group.id));
      groups.sort((a, b) => a.tag.localeCompare(b.tag));
      setGroups(groups);
    } else {
      setGroups([]);
    }
  }, [product]);

  if (!user?.admin) {
    return (
      <ErrorPage />
    );
  }

  return (
    <>
      <Form loading={loading}>
        <Form.Dropdown
          fluid
          multiple
          search
          selection
          options={Object.values(productGroups)
            .filter(group => !group.in_menu || (group.in_menu && group.parent_id !== 0))
            .map(group => ({
              key: group.id,
              text: group.names[language],
              value: group.id,
            }))
            .sort((a, b) => a.text.localeCompare(b.text))}
          value={groups.map(group => group.id)}
          onChange={(e, { value }) => {
            const product_group_ids = value;
            updateProductMutation.mutate([product.id, { product_group_ids }]);
          }}
        />
      </Form>
    </>
  );
}

export default AdminProductTags;
