export const formattedPrice = (price, symbol, right_side) => {
  if (!price || !symbol) {
    return null;
  }
  
  const options = {
    maximumFractionDigits: 2, // Display up to 2 decimals
    minimumFractionDigits: (price % 1 === 0) ? 0 : 2 // No decimals for whole numbers
  };

  const priceString = Intl.NumberFormat(navigator.language, options).format(price);

  if (right_side) {
    return `${priceString} ${symbol}`;
  } else {
    return `${symbol} ${priceString}`;
  }
};
