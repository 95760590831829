import { atom } from 'jotai';

export const mobileSize = 1230;
export const leftMenuWidth = '250px';
export const cartWidth = '400px';
export const topBarMobileHeight = '64px';
export const topBarDesktopHeight = '100px';

export const responsiveAtom = atom({
  leftMenuVisible: window.innerWidth > mobileSize,
  cartVisible: window.innerWidth > mobileSize,
  topBarVisible: window.innerWidth < mobileSize,
  tobBar: window.innerWidth < mobileSize ? 'mobile' : 'desktop',
  topMargin: window.innerWidth < mobileSize ? topBarMobileHeight : topBarDesktopHeight,
});
