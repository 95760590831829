import { atom } from 'jotai';
import {
  productsAtom,
} from '../state.js';

export const searchProductsOptionsAtom = atom('');

export const searchProductsAtom = atom((get) => {
  const products = get(productsAtom);
  const options = get(searchProductsOptionsAtom);

  let searchProducts = Object.values(products);

  const combinedProducts = [];

  // Filter products and stop after 50 results. Split words by space and lowercase them, and only return products that match all words.
  const words = options.split(' ').map(word => word.toLowerCase());

  for (let i = 0; i < searchProducts.length; i++) {
    const product = searchProducts[i];
    
    const partNumber = product.part_number ? product.part_number.toLowerCase() : '';
    const name = product.name ? product.name.toLowerCase() : '';
    // const brand = product.brand_name?.toLowerCase().split(/[- ]/)[0] || '';
    const brand = product.brand_name?.toLowerCase();
  
    const matches = words.filter(word => {
      return partNumber.includes(word) || name.includes(word) || brand.includes(word);
    });
  
    if (matches.length === words.length) { // Ensure all words match
      combinedProducts.push(product);
    }
  
    if (combinedProducts.length >= 50) {
      break;
    }
  }

  // Sort the combined products array
  const sortedProducts = combinedProducts.sort((a, b) => {
    // Discontinued products should be at the end
    if (a.discontinued && a.stock === 0 && (!b.discontinued || b.stock > 0)) return 1;
    if (b.discontinued && b.stock === 0 && (!a.discontinued || a.stock > 0)) return -1;

    const aValue = a.part_number || '';
    const bValue = b.part_number || '';
    return aValue.localeCompare(bValue);
  });

  // Return the sorted products
  return sortedProducts;
});
