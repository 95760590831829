import React, { } from 'react';
import { Header } from 'semantic-ui-react'

const Title = ({ title, subtitle }) => {
  return (
    <div className="title-container">
      <div className="red-rectangle" style={{ height: (subtitle ? '65px' : '40px') }}></div>
      <Header as='h1' className="title-header">
        {title}
        {subtitle && (
          <Header.Subheader>
            {subtitle}
          </Header.Subheader>
        )}
      </Header>
    </div>
  );
}

export default React.memo(Title);
