import React, { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { useMutation } from '@tanstack/react-query';
import { Button, Dimmer, Loader, Segment, Table } from 'semantic-ui-react';
import {
  productsByIdAtom,
  productRelationsAtom,
} from '../state.js';
import { userAtom } from '../atoms/authAtom.js';
import AdminPrimaryProductFitsAdd from './AdminPrimaryProductFitsAdd.js';
import AdminPrimaryProductFitsMultiple from './AdminPrimaryProductFitsMultiple.js';
import ErrorPage from '../ErrorPage.js';

const AdminPrimaryProductFits = ({ product }) => {
  const [productsById] = useAtom(productsByIdAtom);
  const [productRelations] = useAtom(productRelationsAtom);
  const [user] = useAtom(userAtom);

  const [fits, setFits] = useState([]);
  const [loading, setLoading] = useState(false);
  const [multipleThingsAccordionActive, setMultipleThingsAccordionActive] = useState(2);

  const deleteRelation = async (data) => {
    const [relationId] = data;
    const response = await fetch(`/api/product_relations/${relationId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  }

  const deleteRelationMutation = useMutation({
    mutationFn: deleteRelation,
    onMutate: (data) => {
      setLoading(true);
    },
    onSuccess: (data) => {
      setLoading(false);
    },
  });

  useEffect(() => {
    if (productRelations && Array.isArray(productRelations)) {
      const relations = productRelations.filter(relation => relation.parent_product_id === product.id).map(relation => {
        const childProduct = productsById[relation.child_product_id];
        if (childProduct) {
          relation.product = childProduct;
          return relation;
        }
      });

      relations.sort((a, b) => a?.product?.part_number.localeCompare(b?.product?.part_number));

      setFits(relations);
    }
  }, [product, productsById, productRelations]);

  if (!user?.admin) {
    return (
      <ErrorPage />
    );
  }

  return (
    <Segment basic style={{ padding: '0px' }}>
      <Dimmer active={loading}>
        <Loader />
      </Dimmer>

      <AdminPrimaryProductFitsAdd primaryProduct={product} />

      <AdminPrimaryProductFitsMultiple primaryProduct={product} />
      
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Part #</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Action</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {fits?.sort((a, b) => a.product?.short_name.localeCompare(b.product?.short_name)).map((fit) => (
            <Table.Row key={fit.product?.id}>
              <Table.Cell>{fit.product?.part_number}</Table.Cell>
              <Table.Cell>{fit.product?.name}</Table.Cell>
              <Table.Cell textAlign='center'>
                <Button negative size='tiny' icon='trash'
                  onClick={(e) => {
                    e.preventDefault();
                    deleteRelationMutation.mutate([fit.id]);
                  }}
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Segment>
  );
}

export default AdminPrimaryProductFits;
