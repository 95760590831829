import { atom } from 'jotai';
import {
  inventoryLocationsAtom,
  productsAtom,
} from '../state.js';

export const lowStockProductsAtom = atom((get) => {
  const options = get(lowStockOptionsAtom);
  const inventoryLocations = get(inventoryLocationsAtom);
  const products = get(productsAtom);

  const lowStockProducts = [];

  Object.values(products)?.forEach((product) => {
    const desired = product.product_inventories?.map((product_inventory) => {
      return product_inventory.desired_stock;
    }
    ).reduce((a, b) => a + b, 0);

    if (product.stock < desired) {
      lowStockProducts.push({
        part_number: product.part_number,
        name: product.name,
        stock: product.stock,
        desired_stock: desired,
      });
    }
  });

  if (options.column) {
    lowStockProducts.sort((a, b) => {
      if (options.direction === 'ascending') {
        // Can't use localeCompare here because it's a number
        if (a[options.column] < b[options.column]) {
          return -1;
        } else if (a[options.column] > b[options.column]) {
          return 1;
        } else {
          return 0;
        }
      } else {
        if (a[options.column] > b[options.column]) {
          return -1;
        } else if (a[options.column] < b[options.column]) {
          return 1;
        } else {
          return 0;
        }
      }
    });
  }

  return lowStockProducts;
});

export const lowStockOptionsAtom = atom({
  column: 'part_number',
  direction: 'ascending',
})
