import React, { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { Header, List, ListItem, Segment } from 'semantic-ui-react';

const ProductDownloads = ({ product }) => {
  const { t } = useTranslation();

  useEffect(() => {
  }, []);

  console.log(product.downloads)

  return (
    <Segment secondary textAlign='left'>
      <Header as='h3' dividing>
        {t('downloads')}
      </Header>
      <List relaxed>
        {product.downloads?.map((download, index) => {
          return (
            <ListItem key={index}>
              <a target="_blank" href={`/downloads/${product.id}/${download.id}/${download.filename}`}>{download.filename}</a>
            </ListItem>
          );
        })}
        </List>
    </Segment>
  );
}

export default React.memo(ProductDownloads);
