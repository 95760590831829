import { useEffect, useRef } from 'react';
import { useAtom } from 'jotai';

import { responsiveAtom, mobileSize } from '../atoms/responsiveAtom';

const useResponsive = () => {
  const [responsive, setResponsive] = useAtom(responsiveAtom);

  const prevWidthRef = useRef(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      const currentWidth = window.innerWidth;
      if (prevWidthRef.current !== currentWidth) {
        setResponsive({
          leftMenuVisible: window.innerWidth > mobileSize,
          cartVisible: window.innerWidth > mobileSize,
          topBarVisible: window.innerWidth < mobileSize,
          topMargin: window.innerWidth < mobileSize ? '64px' : '100px',
          topBar: window.innerWidth < mobileSize ? 'mobile' : 'desktop',
        });
        prevWidthRef.current = currentWidth;
      }
    }

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  const handleDimmerVisibility = () => {
    if (window.innerWidth <= mobileSize) {
      setResponsive({
        leftMenuVisible: false,
        cartVisible: false,
        topBarVisible: true,
        topMargin: window.innerWidth < mobileSize ? '64px' : '100px',
        topBar: window.innerWidth < mobileSize ? 'mobile' : 'desktop',
      });
    }
  }

  return {
    handleDimmerVisibility,
  };
};

export default useResponsive;
