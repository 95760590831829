import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import {
  bundleFinishedAtom,
  brandsAtom,
  productsByIdAtom,
  suppliersAtom,
  webpSupportedAtom,
} from '../state.js';
import { userAtom } from '../atoms/authAtom';
import { Button, Divider, Dropdown, Form, Grid, Header, Icon, Loader, Menu, Popup, Segment, Tab, Table } from 'semantic-ui-react';
import { primaryThumbnailUrl } from '../utils/thumbnailUrl.js';
import Title from '../components/Title.js';
import ErrorPage from '../ErrorPage.js';

const AdminPurchaseOrderDetails = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  const [bundleFinished] = useAtom(bundleFinishedAtom);
  const [brands] = useAtom(brandsAtom);
  const [productsById] = useAtom(productsByIdAtom);
  const [purchaseOrder, setPurchaseOrder] = useState({});
  const [suppliers] = useAtom(suppliersAtom);
  const [user] = useAtom(userAtom);
  const [webpSupported] = useAtom(webpSupportedAtom);

  const [history, setHistory] = useState(<></>);
  const [loading, setLoading] = useState(false);
  const [productOptions, setProductOptions] = useState([]);
  const [productToAdd, setProductToAdd] = useState({});
  const [quantityToAdd, setQuantityToAdd] = useState(1);
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const [relevantBrands, setRelevantBrands] = useState([]);
  const [supplier, setSupplier] = useState({});

  const [editingRowId, setEditingRowId] = useState(null);
  const [newQuantity, setNewQuantity] = useState(0);

  useEffect(() => {
    if (id && bundleFinished) {
      setLoading(true);
      try {
        fetch(`/api/purchase_orders/${id}`)
          .then(response => response.json())
          .then(data => {
            setLoading(false);
            setPurchaseOrder(data);
            setSupplier(suppliers.find(supplier => supplier.id === data.supplier_id));
            setRelevantBrands(Object.values(brands).filter(brand => brand.supplier_id === data.supplier_id));
          });
      } catch (error) {
        console.error('Error fetching purchase order', error);
      }
    }
  }, [bundleFinished, brands, id, productsById, refreshTrigger]);

  const customSearch = (options, query) => {
    const terms = query.toLowerCase().split(' ');
    return options.filter(option =>
      terms.every(term =>
        option.text.toLowerCase().includes(term)
      )
    );
  };

  const handleSearchChange = (e, { searchQuery }) => {
    const terms = searchQuery.toLowerCase().split(' ');
    const filteredProducts = Object.values(productsById)
      .filter(product =>
        relevantBrands.some(brand => brand.id === product.brand_id) &&
        terms.every(term =>
          product.name.toLowerCase().includes(term) ||
          product.part_number.toLowerCase().includes(term)
        ) 
      )
      .sort((a, b) => a.part_number.localeCompare(b.part_number))
      .slice(0, 15)
      .map(product => ({
        key: product.id,
        text: `[${product.part_number}] - ${product.name}`,
        value: product.id,
        content: (
          <Header
            size='small'
            image={primaryThumbnailUrl(product.images, webpSupported, product.url) || '/assets/placeholder.png'}
            content={product.name}
            subheader={product.part_number}
          />
        ),
      }));
    setProductOptions(filteredProducts);
  };

  const handleAddProduct = async (productId, quantity) => {
    if (productId && quantity) {
      try {
        const response = await fetch(`/api/purchase_order_rows`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            purchase_order_id: purchaseOrder.id,
            product_id: productId,
            quantity: quantity,
          }),
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        const data = await response.json();
        await new Promise(resolve => setTimeout(resolve, 30)); // 30ms delay to allow the database to update
        setRefreshTrigger(refreshTrigger + 1);
      } catch (error) {
        alert('Error adding product to purchase order');
      }
    }
  };
  
  const handleDeleteRow = (rowId) => {
    if (window.confirm(t('Are you sure you want to delete this row?'))) {
      try {
        fetch(`/api/purchase_order_rows/${rowId}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
        })
          .then(response => response.json())
          .then(data => {
            setRefreshTrigger(refreshTrigger + 1);
          });
      } catch (error) {
        console.error('Error deleting product from purchase order', error);
      }
    }
  };

  const handleEditQuantity = (rowId, currentQuantity) => {
    setEditingRowId(rowId);
    setNewQuantity(currentQuantity);
  };

  const handleSaveQuantity = (rowId) => {
    if (newQuantity && rowId) {
      try {
        fetch(`/api/purchase_order_rows/${rowId}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            quantity: newQuantity,
          }),
        })
          .then(response => response.json())
          .then(data => {
            setRefreshTrigger(refreshTrigger + 1);
            setEditingRowId(null);
          });
      } catch (error) {
        console.error('Error updating product quantity in purchase order', error);
      }
    }
  };

  const handleClose = () => {
    if (window.confirm(t('Are you sure you want to close this purchase order?'))) {
      try {
        fetch(`/api/purchase_orders/${purchaseOrder.id}/close`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
        })
          .then(response => response.json())
          .then(data => {
            setRefreshTrigger(refreshTrigger + 1);
          });
      } catch (error) {
        console.error('Error closing purchase order', error);
      }
    }
  };

  const handleReopen = () => {
    if (window.confirm(t('Are you sure you want to reopen this purchase order?'))) {
      try {
        fetch(`/api/purchase_orders/${purchaseOrder.id}/open`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
        })
          .then(response => response.json())
          .then(data => {
            setRefreshTrigger(refreshTrigger + 1);
          });
      } catch (error) {
        console.error('Error reopening purchase order', error);
      }
    }
  };

  const handleHistory = (productId) => {
    try {
      fetch(`/api/products/${productId}/purchase_orders`)
        .then(response => response.json())
        .then(data => {
          const rows = data
            // .filter(row => row.purchase_order_id !== purchaseOrder.id)
            .map(row => {
              return (
                <Table.Row active={row.purchase_order_id == purchaseOrder.id} key={row.purchase_order_id}>
                  <Table.Cell>{row.purchase_order_id}</Table.Cell>
                  <Table.Cell>{row.quantity}</Table.Cell>
                  <Table.Cell>{row.ordered_at ? new Date(row.ordered_at).toLocaleDateString() : ''}</Table.Cell>
                </Table.Row>
              );
            });

          setHistory(
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>{t('admin-purchase_order')}</Table.HeaderCell>
                  <Table.HeaderCell>{t('quantity')}</Table.HeaderCell>
                  <Table.HeaderCell>{t('ordered')}</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {rows}
              </Table.Body>
            </Table>
          );
        });
    } catch (error) {
      console.error('Error fetching product purchase order history', error);
    }
  };

  if (!user?.admin) {
    return <ErrorPage />;
  }

  if (loading) {
    return <Loader size='massive' active />;
  }

  if (!purchaseOrder.id) {
    return <ErrorPage />;
  }

  return (
    <>
      <Title title={`${t('admin-purchase_order')} #${purchaseOrder?.id}`} />

      <Segment secondary>

        <Grid columns={2} textAlign='center'>
          <Grid.Row>
            <Grid.Column textAlign='left'>
              <Header as='h2'>
                {supplier?.name}
                <Header.Subheader>
                  {purchaseOrder?.ordered_at ? (
                    <>{t('status')}: {t('ordered')} {`(${new Date(purchaseOrder.ordered_at).toLocaleDateString()})`}</>
                  ) : (
                    <>{t('status')}: {t('open')}</>
                  )}
                </Header.Subheader>
              </Header>
            </Grid.Column>

            <Grid.Column textAlign='right' verticalAlign='middle'>
              <Button
                size='large'
                positive
                icon='refresh'
                onClick={() => setRefreshTrigger(refreshTrigger + 1)}
              />
              {purchaseOrder?.ordered_at ? (
                <Button
                  size='large'
                  negative content={t('reopen')}
                  onClick={handleReopen}
                />
              ) : (
                <Button
                  size='large'
                  negative content={t('close')}
                  onClick={handleClose}
                />
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Divider />

        {!purchaseOrder?.ordered_at && (
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              handleAddProduct(productToAdd, quantityToAdd);
              setRefreshTrigger(refreshTrigger + 1);
            }}
          >
            <Form.Field>
              <Dropdown
                placeholder={t('search')}
                search={customSearch}
                selection
                options={productOptions}
                onSearchChange={handleSearchChange}
                onChange={(e, { value }) => setProductToAdd(value)}
              />
            </Form.Field>
            <Form.Field>
              <input
                type='number'
                min={1}
                max={10000}
                required
                placeholder={t('quantity')}
                value={quantityToAdd}
                onChange={(e) => setQuantityToAdd(Number(e.target.value) || null)}
              />
            </Form.Field>
            <Form.Field>
              <Button type='submit' positive>
                <Icon name='add' />
                {t('add')}
              </Button>
            </Form.Field>
          </Form>
        )}

        {purchaseOrder?.ordered_at && (
          <Segment textAlign='center'>
            <Button icon size='large' positive labelPosition='left' as='a' href={`/api/purchase_orders/${purchaseOrder.id}/csv`} target='_blank'>
              <Icon name='download' />
              {t('download_csv')}
            </Button>
          </Segment>
        )}

        <Table selectable striped sortable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{t('sku')}</Table.HeaderCell>
              <Table.HeaderCell>{t('name')}</Table.HeaderCell>
              <Table.HeaderCell>{t('in_stock')}</Table.HeaderCell>
              <Table.HeaderCell>{t('case_quantity')}</Table.HeaderCell>
              <Table.HeaderCell>{t('quantity')}</Table.HeaderCell>
              <Table.HeaderCell>{t('history')}</Table.HeaderCell>
              <Table.HeaderCell>{t('delete')}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {purchaseOrder?.purchase_order_rows?.sort(
              (a, b) => productsById[a.product_id]?.part_number.localeCompare(productsById[b.product_id]?.part_number)

            ).map(item => {
              const product = productsById[item.product_id];
              return (
                <Table.Row key={item.id}>
                  <Table.Cell>{product?.part_number}</Table.Cell>
                  <Table.Cell>{product?.name}</Table.Cell>
                  <Table.Cell>{product?.stock}</Table.Cell>
                  <Table.Cell>{product?.case_quantity}</Table.Cell>
                  <Table.Cell>
                    {editingRowId === item.id ? (
                      <Form onSubmit={() => handleSaveQuantity(item.id)}>
                        <Form.Input
                          type='number'
                          value={newQuantity}
                          onChange={(e) => setNewQuantity(Number(e.target.value))}
                          min='1'
                          max='10000'
                          style={{ width: '6em' }}
                          action={{
                            icon: 'save',
                            color: 'green',
                            onClick: () => handleSaveQuantity(item.id),
                          }}
                        />
                      </Form>
                    ) : (
                      <>
                        {item.quantity}
                        <Icon
                          name='pencil'
                          disabled={purchaseOrder?.ordered_at}
                          style={{ cursor: 'pointer', marginLeft: '10px' }}
                          onClick={() => handleEditQuantity(item.id, item.quantity)}
                        />
                      </>
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    <Popup
                      content={history}
                      key={item.id}
                      trigger={<Icon size='large' name='info circle' />}
                      onOpen={() => handleHistory(item.product_id)}
                      onClose={() => setHistory(<></>)}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <Button
                      icon='trash'
                      negative
                      disabled={purchaseOrder?.ordered_at}
                      onClick={() => handleDeleteRow(item.id)}
                    />
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </Segment>
    </>
  );
};

export default AdminPurchaseOrderDetails;
