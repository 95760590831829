import React, { useCallback, useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { Image, Modal, Segment } from 'semantic-ui-react'
import {
  webpSupportedAtom,
} from '../state.js';
import { smallThumbnailUrl, bigThumbnailUrl } from '../utils/thumbnailUrl';

const ProductPageImages = ({ images, productUrl }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const [startX, setStartX] = useState(null);
  const [webpSupported] = useAtom(webpSupportedAtom);

  useEffect(() => {
    // Set currentImageIndex to the primary image if there is one, otherwise the first image
    if (images && images?.length > 0) {
      const primaryImageIndex = images.findIndex(image => image.primary);
      if (primaryImageIndex > -1) {
        setCurrentImageIndex(primaryImageIndex);
      } else {
        setCurrentImageIndex(0);
      }
    }
  }, [images]);

  const handleKeyDown = useCallback((e) => {
    if (open) {
      if (e.key === 'ArrowRight') {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
      } else if (e.key === 'ArrowLeft') {
        setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
      }
    }
  }, [open, images?.length]);

  useEffect(() => {
    if (open) {
      document.addEventListener('keydown', handleKeyDown);
    }
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [open, handleKeyDown]);

  const handleTouchStart = (e) => {
    setStartX(e.touches[0].clientX);
  }

  const handleTouchEnd = (e) => {
    const endX = e.changedTouches[0].clientX;
    const threshold = 30; // Minimum distance to consider the gesture a swipe

    if (startX - endX > threshold) {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length); // Right swipe
    } else if (endX - startX > threshold) {
      setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length); // Left swipe
    }
  }

  if (!images || images?.length === 0) {
    return (
      <Image fluid src='/assets/placeholder.png' style={{ objectFit: 'contain', height: '40vh', marginLeft: '0px', marginRight: '0px' }} />
    )
  }

  return (
    <>
      <meta itemProp="image" href={bigThumbnailUrl(images[currentImageIndex], webpSupported, productUrl)} />
      <Image
        fluid
        src={bigThumbnailUrl(images[currentImageIndex], webpSupported, productUrl)}
        style={{ objectFit: 'contain', height: '40vh', marginLeft: '0px', marginRight: '0px' }}
        onClick={() => setOpen(true)}
      />
      <Segment secondary style={{ width: '100%', whiteSpace: 'nowrap', overflowX: 'auto', display: 'inline-block', margin: '0px', padding: '0px', top: '-1px' }}>
        {images?.map((image, index) => {
          return (
            <img key={image.id} src={smallThumbnailUrl(image, webpSupported, productUrl)} style={{
              display: 'inline-block',
              width: '10vh',
              height: '10vh',
              margin: '0.5em',
              cursor: 'pointer',
              objectFit: 'cover',
            }}
              onClick={() => setCurrentImageIndex(index)}
            />
          )
        })}
      </Segment>
      <Modal
        basic
        closeIcon
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        size='fullscreen'
      >
        <Modal.Content style={{ width: '100%', height: '100%', backgroundColor: 'red' }}>
          <Image
            fluid
            src={images[currentImageIndex]?.path}
            style={{ objectFit: 'contain', width: '100%', height: '100%', marginLeft: '0px', marginRight: '0px', padding: '0px', cursor: 'pointer' }}
            onClick={() => setOpen(false)}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
          />
        </Modal.Content>
      </Modal>
    </>
  )
}

export default ProductPageImages;
