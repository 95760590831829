import React, { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Icon, Menu, Table } from 'semantic-ui-react';
import {
  productGroupsAtom,
} from '../state.js';
import { userAtom } from '../atoms/authAtom';
import ErrorPage from '../ErrorPage.js';
import Title from '../components/Title.js';

const AdminProductGroups = () => {
  const [productGroups] = useAtom(productGroupsAtom);
  const [user] = useAtom(userAtom);

  const { t } = useTranslation();

  const [groups, setGroups] = useState(Object.values(productGroups).sort((a, b) => a.tag.localeCompare(b.tag)));
  const [sortColumn, setSortColumn] = useState('tag');
  const [sortDirection, setSortDirection] = useState('ascending');

  useEffect(() => {
    setGroups(Object.values(productGroups).sort((a, b) => {
      if (sortColumn === 'tag') {
        return sortDirection === 'ascending' ? a.tag.localeCompare(b.tag) : b.tag.localeCompare(a.tag);
      }
      if (sortColumn === 'in_menu') {
        return sortDirection === 'ascending' ? a.in_menu - b.in_menu : b.in_menu - a.in_menu;
      }
      if (sortColumn === 'related_product_group_id') {
        return sortDirection === 'ascending' ? a.related_product_group_id - b.related_product_group_id : b.related_product_group_id - a.related_product_group_id;
      }
    }
    ));
  }, [sortColumn, sortDirection, productGroups]);

  if (!user?.admin) {
    return (
      <ErrorPage />
    );
  }

  function relatedTag(id) {
    const relatedGroup = Object.values(productGroups).find(group => group.id === id);
    return relatedGroup?.tag;
  }

  return (
    <>
      <Title title={t('admin-product_groups')} />

      <Menu>
        <Menu.Item
          name='add'
        >
          <Button
            positive
            icon
            labelPosition='left'
          >
            <Icon name='add' />
            Add
          </Button>
        </Menu.Item>
      </Menu>

      <Table selectable striped sortable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              sorted={sortColumn === 'tag' ? sortDirection : null}
              onClick={() => {
                setSortColumn('tag');
                setSortDirection(sortDirection === 'ascending' ? 'descending' : 'ascending');
              }}
            >
              Tag
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortColumn === 'in_menu' ? sortDirection : null}
              onClick={() => {
                setSortColumn('in_menu');
                setSortDirection(sortDirection === 'ascending' ? 'descending' : 'ascending');
              }}
            >
              In Menu
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortColumn === 'related_product_group_id' ? sortDirection : null}
              onClick={() => {
                setSortColumn('related_product_group_id');
                setSortDirection(sortDirection === 'ascending' ? 'descending' : 'ascending');
              }}
            >
              Related To
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {groups.map(productGroup => (
            <Table.Row key={productGroup.id}>
              <Table.Cell>
                <Link to={`/admin/product_groups/${productGroup.tag}`}>{productGroup.tag}</Link>
              </Table.Cell>
              <Table.Cell>
                {productGroup.in_menu ? 'Yes' : 'No'}
              </Table.Cell>
              <Table.Cell>
                {productGroup.related_product_group_id !== null && (
                  relatedTag(productGroup.related_product_group_id)
                )}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
}

export default AdminProductGroups;
