import React from 'react';
import { createBrowserRouter } from 'react-router-dom';

import App from './App';

import B2B from './B2B';
import B2BCheckout from './B2BCheckout';
import BrandPage from './BrandPage';
import BrandsPage from './BrandsPage';
import Checkout from './Checkout';
import ErrorPage from './ErrorPage';
import Home from './Home';
import OrderConfirmationPage from './OrderConfirmationPage';
import OrdersPage from './OrdersPage';
import OrderPage from './OrderPage';
import ProductPage from './ProductPage';
import RegisterPage from './RegisterPage';
import ResetPasswordPage from './ResetPasswordPage';
import TagPage from './TagPage';
import TermsPage from './TermsPage';

import AdminAddProduct from './admin/AdminAddProduct';
import AdminBrands from './admin/AdminBrands';
import AdminCustomers from './admin/AdminCustomers';
import AdminInventoryReport from './admin/AdminInventoryReport';
import AdminInvoices from './admin/AdminInvoices';
import AdminOrders from './admin/AdminOrders';
import AdminProductGroup from './admin/AdminProductGroup';
import AdminProductGroups from './admin/AdminProductGroups';
import AdminPurchaseOrders from './admin/AdminPurchaseOrders';
import AdminPurchaseOrderDetails from './admin/AdminPurchaseOrderDetails';
import AdminSuppliers from './admin/AdminSuppliers';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <Home /> },
      {
        path: "/admin/add_product",
        element: <AdminAddProduct />,
      },
      {
        path: "/admin/brands",
        element: <AdminBrands />,
      },
      {
        path: "/admin/customers",
        element: <AdminCustomers />,
      },
      {
        path: "/admin/inventory_report",
        element: <AdminInventoryReport />,
      },
      {
        path: "/admin/invoices",
        element: <AdminInvoices />,
      },
      {
        path: "/admin/orders",
        element: <AdminOrders />,
      },
      {
        path: "/admin/product_groups",
        element: <AdminProductGroups />,
      },
      {
        path: "/admin/product_groups/:tag",
        element: <AdminProductGroup />,
      },
      {
        path: "/admin/purchase_orders",
        element: <AdminPurchaseOrders />,
      },
      {
        path: "/admin/purchase_orders/:id",
        element: <AdminPurchaseOrderDetails />,
      },
      {
        path: "/admin/suppliers",
        element: <AdminSuppliers />,
      },
      {
        path: "/b2b",
        element: <B2B />,
      },
      {
        path: "/b2b/checkout",
        element: <B2BCheckout />,
      },
      {
        path: "/brands/",
        element: <BrandsPage />,
      },
      {
        path: "/brands/:tag",
        element: <BrandPage />,
      },
      {
        path: "/checkout",
        element: <Checkout />,
      },
      {
        path: "/order-confirmation/:orderId",
        element: <OrderConfirmationPage />,
      },
      {
        path: "/orders",
        element: <OrdersPage />,
      },
      {
        path: "/orders/:orderId",
        element: <OrderPage />,
      },
      {
        path: "/products/:tag",
        element: <ProductPage />,
      },
      {
        path: "/register",
        element: <RegisterPage />,
      },
      {
        path: "/reset-password",
        element: <ResetPasswordPage />,
      },
      {
        path: "/tags/:tag",
        element: <TagPage />,
      },
      {
        path: "/terms",
        element: <TermsPage />,
      },
    ],
  },
]);

export default router;
