import React, { useState } from 'react';
import { useAtom } from 'jotai';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Table } from 'semantic-ui-react';
import {
  inventoryLocationsAtom,
} from '../state.js';
import { userAtom } from '../atoms/authAtom';

const AdminInventoryLog = ({ product }) => {
  const [inventoryLocations] = useAtom(inventoryLocationsAtom);
  const [user] = useAtom(userAtom);

  const queryClient = useQueryClient();

  const inventoryLogsQuery = useQuery({
    queryKey: ['inventoryLogs', [ product.id ]],
    queryFn: async () => {
      const response = await fetch(`/api/products/${product.id}/inventory_logs`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    },
  });

  const { data: data, isLoading, isError, error } = useQuery({
    queryKey: ['inventoryLogs', [ product.id ]],
    queryFn: inventoryLogsQuery,
  });

  if (!user?.admin) {
    return (
      <></>
    );
  }

  return (
    <>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Date & Time</Table.HeaderCell>
            <Table.HeaderCell>Order</Table.HeaderCell>
            <Table.HeaderCell>Customer</Table.HeaderCell>
            <Table.HeaderCell>Location</Table.HeaderCell>
            <Table.HeaderCell>Before</Table.HeaderCell>
            <Table.HeaderCell>Change</Table.HeaderCell>
            <Table.HeaderCell>After</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {data?.inventory_logs.map((inventoryLog) => (
            <Table.Row
              key={inventoryLog.id}
              negative={(inventoryLog.change < 0)}
              positive={(inventoryLog.change > 0)}
            >
              <Table.Cell>{new Date(inventoryLog.created_at).toLocaleString()}</Table.Cell>
              <Table.Cell>{inventoryLog.order_id || '-'}</Table.Cell>
              <Table.Cell>{inventoryLog.customer_name}</Table.Cell>
              <Table.Cell>{inventoryLocations[inventoryLog.inventory_location_id].name}</Table.Cell>
              <Table.Cell>{inventoryLog.before}</Table.Cell>
              <Table.Cell>{inventoryLog.change}</Table.Cell>
              <Table.Cell>{inventoryLog.after}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
}

export default AdminInventoryLog;
