import { useEffect, useRef, useCallback } from 'react';
import { useAtom } from 'jotai';
import {
  brandsAtom,
  productsAtom,
  productsByIdAtom,
  productsByUrlAtom,
  productGroupsAtom,
  productRelationsAtom,
  webSocketConnectedAtom
} from '../state.js';

// TODO: production should be wss://
let WEBSOCKET_URL = `wss://${window.location.host}/ws`;

// If window location is localhost, use ws://
if (window.location.host === 'localhost:8080') {
  WEBSOCKET_URL = `ws://${window.location.host}/ws`;
}

const RECONNECT_DELAY = 5000;

function useWebSocket() {
  const wsRef = useRef(null);
  const reconnectRef = useRef(null);
  const [isConnected, setConnected] = useAtom(webSocketConnectedAtom);

  const [brands, setBrands] = useAtom(brandsAtom);
  const [products, updateProducts] = useAtom(productsAtom);
  const [productsById, updateProductsById] = useAtom(productsByIdAtom);
  const [productsByUrl, updateProductsByUrl] = useAtom(productsByUrlAtom);
  const [productGroups, setProductGroups] = useAtom(productGroupsAtom);
  const [productRelations, setProductRelations] = useAtom(productRelationsAtom);

  const setupWebSocket = () => {
    if (wsRef.current) {
      wsRef.current.close();
    }

    const ws = new WebSocket(WEBSOCKET_URL, "ecup");

    ws.onopen = () => {
      setConnected(true);
      if (reconnectRef.current) {
        clearTimeout(reconnectRef.current);
      }
    };

    ws.onerror = (errorEvent) => {
      console.error('WebSocket error:', errorEvent);
      setConnected(false);
    };

    ws.onclose = (event) => {
      console.log('WebSocket closed:', event);
      setConnected(false);

      // Use ref to manage reconnection and ensure it's not called multiple times
      if (!reconnectRef.current) {
        reconnectRef.current = setTimeout(() => {
          setupWebSocket();
          reconnectRef.current = null;
        }, RECONNECT_DELAY);
      }
    };

    wsRef.current = ws;
  };

  useEffect(() => {
    setupWebSocket();

    return () => {
      if (wsRef.current) {
        wsRef.current.close();
      }
      if (reconnectRef.current) {
        clearTimeout(reconnectRef.current);
      }
    };
  }, []);

  useEffect(() => {
    // Handle incoming messages
    if (wsRef.current) {
      wsRef.current.onmessage = (event) => {
        let data;
        try {
          data = JSON.parse(event.data);
        } catch (error) {
          console.error('Error parsing JSON:', event.data);
        }

        if (data.ping) {
          wsRef.current.send(JSON.stringify({ pong: true }));
          return;
        }

        console.log(data);
        switch (data.action) {
          case 'create':
            switch (data.type) {
              case 'product':
                const product = data.data;
                if (product && product.url && product.part_number) {
                  updateProducts({ [product.url]: product });
                  updateProductsById({ [product.id]: product });
                  updateProductsByUrl({ [product.url]: product.id });
                } else {
                  console.error('Product has no url:', product);
                }
                break;
              case 'product_relation':
                if (productRelations && Array.isArray(productRelations)) {
                  const relation = data.data;
                  if (relation) {
                    setProductRelations([relation, ...productRelations]);
                  }
                } else {
                  console.error('productRelations is not an array');
                }
                break;
              default:
                console.log('Unhandled create type:', data.type);
                break;
            };
            break;
          case 'delete':
            switch (data.type) {
              case 'product_relation':
                if (productRelations && Array.isArray(productRelations) && productRelations.length) {
                  const relation = data.data;
                  if (relation) {
                    const newRelations = productRelations.filter(r => r.id !== relation.id);
                    setProductRelations(newRelations);
                  }
                } else {
                  console.error('productRelations is not an array or has no length');
                }
                break;
              default:
                console.log('Unhandled delete type:', data.type);
                break;
            };
            break;
          case 'update':
            switch (data.type) {
              case 'brand':
                break;
              case 'product':
                const product = data.data;
                if (product && product.url && product.part_number) {
                  const oldProduct = productsById[product.id];
                  updateProducts({ [product.url]: product });
                  updateProductsById({ [product.id]: product });
                  if (oldProduct.url !== product.url) {
                    updateProductsByUrl({ [oldProduct.url]: null });
                  }
                  updateProductsByUrl({ [product.url]: product.id });
                } else {
                  console.error('Product has no url:', product);
                }
                break;
              case 'product_group':
                break;
              default:
                console.log('Unhandled type:', data.type);
                break;
            };
            break;
          default:
            console.log('Unhandled action:', data.action, ", type:", data.type, ", part number:", data.data?.part_number);
            break;
        };
      }
    }
  }, [updateProducts, productRelations]);

  const sendMessage = (message) => {
    if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
      wsRef.current.send(JSON.stringify(message));
    }
  };

  return {
    sendMessage,
    isConnected
  };
}

export default useWebSocket;
