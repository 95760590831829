import React, { useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Button, Checkbox, Form, Table } from 'semantic-ui-react';

const AdminProductDownloads = ({ download }) => {
  const [checked, setChecked] = useState(false);
  const [filename, setFilename] = useState(download.filename);
  const [loading, setLoading] = useState(false);

  const deleteFile = async (data) => {
    const [downloadId] = data;
    const response = await fetch(`/api/product_downloads/${downloadId}`, {
      method: 'DELETE',
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  }

  const updateFile = async (data) => {
    const [downloadId, filename] = data;
    const response = await fetch(`/api/product_downloads/${downloadId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ filename: filename }),
    });
  }

  const deleteFileMutation = useMutation({
    mutationFn: deleteFile,
    onMutate: (data) => {
      setLoading(true);
    },
    onSuccess: (data) => {
      setLoading(false);
    },
  });

  const updateFileMutation = useMutation({
    mutationFn: updateFile,
    onMutate: (data) => {
      setLoading(true);
    },
    onSuccess: (data) => {
      setLoading(false);
    },
  });

  useEffect(() => {
    setFilename(download.filename);
  }
  , [download]);

  function formatFileSize(size) {
    if (size >= 1024 * 1024 * 1024) {
      return (size / (1024 * 1024 * 1024)).toFixed(2) + ' GB';
    } else if (size >= 1024 * 1024) {
      return (size / (1024 * 1024)).toFixed(2) + ' MB';
    } else if (size >= 1024) {
      return (size / 1024).toFixed(2) + ' KB';
    } else {
      return size + ' bytes';
    }
  }

  return (
    <Table.Row key={download.id}>
      <Table.Cell>
        <Form onSubmit={() => updateFileMutation.mutate([download.id, filename])}>
          <Form.Input
            fluid
            value={filename}
            onChange={(e, { value }) => setFilename(value)}
          />
        </Form>
      </Table.Cell>
      <Table.Cell collapsing>{formatFileSize(download.size)}</Table.Cell>
      <Table.Cell collapsing>
        <Checkbox
          checked={checked}
          onChange={() => setChecked(!checked)}
          style={{ marginRight: '1em' }}
        />
        <Button
          negative
          disabled={!checked}
          loading={loading}
          icon='trash'
          onClick={() => deleteFileMutation.mutate([download.id])}
        />
      </Table.Cell>
    </Table.Row>
  );
}

export default React.memo(AdminProductDownloads);
