import React, { useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useAtom } from 'jotai';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { Divider, Dropdown, Form, FormButton, FormCheckbox, FormDropdown, FormGroup, FormInput, FormSelect, Header, Icon, Label } from 'semantic-ui-react'
import { brandOptionsAtom } from '../atoms/brandOptions.js';
import {
  brandsAtom,
  productsAtom,
} from '../state.js';
import {
  replacedByOptionsAtom,
  replacedBySearchAtom,
} from '../atoms/replacedBySearchAtom.js';

const AdminProductDetails = ({ product }) => {
  const [brands] = useAtom(brandsAtom);
  const [brandOptions] = useAtom(brandOptionsAtom);
  const [products] = useAtom(productsAtom);
  const [replacedByOptions] = useAtom(replacedByOptionsAtom);
  const [, setReplacedBySearch] = useAtom(replacedBySearchAtom);

  const [barcode, setBarcode] = useState('');
  const [brandId, setBrandId] = useState('');
  const [brandPartNumber, setBrandPartNumber] = useState('');
  const [case_quantity, setCaseQuantity] = useState(0);
  const [discontinued, setDiscontinued] = useState(false);
  const [name, setName] = useState('');
  const [partNumber, setPartNumber] = useState('');
  const [primary, setPrimary] = useState(false);
  const [promoted, setPromoted] = useState('');
  const [published, setPublished] = useState(false);
  const [replacedBy, setReplacedBy] = useState(0);
  const [shortName, setShortName] = useState('');
  const [supplierPartNumber, setSupplierPartNumber] = useState('');
  const [url, setUrl] = useState('');
  const [weight, setWeight] = useState('');

  const [brand, setBrand] = useState({});
  const [partNumberEdited, setPartNumberEdited] = useState(false);
  const [replacedByProduct, setReplacedByProduct] = useState({});

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (product) {
      unstable_batchedUpdates(() => {
        setBarcode(product.barcode);
        setBrandId(product.brand_id);
        setBrandPartNumber(product.brand_part_number);
        setCaseQuantity(product.case_quantity);
        setDiscontinued(product.discontinued);
        setName(product.name);
        setPartNumber(product.part_number);
        setPartNumberEdited(false);
        setPrimary(product.primary);
        setPromoted(product.promoted);
        setPublished(product.published);
        setReplacedBy(product.replaced_by);
        setShortName(product.short_name);
        setSupplierPartNumber(product.supplier_part_number);
        setUrl(product.url);
        setWeight(product.weight);

        if (product.replaced_by) {
          const replacedByProduct = Object.values(products).find(p => p.id === product.replaced_by);
          setReplacedByProduct(replacedByProduct);
        }
      });
    }
  }, [product]);

  useEffect(() => {
    if (brandId) {
      const brand = Object.values(brands).find(b => b.id === brandId);
      setBrand(brand);
    }
  }, [brandId]);

  useEffect(() => {
    if (replacedBy) {
      const replacedByProduct = Object.values(products).find(p => p.id === replacedBy);
      setReplacedByProduct(replacedByProduct);
    }
  }, [replacedBy]);

  useEffect(() => {
    if (brand) {
      if ((partNumber === '' || !partNumberEdited) && (!product || product.id === 0)) {
        setPartNumber(brand.prefix);
      }
    }
  }, [brand]);

  useEffect(() => {
    if (!product || product.id === 0) {
      const url = `${(partNumber || '').toLowerCase()}-${name.toLowerCase()}`.replace(/[^a-z0-9]+/g, '-').replace(/^-|-$/g, '');
      setUrl(url);
    }
  }, [brand, name, partNumber]);

  const createProduct = async (data) => {
    const [product] = data;
    const response = await fetch('/api/products', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(product),
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  }

  const updateProduct = async (data) => {
    const [productId, product] = data;
    const response = await fetch(`/api/products/${productId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(product),
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  }

  const createProductMutation = useMutation({
    mutationFn: createProduct,
    onMutate: (data) => {
      setLoading(true);
    },
    onSuccess: (data) => {
      setLoading(false);
      const interval = setInterval(() => {
        const product = products[data.url];
        if (product) {
          navigate(`/products/${product.url}`);
          clearInterval(interval);
        }
      }, 100);
    },
  });

  const updateProductMutation = useMutation({
    mutationFn: updateProduct,
    onMutate: (data) => {
      setLoading(true);
    },
    onSuccess: (data) => {
      setLoading(false);
    },
  });

  return (
    <Form onSubmit={() => {
      if (!product || product.id === 0) {
        createProductMutation.mutate([{
          product_group_ids: [],
          barcode: barcode,
          brand_id: brandId,
          brand_part_number: brandPartNumber,
          case_quantity: case_quantity,
          discontinued: discontinued,
          name: name,
          part_number: partNumber,
          primary: primary,
          promoted: Number(promoted),
          published: published,
          replaced_by: replacedBy,
          short_name: shortName,
          supplier_part_number: supplierPartNumber,
          url: url,
          weight: Number(weight),
        }]);
      } else {
        updateProductMutation.mutate([product.id, {
          barcode: barcode,
          brand_id: brandId,
          brand_part_number: brandPartNumber,
          case_quantity: case_quantity,
          discontinued: discontinued,
          name: name,
          part_number: partNumber,
          primary: primary,
          promoted: Number(promoted),
          published: published,
          replaced_by: replacedBy,
          short_name: shortName,
          supplier_part_number: supplierPartNumber,
          url: url,
          weight: Number(weight),
        }]);
      }
    }}>
      <FormInput
        label='Name'
        name='name'
        value={name}
        required
        onChange={(e, { value }) => setName(value)}
      />

      <FormGroup widths='equal'>
        <FormSelect
          label='Brand'
          name='brand_id'
          search
          options={brandOptions}
          required
          value={brandId}
          onChange={(e, { value }) => setBrandId(value)}
        />
        <FormInput
          label='Promoted'
          type='number'
          name='promoted'
          value={promoted || 0}
          onChange={(e, { value }) => setPromoted(value)}
        />
      </FormGroup>

      <FormGroup widths='equal'>
        <FormInput
          label='Part Number'
          name='part_number'
          value={partNumber}
          required
          onChange={(e, { value }) => {
            setPartNumber(value)
            setPartNumberEdited(true);
          }}
        />
        <FormCheckbox
          label='Published'
          name='published'
          checked={published}
          onChange={(e, { checked }) => setPublished(checked)}
        />
      </FormGroup>

      <FormGroup widths='equal'>
        <FormInput
          label='Brand Part Number'
          name='brand_part_number'
          value={brandPartNumber}
          required
          onChange={(e, { value }) => setBrandPartNumber(value)}
        />
        <FormInput
          label='Supplier Part Number'
          name='supplier_part_number'
          value={supplierPartNumber}
          required
          onChange={(e, { value }) => setSupplierPartNumber(value)}
        />
      </FormGroup>

      <FormGroup widths='equal'>
        {(replacedBy) ? (
          <Form.Field>
            <b>Replaced By</b><br />
            <Label style={{ marginTop: '0.3em' }} size='large' color='red' as='a' onClick={() => setReplacedBy(0)}>
              {replacedByProduct.part_number} - {replacedByProduct.name}
              <Icon name='delete' />
            </Label>
          </Form.Field>
        ) : (
          <FormDropdown
            label='Replaced By'
            name='replaced_by'
            placeholder='-'
            search
            options={replacedByOptions}
            value={replacedBy}
            onSearchChange={(e, { searchQuery }) => setReplacedBySearch(searchQuery)}
            onChange={(e, { value }) => setReplacedBy(value)}
            selection
          />
        )}

        <FormCheckbox
          label='Discontinued'
          name='discontinued'
          checked={discontinued}
          onChange={(e, { checked }) => setDiscontinued(checked)}
        />
      </FormGroup>

      <FormGroup widths='equal'>
        <FormInput
          label='Short Name'
          disabled={!primary}
          required={primary}
          name='short_name'
          value={shortName}
          onChange={(e, { value }) => setShortName(value)}
        />
        <FormCheckbox
          label='Primary'
          name='primary'
          checked={primary}
          onChange={(e, { checked }) => setPrimary(checked)}
        />
      </FormGroup>

      <FormGroup widths='equal'>
        <FormInput
          label='Barcode'
          name='barcode'
          value={barcode}
          onChange={(e, { value }) => setBarcode(value)}
        />
        <FormInput
          label='Weight (grams)'
          name='weight'
          type='number'
          value={weight || 0}
          onChange={(e, { value }) => setWeight(value)}
        />
      </FormGroup>

      <FormGroup widths='equal'>
        <FormInput
          label='Case Quantity'
          name='case_quantity'
          type='number'
          value={case_quantity || 0}
          onChange={(e, { value }) => setCaseQuantity(value)}
        />
        <FormInput
          label='URL'
          name='url'
          disabled={product && product?.id !== 0}
          required
          value={url}
          onChange={(e, { value }) => setUrl(value)}
        />
      </FormGroup>

      <Divider />
      <FormButton
        positive
        type='submit'
        loading={loading}
      >
        {product && product.id !== 0 ? 'Update' : 'Create'}
      </FormButton>
    </Form>
  );
}

export default React.memo(AdminProductDetails);
