import { useEffect } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useAtom } from 'jotai';
import {
  bundleFinishedAtom,
  fullBundleFinishedAtom,
  brandsAtom,
  countriesAtom,
  currenciesAtom,
  inventoryLocationsAtom,
  languagesAtom,
  priceListsAtom,
  productsAtom,
  productsByIdAtom, 
  productsByUrlAtom, 
  productGroupsAtom,
  productRelationsAtom,
  shipmentMethodsAtom,
  specialPricesAtom,
  suppliersAtom,
} from '../state.js';

const STARTER_DATA_URL = '/starter-bundle.json';
const DATA_URL = '/bundle.json';

const useBundle = () => {
  const [bundleFinished, setBundleFinished] = useAtom(bundleFinishedAtom);
  const [fullBundleFinished, setFullBundleFinished] = useAtom(fullBundleFinishedAtom);
  const [brands, setBrands] = useAtom(brandsAtom);
  const [countries, setCountries] = useAtom(countriesAtom);
  const [currencies, setCurrencies] = useAtom(currenciesAtom);
  const [inventoryLocations, setInventoryLocations] = useAtom(inventoryLocationsAtom);
  const [languages, setLanguages] = useAtom(languagesAtom);
  const [priceLists, setPriceLists] = useAtom(priceListsAtom);
  const [products, setProducts] = useAtom(productsAtom);
  const [productsById, setProductsById] = useAtom(productsByIdAtom);
  const [productsByUrl, setProductsByUrl] = useAtom(productsByUrlAtom);
  const [productGroups, setProductGroups] = useAtom(productGroupsAtom);
  const [productRelations, setProductRelations] = useAtom(productRelationsAtom);
  const [shipmentMethods, setShipmentMethods] = useAtom(shipmentMethodsAtom);
  const [specialPrices, setSpecialPrices] = useAtom(specialPricesAtom);
  const [suppliers, setSuppliers] = useAtom(suppliersAtom);

  useEffect(() => {
    const fetchStarterBundle = async () => {
      try {
        // Brands
        setBrands(arrayToMapWithTag(window.starterBundle.brands));

        // Countries
        setCountries(arrayToMapWithId(window.starterBundle.countries));

        // Currencies
        setCurrencies(arrayToMapWithId(window.starterBundle.currencies));

        // Languages
        setLanguages(arrayToMapWithId(window.starterBundle.languages));

        // Products
        setProducts(window.starterBundle.products.reduce((acc, item) => {
          if (item.url && item.part_number) {
            acc[item.url] = item;
          } else {
            // console.error('Product has no url:', item);
          }
          return acc;
        }, {}));

        // Products By ID
        setProductsById(arrayToMapWithId(window.starterBundle.products));

        // Products By URL
        setProductsByUrl(window.starterBundle.products.reduce((acc, item) => {
          if (item.url && item.part_number) {
            acc[item.url] = item.id;
          } else {
            // console.error('Product has no url:', item);
          }
          return acc;
        }, {}));

        // Product Groups
        setProductGroups(arrayToMapWithTag(window.starterBundle.product_groups));

        // Shipment Methods
        setShipmentMethods(arrayToMapWithId(window.starterBundle.shipment_methods));

        // Special Prices
        if (window.starterBundle.special_prices) {
          setSpecialPrices(window.starterBundle.special_prices);
        }

        setBundleFinished(true);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const fetchBundle = async () => {
      try {
        // Sleep for 4 seconds to simulate a slow connection
        // await new Promise(resolve => setTimeout(resolve, 4000));

        const response = await fetch(DATA_URL + '?t=' + Date.now());
        const data = await response.json();

        // Products
        // data.products.forEach(product => {
        //   if (product.url && product.part_number) {
        //     setProducts({ [product.url]: product });
        //   } else {
        //     // console.error('Product has no url:', product);
        //   }
        // });

        // Inventory Locations
        setInventoryLocations(arrayToMapWithId(data.inventory_locations));

        // Price Lists
        setPriceLists(arrayToMapWithId(data.price_lists));

        // Products
        setProducts(data.products.reduce((acc, item) => {
          if (item.url && item.part_number) {
            acc[item.url] = item;
          } else {
            // console.error('Product has no url:', item);
          }
          return acc;
        }, {}));

        // Products By ID
        setProductsById(arrayToMapWithId(data.products));

        // Products By URL
        setProductsByUrl(data.products.reduce((acc, item) => {
          if (item.url && item.part_number) {
            acc[item.url] = item.id;
          } else {
            // console.error('Product has no url:', item);
          }
          return acc;
        }, {}));

        // Product Relations
        setProductRelations(data.product_relations);

        // Special Prices
        if (data.special_prices) {
          setSpecialPrices(data.special_prices);
        }

        // Suppliers
        setSuppliers(data.suppliers);

        setFullBundleFinished(true);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const arrayToMapWithId = (array) =>
      array?.reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {});

    const arrayToMapWithTag = (array) =>
      array?.reduce((acc, item) => {
        acc[item.tag] = item;
        return acc;
      }, {});

    fetchStarterBundle();
    fetchBundle();
  }, []);
};

export default useBundle;
