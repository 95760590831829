import { atom } from "jotai";
import { shipmentMethodsAtom } from "../state.js";

export const shipmentMethodOptionsCountryAtom = atom(0);

export const shipmentMethodOptionsAtom = atom((get) => {
  const country = get(shipmentMethodOptionsCountryAtom);
  const shipmentMethods = get(shipmentMethodsAtom);

  // return Object.values(shipmentMethods).map((shipmentMethod) => ({
  //   key: shipmentMethod.id,
  //   text: shipmentMethod.short_name,
  //   value: shipmentMethod.id,
  // }));

  return Object.values(shipmentMethods)
    .filter((shipmentMethod) => {
      return shipmentMethod.country_id === country;
    })
    .map((shipmentMethod) => ({
      key: shipmentMethod.id,
      text: shipmentMethod.short_name,
      value: shipmentMethod.id,
    }));
});
