import React from 'react';
import { useAtom } from 'jotai';
import {
} from '../state.js';
import { userAtom } from '../atoms/authAtom';
import ErrorPage from '../ErrorPage.js';

const AdminBrands = () => {
  const [user] = useAtom(userAtom);

  if (!user?.admin) {
    return (
      <ErrorPage />
    );
  }

  return (
    <></>
  );
}

export default AdminBrands;
