import React, { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { Form } from 'semantic-ui-react';
import {
  productsByIdAtom,
  productRelationsAtom
} from '../state.js';

const AdminPrimaryProductFitsAdd = ({ primaryProduct }) => {
  const [productsById] = useAtom(productsByIdAtom);
  const [productRelations] = useAtom(productRelationsAtom);

  const [currentChildren, setCurrentChildren] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [search, setSearch] = useState('');

  useEffect(() => {
    setCurrentChildren(productRelations.filter(r => r.parent_product_id === primaryProduct.id));
  }, [productRelations, primaryProduct]);

  const customSearch = (options, searchQuery) => {
    const searchTerms = searchQuery.toLowerCase().split(' ').filter(term => term.trim() !== '');

    let newAvailable = [];
    for (const p of Object.values(productsById)) {
      if (p.primary === false && !currentChildren.some(r => r.child_product_id === p.id)) {
        const combinedFields = (p.part_number + ' ' + p.name).toLowerCase();
        if (searchTerms.every(term => combinedFields.includes(term))) {
          newAvailable.push({
            key: p.id,
            value: p.id,
            text: p.part_number + ' - ' + p.name,
          });
          if (newAvailable.length === 10) {
            break;
          }
        }
      }
    }

    newAvailable.sort((a, b) => a.text.localeCompare(b.text));
    return newAvailable;
  };

  const getOptions = () => {
    const searchTerms = search.toLowerCase().split(' ').filter(term => term.trim() !== '');
    let options = [];

    for (const p of Object.values(productsById)) {
      const combinedFields = (p.part_number + ' ' + p.name).toLowerCase();
      if (searchTerms.every(term => combinedFields.includes(term))) {
        if (p.primary === false && !currentChildren.some(r => r.child_product_id === p.id)) {
          options.push({
            key: p.id,
            value: p.id,
            text: p.part_number + ' - ' + p.name,
          });
          if (options.length === 10) {
            break;
          }
        }
      }
    }

    options.sort((a, b) => a.text.localeCompare(b.text));

    return options;
  };

  return (
    <Form loading={loading} fluid>
      <Form.Group fluid inline>
        <Form.Dropdown
          width={16}
          placeholder='Add related product...'
          fluid
          search={(options, searchQuery) => customSearch(options, searchQuery)}
          selection
          options={getOptions()}
          onSearchChange={(e, data) => {
            setSearch(data.searchQuery)
          }}
          onChange={(e, data) => {
            setData(data)
          }}
        />
        <Form.Button
          icon='add'
          positive
          onClick={(e) => {
            e.preventDefault();
            setLoading(true);
            const childProductId = data.value;
            const parentProductId = primaryProduct.id;
            const response = fetch('/api/product_relations', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                parent_product_id: parentProductId,
                child_product_id: childProductId,
              }),
            });
            if (!response.ok) {
              setError('Network response was not ok');
              setLoading(false);
              return;
            }
            setLoading(false);
            return response.json();
          }}
        />
      </Form.Group>
    </Form>
  )
}

export default AdminPrimaryProductFitsAdd;
