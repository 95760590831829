import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAtom } from 'jotai';
import { useMutation } from '@tanstack/react-query';
import { Divider, Form } from 'semantic-ui-react';
import {
  productGroupsAtom,
} from '../state.js';
import { userAtom } from '../atoms/authAtom';
import ErrorPage from '../ErrorPage.js';
import Title from '../components/Title.js';

const AdminProductGroup = ({ }) => {
  const [productGroups] = useAtom(productGroupsAtom);
  const [user] = useAtom(userAtom);

  const [group, setGroup] = useState(null);
  const [loading, setLoading] = useState(false);

  const { tag } = useParams();
  const { t } = useTranslation();

  const [formTag, setFormTag] = useState('');
  const [formInMenu, setFormInMenu] = useState(false);

  const updateProductGroup = async (data) => {
    const [id, group] = data;

    let url;
    if (id) {
      url = `/api/product_groups/${id}`;
    } else {
      url = '/api/product_groups';
    }

    const response = await fetch(url, {
      method: (id ? 'PUT' : 'POST'),
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(group),
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  }

  const updateProductGroupMutation = useMutation({
    mutationFn: updateProductGroup,
    onMutate: (data) => {
      setLoading(true);
    },
    onSuccess: (data) => {
      setLoading(false);
    },
  });

  useEffect(() => {
    const group = productGroups[tag];
    setGroup(group);
    if (group) {
      setFormTag(group.tag);
      setFormInMenu(group.in_menu);
    }
  }, [tag, productGroups]);

  if (!user?.admin) {
    return (
      <ErrorPage />
    );
  }

  return (
    <>
      <Title title={group?.tag} />
      <Divider hidden />

      <Form
        onSubmit={(e) => {
          e.preventDefault();
          updateProductGroupMutation.mutate([group?.id, { tag: formTag, in_menu: formInMenu }]);
        }}
        loading={loading}
      >
        <Form.Input
          label='Tag'
          required
          value={formTag}
          onChange={(e, { value }) => setFormTag(value)}
        />
        <Form.Checkbox
          label='In Menu'
          checked={formInMenu}
          onChange={(e, { checked }) => setFormInMenu(checked)}
        />
        <Form.Button
          type='submit'
          positive
        >
          Save
        </Form.Button>
      </Form>
    </>
  );
}

export default AdminProductGroup;
