import React, { useEffect, useRef, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { Accordion, Button, Divider, Form, Header, Icon, Segment, Transition } from 'semantic-ui-react'
import { useAtom } from 'jotai';
import { useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import {
  languageAtom,
} from '../state.js';
import { filteredProductsOptionsAtom } from '../atoms/filteredProductsAtom.js';
import { relevantBrandsAtom } from '../atoms/relevantBrandsAtom.js';
import { relevantFitsAtom } from '../atoms/relevantFitsAtom.js';
import { relevantTagsAtom } from '../atoms/relevantTagsAtom.js';
import { mobileSize, responsiveAtom } from '../atoms/responsiveAtom.js';

const ProductFilter = ({ primary, color, size = 'large' }) => {
  const [filteredProductsOptions, setFilteredProductOptions] = useAtom(filteredProductsOptionsAtom);
  const [language] = useAtom(languageAtom);
  const [relevantBrands] = useAtom(relevantBrandsAtom);
  const [relevantFits] = useAtom(relevantFitsAtom);
  const [relevantTags] = useAtom(relevantTagsAtom);
  const [responsive] = useAtom(responsiveAtom);

  const location = useLocation();
  const { t } = useTranslation();

  const [isExpanded, setIsExpanded] = useState(window.innerWidth > mobileSize);

  const setSearch = (e) => {
    if (e.target.value.length > 1 || e.target.value.length === 0) {
      setFilteredProductOptions({ ...filteredProductsOptions, search: e.target.value });
    }
  }

  return (
    <>
      <Accordion defaultActiveIndex={0} style={{ marginTop: '0.4em' }}>
        <Accordion.Title
          active={isExpanded}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <Button
            icon
            labelPosition='right'
          >
            {t('filter')}
            <Icon name={(isExpanded ? 'up arrow' : 'down arrow')} />
          </Button>
        </Accordion.Title>
        <Accordion.Content active={isExpanded}>
          <Segment secondary>
            {relevantBrands.map(brand => (
              <Button
                className='filterTag'
                size='small'
                key={brand.id}
                positive={filteredProductsOptions.brand_id === brand.id}
                onClick={(e) => {
                  e.stopPropagation();
                  if (filteredProductsOptions.brand_id === brand.id) {
                    setFilteredProductOptions({ ...filteredProductsOptions, brand_id: null })
                    return;
                  }
                  setFilteredProductOptions({ ...filteredProductsOptions, brand_id: brand.id })
                }}
              >
                {brand.name}
              </Button>
            ))}

            <Divider hidden />

            {relevantTags.map(group => (
              <Button
                className='filterTag'
                size='small'
                key={group.tag}
                positive={filteredProductsOptions.sub_tag === group.tag}
                onClick={(e) => {
                  e.stopPropagation();
                  if (filteredProductsOptions.sub_tag === group.tag) {
                    setFilteredProductOptions({ ...filteredProductsOptions, sub_tag: '' })
                    return;
                  }
                  setFilteredProductOptions({ ...filteredProductsOptions, sub_tag: group.tag })
                }}
              >
                {group.names[language]}
              </Button>
            ))}

            {!primary && (
              <Divider hidden />
            )}

            {relevantFits.map(product => (
              <Button
                className='filterTag'
                size='small'
                key={product.url}
                positive={filteredProductsOptions.fits === product.id}
                onClick={(e) => {
                  e.stopPropagation();
                  if (filteredProductsOptions.fits === product.id) {
                    setFilteredProductOptions({ ...filteredProductsOptions, fits: null })
                    return;
                  }
                  setFilteredProductOptions({ ...filteredProductsOptions, fits: product.id })
                }}
              >
                {product.short_name}
              </Button>
            ))}

            <Divider hidden />

            <Form
              onClick={(e) => e.stopPropagation()}
            >
              <Form.Input
                fluid
                icon='search'
                iconPosition='left'
                placeholder={`${t('filter2')}...`}
                onChange={setSearch}
              />
            </Form>
          </Segment>
        </Accordion.Content>
      </Accordion>
    </>
  );
}

export default React.memo(ProductFilter);
