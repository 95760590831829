export const primaryThumbnailUrl = (images, webp, productUrl) => {
  // If there are no images, return null
  if (!images || images.length === 0) {
    return null;
  }

  let image = null;
  let extension = 'jpg';
  let path = 'jpeg';
  // Is there an image in the array with 'primary' set to true?
  const primaryImage = images.find((image) => image.primary);
  // If so, use that image
  if (primaryImage) {
    image = primaryImage;
  } else {
    // Otherwise, use the first image in the array
    image = images[0];
  }

  // If webp is supported, use webp extension
  if (webp) {
    extension = 'webp';
    path = 'webp';
  }

  return `/thumbnails/big-${path}/${productUrl}-${image.id}.${extension}`;
};

export const smallThumbnailUrl = (image, webp, productUrl) => {
  if (!image) {
    return null;
  }

  let extension = 'jpg';
  let path = 'jpeg';

  // If webp is supported, use webp extension
  if (webp) {
    extension = 'webp';
    path = 'webp';
  }

  return `/thumbnails/small-${path}/${productUrl}-${image.id}.${extension}`;
};

export const bigThumbnailUrl = (image, webp, productUrl) => {
  if (!image) {
    return null;
  }

  let extension = 'jpg';
  let path = 'jpeg';

  // If webp is supported, use webp extension
  if (webp) {
    extension = 'webp';
    path = 'webp';
  }

  return `/thumbnails/big-${path}/${productUrl}-${image.id}.${extension}`;
};
