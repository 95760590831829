import { useEffect } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useAtom } from 'jotai';
import {
  countryAtom,
  currencyAtom,
  languageAtom,
  showVatAtom,
} from '../state.js';

const useSitesConfig = () => {
  const [country, setCountry] = useAtom(countryAtom);
  const [currency, setCurrency] = useAtom(currencyAtom);
  const [language, setLanguage] = useAtom(languageAtom);
  const [showVat, setShowVat] = useAtom(showVatAtom);
  
  useEffect(() => {
    unstable_batchedUpdates(() => {
      setCountry(window.sitesConfig.country_id);
      setCurrency(window.sitesConfig.currency_id);
      setLanguage(window.sitesConfig.language_id);
      setShowVat(true);
    });
  }, []);
};

export default useSitesConfig;
