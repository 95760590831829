import { atom } from 'jotai';
import { productGroupsAtom } from '../state.js';

export const leftMenuAtom = atom((get) => {
  const groups = get(productGroupsAtom);
  const topLevel = [];
  const childrenMap = {};

  Object.values(groups).forEach(group => {
    if (group.in_menu) {
      if (!group.parent_id) {
        topLevel.push(group);
      } else {
        if (!childrenMap[group.parent_id]) {
          childrenMap[group.parent_id] = [];
        }
        childrenMap[group.parent_id].push(group);
      }
    }
  });

  // Sort function that sorts by position first, then by tag
  const sortByPositionAndTag = (a, b) => {
    if (a.position !== b.position) {
      return a.position - b.position; // Sort by position
    }
    // If position is the same, sort by tag
    return a.tag.localeCompare(b.tag);
  };

  // Sort the topLevel array
  topLevel.sort(sortByPositionAndTag);

  // Sort each children group in childrenMap
  for (let parentId in childrenMap) {
    childrenMap[parentId].sort(sortByPositionAndTag);
  }

  return {
    topLevel: topLevel,
    childrenMap: childrenMap
  };
});
