import React, { Fragment, useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { Link } from 'react-router-dom';
import { Breadcrumb, Header, Segment } from 'semantic-ui-react'
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import {
  bundleFinishedAtom,
  productsByIdAtom,
  productRelationsAtom,
} from '../state.js';

const ProductFits = ({ product }) => {
  const { t } = useTranslation();

  const [bundleFinished] = useAtom(bundleFinishedAtom);
  const [productsById] = useAtom(productsByIdAtom);
  const [productRelations] = useAtom(productRelationsAtom);

  const [fits, setFits] = useState([]);

  useEffect(() => {
    if (bundleFinished) {
      unstable_batchedUpdates(() => {
        const relations = productRelations.filter(relation => relation.child_product_id === product.id).map(relation => {
          const parentProduct = productsById[relation.parent_product_id];
          if (parentProduct?.primary) {
            return parentProduct;
          }
        });

        // Sort by short name
        relations.sort((a, b) => {
          return a.short_name.localeCompare(b.short_name);
        });

        setFits(relations);
      });
    }
  }, [bundleFinished, product, productsById, productRelations]);

  if (!fits.length) return null;

  return (
    <Segment secondary textAlign='left'>
      <Header as='h3' dividing>
        {t('this_product_fits')}
      </Header>
      <Breadcrumb>
        {fits?.map((fit, index) => {
          return (
            <React.Fragment key={`fits-${index}`}>
              <Breadcrumb.Section key={fit?.id} as={Link} to={`/products/${fit?.url}`}>
                {fit?.short_name}
              </Breadcrumb.Section>
              {index !== fits.length - 1 && <Breadcrumb.Divider />}
            </React.Fragment>
          );
        })}
      </Breadcrumb>
    </Segment>
  );
};

export default React.memo(ProductFits);
