import React, { } from 'react';
import { Divider, Header, Segment } from 'semantic-ui-react'

const B2B = ({ }) => {
  return (
    <Segment>
      <Header as='h1'>Vill du bli återförsäljare?</Header>
      <Divider />
      <p>Kontakta oss på <a href="mailto:b2b@latera.se">b2b@latera.se</a>.</p>
    </Segment>
  )
}

export default B2B;
