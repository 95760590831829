import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { unstable_batchedUpdates } from 'react-dom';
import { Form, Input, Message } from 'semantic-ui-react'
import { useAtom } from 'jotai';
import { cartAtom } from '../atoms/cartAtom';

const AddToCart = ({ product }) => {
  const [cart, setCart] = useAtom(cartAtom);

  const [disabled, setDisabled] = useState(true);
  const [labelColor, setLabelColor] = useState('green');
  const [cartValue, setCartValue] = useState(1);

  useEffect(() => {
    unstable_batchedUpdates(() => {
      if (product?.stock > 0) {
        const cartItem = cart?.items?.find(item => item.id === product.id);
        if (cartItem) {
          if (cartItem.quantity >= product.stock) {
            setDisabled(true);
            setLabelColor('green');
          } else {
            setDisabled(false);
            setLabelColor('green');
          }
        } else {
          setDisabled(false);
          setLabelColor('green');
        }
      } else {
        setDisabled(true);
        setLabelColor('grey');
      }
    });
  }, [cart, product?.stock]);

  const handleCartChange = (e) => {
    e.stopPropagation()
    if (e.target.value < 0) {
      return;
    }
    
    const cartItem = cart?.items?.find(item => item.id === product.id);
    if ((parseInt(e.target.value) + (cartItem?.quantity || 0)) > product.stock) {
      setCartValue(product.stock - (cartItem?.quantity || 0));
      return;
    }

    setCartValue(e.target.value);
  }

  const addToCart = (e, product, i) => {
    e.stopPropagation();
    let quantity = parseInt(i);
    if (isNaN(quantity) || quantity === 0) {
      quantity = 1;
    }
  
    if (!product) {
      return;
    }

    const cartCopy = { ...cart };
    const cartItem = cartCopy.items?.find(item => item.id === product.id);
    if (cartItem) {
      cartItem.quantity += quantity;
    } else {
      if (!cartCopy.items) {
        cartCopy.items = [];
      }
      cartCopy.items.push({ id: product.id, quantity: quantity });
    }
    cartCopy.checkingOut = false;
    cartCopy.uuid = uuidv4();
    setCart(cartCopy);
  }

  return (
    <Form onSubmit={(e) => addToCart(e, product, cartValue)}>
      <Input
        disabled={disabled}
        type='number'
        action={{
          color: labelColor,
          disabled: disabled,
          icon: 'add to cart',
          size: 'big'
        }}
        onClick={(e) => e.stopPropagation()}
        onChange={(e) => handleCartChange(e) }
        placeholder='#'
        value={cartValue}
        size='big'
        fluid
      />
    </Form>
  );
}

export default React.memo(AddToCart);
