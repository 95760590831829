import React, { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { useMutation } from '@tanstack/react-query';
import { Button, ButtonGroup, Divider, Dropdown } from 'semantic-ui-react';
import {
  languageAtom,
  languagesAtom
} from '../state.js';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const AdminProductDescriptions = ({ product }) => {
  const [language] = useAtom(languageAtom);
  const [languages] = useAtom(languagesAtom);
  const [currentLanguage, setCurrentLanguage] = useState(language);
  const [description, setDescription] = useState({});
  const [value , setValue] = useState('')

  const [loading, setLoading] = useState(false);

  const updateDescription = async (data) => {
    const [id, description] = data;

    let url;
    if (id) {
      url = `/api/product_descriptions/${id}`;
    } else {
      url = '/api/product_descriptions';
    }

    const response = await fetch(url, {
      method: (id ? 'PUT' : 'POST'),
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        product_id: product.id,
        language_id: currentLanguage,
        description: description,
      }),
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  }

  const updateProductMutation = useMutation({
    mutationFn: updateDescription,
    onMutate: (data) => {
      setLoading(true);
    },
    onSuccess: (data) => {
      setLoading(false);
    },
  });

  useEffect(() => {
    if (product && product.product_descriptions) {
      const description = product.product_descriptions.find(description => description.language_id === currentLanguage);
      setDescription(description);
      setValue(description.description);
    } else {
      setDescription({});
      setValue('');
    }
  }, [currentLanguage]);

  return (
    <>
      <ButtonGroup>
        {Object.values(languages).map(language => (
          <Button
            key={language.id}
            active={currentLanguage === language.id}
            onClick={() => setCurrentLanguage(language.id)}
          >
            {language.name}
          </Button>
        ))}
      </ButtonGroup>

      <Divider hidden />

      <ReactQuill theme="snow" value={value} onChange={setValue} className=''/>

      <Divider hidden />

      <Button
        color='green'
        loading={loading}
        onClick={() => updateProductMutation.mutate([description.id, value])}
      >
        Save
      </Button>
    </>
  );
}

export default AdminProductDescriptions;
