import { unstable_batchedUpdates } from 'react-dom';
import { useAtom } from 'jotai';
import { Link } from "react-router-dom";
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, Icon } from 'semantic-ui-react'
import { userAtom } from '../atoms/authAtom';
import { useTranslation } from 'react-i18next';
import {
  checkoutHtmlAtom,
  checkoutErrorAtom,
  checkoutLoadingAtom,
  checkoutSuccessAtom,
  countryAtom,
  currencyAtom,
  languageAtom,
  productsByIdAtom,
} from '../state.js';
import {
  mobileSize,
  responsiveAtom,
} from '../atoms/responsiveAtom';
import { cartAtom } from '../atoms/cartAtom.js';
import { useEffect } from 'react';

const CheckoutButton = ({ enabled, contents }) => {
  const [user] = useAtom(userAtom);
  const [country] = useAtom(countryAtom);
  const [currency] = useAtom(currencyAtom);
  const [language] = useAtom(languageAtom);
  const [productsById] = useAtom(productsByIdAtom);
  const [responsive, setResponsive] = useAtom(responsiveAtom);
  const [, setCheckoutHtml] = useAtom(checkoutHtmlAtom);
  const [checkoutLoading, setCheckoutLoading] = useAtom(checkoutLoadingAtom);
  const [checkoutError, setCheckoutError] = useAtom(checkoutErrorAtom);
  const [checkoutSuccess, setCheckoutSuccess] = useAtom(checkoutSuccessAtom);
  const [cart, setCart] = useAtom(cartAtom);

  const queryClient = useQueryClient()
  const { t } = useTranslation();

  async function postCheckout(url, data) {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    return response.text();
  }

  const checkoutMutation = useMutation({
    mutationFn: (data) => postCheckout('/checkout', data),
    onSuccess: (data) => {
      unstable_batchedUpdates(() => {
        setCheckoutLoading(false);
        setCheckoutSuccess(true);
        setCheckoutHtml(data);
      })
      // queryClient.invalidateQueries('checkout');
    },
    onError: (error) => {
      unstable_batchedUpdates(() => {
        setCheckoutLoading(false);
        setCheckoutError(true);
        setCheckoutSuccess(false);
        setCheckoutHtml(null);
        setCart({ ...cart, checkingOut: false });
      })
    },
    onLoading: () => {
      unstable_batchedUpdates(() => {
        setCheckoutLoading(true);
        setCheckoutError(false);
        setCheckoutSuccess(false);
        setCheckoutHtml(null);
      })
    },
  });

  const handleCheckout = (e) => {
    setCart({ ...cart, checkingOut: true });

    const data = {
      country_id: country,
      currency_id: currency,
      language_id: language,
      cart: cart?.items?.map(item => ({
        product_id: item.id,
        quantity: item.quantity,
      })),
    };

    checkoutMutation.mutate(data);

    // If mobile sized, hide the cart
    if (window.innerWidth <= mobileSize) {
      setResponsive({ ...responsive, cartVisible: false });
    }
  };

  if (user?.b2b) {
    return (
      <Button
        positive
        icon
        labelPosition='left'
        size='big'
        as={Link}
        to='/b2b/checkout'
        onClick={() => {
          if (window.innerWidth <= mobileSize) {
            setResponsive({ ...responsive, cartVisible: false });
          }
        }}
        disabled={!enabled || cart?.items?.length === 0}
      >
        <Icon name='shopping cart' />
        {contents}
      </Button>
    )
  }

  return (
    <Button
      positive
      icon
      labelPosition='left'
      size='big'
      as={Link}
      to='/checkout'
      onClick={handleCheckout}
      loading={checkoutLoading}
      disabled={!enabled || cart?.items?.length === 0}
    >
      <Icon name='shopping cart' />
      {contents}
    </Button>
  );
}

export default CheckoutButton;
