import React, { useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { Label } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import {
  countryAtom,
  countriesAtom,
  currencyAtom,
  currenciesAtom,
  showVatAtom,
  specialPricesAtom
} from '../state.js';
import { userAtom } from '../atoms/authAtom.js';
import usePrice from '../hooks/usePrice.js';
import { formattedPrice } from '../utils/price.js';

const PriceLabelBig = ({ product, color, size = 'large' }) => {
  const [country] = useAtom(countryAtom);
  const [countries] = useAtom(countriesAtom);
  const [currency] = useAtom(currencyAtom);
  const [currencies] = useAtom(currenciesAtom);
  const [showVat] = useAtom(showVatAtom);
  const [specialPrices] = useAtom(specialPricesAtom);
  const [user] = useAtom(userAtom);

  const { calculatedPrice, calculatedRegularPrice } = usePrice();

  const { t } = useTranslation();

  const [hasSpecialPrice, setHasSpecialPrice] = useState(false);
  const [b2bLabelColor, setB2bLabelColor] = useState('blue');
  const [labelColor, setLabelColor] = useState('green');
  const [price, setPrice] = useState(null);
  const [specialPrice, setSpecialPrice] = useState(null);
  const [vatString, setVatString] = useState('');

  useEffect(() => {
    if (specialPrices[product?.id]) {
      setHasSpecialPrice(true);
    } else {
      setHasSpecialPrice(false);
    }


    if (product.stock === 0) {
      setB2bLabelColor('grey');
      setLabelColor('grey');
    } else {
      setB2bLabelColor('blue');
      setLabelColor('green');
    }

    if (country) {
      if (showVat) {
        setVatString(`${t('including')} ${countries[country]?.vat_percent}% ${t('vat')}`);
      } else {
        setVatString(`${t('excluding')} ${t('vat')}`);
      }
    } else {
      setVatString('');
    }
  }, [product?.prices, product?.stock, country, currency, currencies, showVat, specialPrices, user]);

  if (product.discontinued && product.stock === 0) {
    return (
      <Label color='grey' size='massive'>
        {t('discontinued')}
      </Label>
    )
  }

  return (
    <>
      <meta itemProp="priceCurrency" content={currencies[currency]?.iso4217} />
      <meta itemProp="price" content={calculatedPrice(product)} />
      {hasSpecialPrice ? (
        <Label.Group>
          <Label color={labelColor} size='big' style={{ textDecoration: 'line-through' }}>
            {formattedPrice(calculatedRegularPrice(product), currencies[currency].symbol, currencies[currency].right_side)}
          </Label>
          <Label color={b2bLabelColor} size='massive'>
            {formattedPrice(calculatedPrice(product), currencies[currency].symbol, currencies[currency].right_side)}
          </Label>
        </Label.Group>
      ) : (
        <Label.Group>
        <Label color={labelColor} size='massive'>
          {formattedPrice(calculatedPrice(product), currencies[currency].symbol, currencies[currency].right_side)}
        </Label>
        </Label.Group>
      )}
      <span style={{ fontSize: '0.8em', fontStyle: 'italic' }}>{vatString}</span>
    </>
  );
}

export default React.memo(PriceLabelBig);
