import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useAtom } from "jotai";
import {
  bundleFinishedAtom,
  fullBundleFinishedAtom,
  brandsAtom,
  languageAtom,
  productsAtom,
  productGroupsAtom,
} from '../state.js';

const useTitle = () => {
  const [bundleFinished] = useAtom(bundleFinishedAtom);
  const [fullBundleFinished] = useAtom(fullBundleFinishedAtom);
  const [brands] = useAtom(brandsAtom);
  const [language] = useAtom(languageAtom);
  const [products] = useAtom(productsAtom);
  const [productGroups] = useAtom(productGroupsAtom);

  const location = useLocation();
  const { tag } = useParams();

  useEffect(() => {
    const path = location.pathname;
    let title = 'Latera Racing';

    //const title = path === "/" ? "Latera Racing" : path.replace("/", "";

    switch (path) {
      case "/admin/brands":
        title = "Latera Admin: Brands";
        break;
      case "/admin/customers":
        title = "Latera Admin: Customers";
        break;
      case "/admin/invoices":
        title = "Latera Admin: Invoices";
        break;
      case "/admin/orders":
        title = "Latera Admin: Orders";
        break;
      case "/admin/product_groups":
        title = "Latera Admin: Product Groups";
        break;
      case "/admin/suppliers":
        title = "Latera Admin: Suppliers";
        break;
      case "/b2b":
        title = "B2B - Latera Racing";
        break;
      case "/brands":
        title = "Brands - Latera Racing";
        break;
      case "/checkout":
        title = "Checkout - Latera Racing";
        break;
      case "/error":
        title = "Error";
        break;
      case path.match(/^\/products\/.*/)?.input:
        const product = products[tag];
        const brand = Object.values(brands).find(brand => brand.id === product?.brand_id);
        if (product && brand) {
          title = brand?.name + " " + product?.name + " - Latera Racing";
        } else {
          title = "Product - Latera Racing";
        }
        break;
      case path.match(/^\/tags\/.*/)?.input:
        const group = productGroups[tag];
        if (group) {
          title = group.names[language] + " - Latera Racing";
        } else {
          title = "Tag - Latera Racing";
        }
        break;
      default:
        title = "Latera Racing";
    }

    window.document.title = title;
  }, [bundleFinished, fullBundleFinished, location]);
};

export default useTitle;
