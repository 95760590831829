import React, { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { userAtom } from '../atoms/authAtom.js';
import { Button, Modal, Form, Input, Dropdown, Checkbox, Message } from 'semantic-ui-react';

const AdminApiTableForm = ({ closeForm, open, endpoint, fields, object, refreshTrigger }) => {
  const { t } = useTranslation();
  const [user] = useAtom(userAtom);
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (object) {
      setFormData(object);
    } else {
      const initialFormData = fields
        .filter(field => field.inForm)
        .reduce((acc, field) => ({ ...acc, [field.key]: '' }), {});
      setFormData(initialFormData);
    }
  }, [object, fields]);

  const handleChange = (e, { name, value, checked, type }) => {
    setFormData({ ...formData, [name]: type === 'checkbox' ? checked : value });
  };

  const validate = () => {
    const newErrors = {};
    fields.forEach(field => {
      if (field.required && !formData[field.key]) {
        newErrors[field.key] = t('This field is required');
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validate()) return;
    setLoading(true);
    try {
      const url = object ? `/api/${endpoint}/${object.id}` : `/api/${endpoint}`;
      const method = object ? 'PUT' : 'POST';
      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      closeForm();
    } catch (error) {
      setErrors({ submit: t('An error occurred while saving the data') });
    } finally {
      setLoading(false);
      refreshTrigger();
    }
  };

  return (
    <Modal 
      open={open} 
      // onClose={closeForm}
      onClose={() => {
        setErrors({});
        closeForm()
      }}
    >
      <Modal.Header>{object ? t(`admin-edit-${endpoint}`) : t(`admin-new-${endpoint}`)}</Modal.Header>
      <Modal.Content>
        <Form loading={loading} error={!!Object.keys(errors).length}>
          {fields.filter(field => field.inForm).map(field => (
            <Form.Field key={field.key} error={!!errors[field.key]}>
              <label>{field.label}</label>
              {field.type === 'text' && (
                <Input
                  type='text'
                  name={field.key}
                  value={formData[field.key] || ''}
                  onChange={handleChange}
                />
              )}
              {field.type === 'dropdown' && (
                <Dropdown
                  name={field.key}
                  selection
                  search
                  options={field.options}
                  value={formData[field.key] || ''}
                  onChange={handleChange}
                />
              )}
              {field.type === 'checkbox' && (
                <Checkbox
                  name={field.key}
                  checked={!!formData[field.key]}
                  onChange={handleChange}
                />
              )}
              {errors[field.key] && (
                <span style={{ color: 'red' }}>{errors[field.key]}</span>
              )}
            </Form.Field>
          ))}
          {errors.submit && (
            <Message
              error
              header={t('Error')}
              content={errors.submit}
            />
          )}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button color='black' onClick={closeForm}>
          {t('cancel')}
        </Button>
        <Button
          content={t('save')}
          labelPosition='right'
          icon='checkmark'
          onClick={handleSubmit}
          positive
        />
      </Modal.Actions>
    </Modal>
  );
}

export default AdminApiTableForm;
