import { atom } from 'jotai';
import {
    brandsAtom,
    productsAtom,
    productGroupsAtom,
    productRelationsAtom,
} from '../state.js'; 
import { filteredProductsOptionsAtom } from './filteredProductsAtom.js';

export const relevantBrandsAtom = atom((get) => {
  const brands = get(brandsAtom);
  const products = get(productsAtom);
  const productGroups = get(productGroupsAtom);
  const productRelations = get(productRelationsAtom);
  const options = get(filteredProductsOptionsAtom);

  const groupId = options.tag ? productGroups[options.tag]?.id : null;
  const relevantBrands = [];

  if (options.parent_id) {
    const relations = Object.values(productRelations).filter(relation => relation.parent_product_id === options.parent_id);
    const childIds = relations.map(relation => relation.child_product_id);
    Object.values(products).forEach(product => {
      if (childIds.includes(product.id)) {
        const brand = Object.values(brands).find(brand => brand.id === product.brand_id);
        if (!brand) return;

        if (!relevantBrands.includes(brand)) {
          relevantBrands.push(brand);
        }
      }
    });

    relevantBrands.sort((a, b) => {
      const aValue = a.name || '';
      const bValue = b.name || '';
      return aValue.localeCompare(bValue);
    });

    return relevantBrands;
  }

  Object.values(products).forEach(product => {
    if (groupId && !product.product_group_ids?.includes(groupId)) return;

    const brand = Object.values(brands).find(brand => brand.id === product.brand_id);
    if (!brand) return;

    if (!relevantBrands.includes(brand)) {
      relevantBrands.push(brand);
    }
  });

  relevantBrands.sort((a, b) => {
    const aValue = a.name || '';
    const bValue = b.name || '';
    return aValue.localeCompare(bValue);
  });

  return relevantBrands;
});

export const relevantBrandsSelectAtom = atom((get) => {
  const brands = get(relevantBrandsAtom);
  const options = [];

  brands.forEach(brand => {
    options.push({
      key: brand.id,
      value: brand.id,
      text: brand.name,
    });
  }
  );

  return options;
});
