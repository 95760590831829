import React, { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { Link } from "react-router-dom";
import { Button, Image, Table } from "semantic-ui-react";
import { currenciesAtom, productsByIdAtom, showVatAtom, webpSupportedAtom } from "../state";
import { userAtom } from "../atoms/authAtom";
import { formattedPrice } from "../utils/price";
import { primaryThumbnailUrl } from "../utils/thumbnailUrl.js";

const OrderRow = ({ currency, item, picked, pickCallback, unpickCallback, shipped }) => {
  const [currencies] = useAtom(currenciesAtom);
  const [productsById] = useAtom(productsByIdAtom);
  const [showVat] = useAtom(showVatAtom);
  const [user] = useAtom(userAtom);
  const [webpSupported] = useAtom(webpSupportedAtom);

  const [price, setPrice] = useState(null);
  const [subTotal, setSubTotal] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);

  useEffect(() => {
    const product = productsById[item.product.id];

    if (!product) {
      return;
    }

    if (product.images && product.images.length > 0) {
      setThumbnail(primaryThumbnailUrl(product.images, webpSupported, product.url));
    } else {
      setThumbnail("/assets/placeholder.png");
    }
  }, [item.product.id, item.product.images, productsById, webpSupported]);

  useEffect(() => {
    if (showVat) {
      setPrice(item?.unit_price_incl_vat);
      setSubTotal(item?.subtotal_incl_vat);
    } else {
      setPrice(item?.unit_price_excl_vat);
      setSubTotal(item?.subtotal_excl_vat);
    }
  }, [item, showVat]);

  return (
    <Table.Row key={item.id}>
      <Table.Cell>
        <Link to={`/products/${item.product.url}`}>
          <Image
            src={thumbnail}
            size="tiny"
            verticalAlign="middle"
            style={{ height: "4em", width: "4em", objectFit: "contain", marginRight: "1em" }}
          />
          <div style={{ display: "inline-block", verticalAlign: "middle" }}>
            <strong>{item.product.part_number}</strong>
            <br />
            {item.product.name}
          </div>
        </Link>
      </Table.Cell>
      <Table.Cell collapsing textAlign="right">
        {formattedPrice(price, currencies[currency]?.symbol, currencies[currency]?.right_side)}
      </Table.Cell>
      <Table.Cell collapsing textAlign="right">
        {item.quantity}
      </Table.Cell>
      <Table.Cell collapsing textAlign="right">
        {formattedPrice(subTotal, currencies[currency]?.symbol, currencies[currency]?.right_side)}
      </Table.Cell>
      {user?.admin && (
        <>
          <Table.Cell collapsing>
            {item.inventory_locations?.map((location, index) => (
              <div key={index}>
                {location.name && (
                  <>
                    {location.change} <span style={{ color: "gray" }}>@</span> {location.name}
                  </>
                )}
              </div>
            ))}
          </Table.Cell>
          {!shipped && (
            <Table.Cell collapsing textAlign="right">
              <Button icon="minus" color="red" onClick={() => unpickCallback(item)} disabled={!picked || picked <= 0} />
              <span className="no-select" style={{ marginLeft: "0.5em", marginRight: "0.5em" }}>
                ({picked || 0} / {item.quantity})
              </span>
              <Button icon="plus" color="green" onClick={() => pickCallback(item)} disabled={picked >= item.quantity} />
            </Table.Cell>
          )}
          <Table.Cell collapsing textAlign="right">
            {productsById[item.product.id]?.stock}
          </Table.Cell>
        </>
      )}
    </Table.Row>
  );
};

export default OrderRow;
