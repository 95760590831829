import React, { useState } from 'react';
import { useAtom } from 'jotai';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Form, Header, Table } from 'semantic-ui-react';
import {
  inventoryLocationsAtom,
} from '../state.js';
import { inventoryLocationsOptionsAtom } from '../atoms/inventoryLocationOptions.js';
import { userAtom } from '../atoms/authAtom';
import AdminInventoryRow from './AdminInventoryRow.js';

const AdminInventories = ({ product }) => {
  const [inventoryLocations] = useAtom(inventoryLocationsAtom);
  const [inventoryLocationOptions] = useAtom(inventoryLocationsOptionsAtom);
  const [user] = useAtom(userAtom);

  const [newLocation, setNewLocation] = useState(0);
  const [newLocationLoading, setNewLocationLoading] = useState(false);

  const queryClient = useQueryClient();

  const addLocation = async (data) => {
    const [productId, inventoryLocationId] = data;
    const response = await fetch(`/api/inventories`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ "product_id": productId, "inventory_location_id": inventoryLocationId }),
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  }

  const addLocationMutation = useMutation({
    mutationFn: addLocation,
    onMutate: (data) => {
      setNewLocationLoading(true);
    },
    onSuccess: (data) => {
      setNewLocationLoading(false);
      setNewLocation(0);
    },
  });

  if (!user?.admin) {
    return (
      <></>
    );
  }

  return (
    <>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Location</Table.HeaderCell>
            <Table.HeaderCell>#</Table.HeaderCell>
            <Table.HeaderCell>- / +</Table.HeaderCell>
            <Table.HeaderCell>Desired</Table.HeaderCell>
            <Table.HeaderCell>Verify</Table.HeaderCell>
            <Table.HeaderCell>Last verified</Table.HeaderCell>
            <Table.HeaderCell>X</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {Object.values(product?.product_inventories || {})
            .sort((a, b) => a.id - b.id)
            .map((inventory) => (
              <AdminInventoryRow
                key={inventory.id}
                inventory={inventory}
              />
            ))}
        </Table.Body>
      </Table>
      <h3>Add inventory location</h3>
      <Form onSubmit={() => addLocationMutation.mutate([product.id, newLocation])}>
        <Form.Group widths='equal'>
          <Form.Dropdown
            placeholder='Select inventory location'
            fluid
            selection
            search={(options, query) => {
              const filteredOptions = options.filter((option) =>
                option.text.toLowerCase().includes(query.toLowerCase())
              );
              return filteredOptions.slice(0, 50);
            }}
            minCharacters={2}
            options={inventoryLocationOptions}
            onChange={(e, { value }) => setNewLocation(value)}
          />
          <Form.Button
            positive
            disabled={newLocation === 0}
            loading={newLocationLoading}
          >
            Add
          </Form.Button>
        </Form.Group>
      </Form>
    </>
  );
}

export default AdminInventories;
