import React, { useState } from 'react';
import { useAtom } from 'jotai';
import { Button, Form, Input, Table } from 'semantic-ui-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  inventoryLocationsAtom,
} from '../state.js';
import { userAtom } from '../atoms/authAtom';

const AdminInventoryRow = ({ inventory }) => {
  const [inventoryLocations] = useAtom(inventoryLocationsAtom);
  const [user] = useAtom(userAtom);

  const queryClient = useQueryClient();

  const [change, setChange] = useState(0);
  const [changeLoading, setChangeLoading] = useState(false);
  const [desired, setDesired] = useState(inventory.desired_stock);
  const [desiredLoading, setDesiredLoading] = useState(false);
  const [deleteInventoryLoading, setDeleteInventoryLoading] = useState(false);
  const [verifyInventoryLoading, setVerifyInventoryLoading] = useState(false);

  const changeInventory = async (data) => {
    const [inventoryId, v] = data;
    const n = parseInt(v);
    const response = await fetch(`/api/stock/${inventoryId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ "change": n }),
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  }

  const changeDesired = async (data) => {
    const [inventoryId, v] = data;
    const n = parseInt(v);
    const response = await fetch(`/api/inventories/${inventoryId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ "desired_stock": n }),
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  }

  const deleteInventory = async (inventoryId) => {
    const response = await fetch(`/api/inventories/${inventoryId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  }

  const verifyInventory = async (inventoryId) => {
    const response = await fetch(`/api/inventory_verify/${inventoryId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  }

  const changeInventoryMutation = useMutation({
    mutationFn: changeInventory,
    onMutate: (data) => {
      setChangeLoading(true);
    },
    onSuccess: (data) => {
      setChangeLoading(false);
      setChange(0);
    },
  });

  const changeDesiredMutation = useMutation({
    mutationFn: changeDesired,
    onMutate: (data) => {
      setDesiredLoading(true);
    },
    onSuccess: (data) => {
      setDesiredLoading(false);
    },
  });

  const deleteInventoryMutation = useMutation({
    mutationFn: deleteInventory,
    onMutate: (data) => {
      setDeleteInventoryLoading(true);
    },
    onSuccess: (data) => {
      setDeleteInventoryLoading(false);
    },
  });

  const verifyInventoryMutation = useMutation({
    mutationFn: verifyInventory,
    onMutate: (data) => {
      setVerifyInventoryLoading(true);
    },
    onSuccess: (data) => {
      setVerifyInventoryLoading(false);
    },
  });

  if (!user?.admin) {
    return (
      <></>
    );
  }

  return (
    <Table.Row key={inventory.id}>
      <Table.Cell>{inventoryLocations[inventory.inventory_location_id]?.name}</Table.Cell>
      <Table.Cell>{inventory.stock}</Table.Cell>
      <Table.Cell>
        <Form onSubmit={(e) => changeInventoryMutation.mutate([inventory.id, change])}>
          <Form.Input
            disabled={changeLoading}
            type='number'
            min={inventory.stock * -1}
            max={9999}
            style={{ width: '6em' }}
            action={{
              color: 'green',
              disabled: false,
              icon: 'refresh',
              size: 'small',
              loading: changeLoading,
            }}
            onClick={(e) => {
              e.stopPropagation()
            }}
            onChange={(e) => setChange(e.target.value)}
            placeholder='#'
            value={change}
            size='small'
          />
        </Form>
      </Table.Cell>
      <Table.Cell>
        <Form onSubmit={(e) => changeDesiredMutation.mutate([inventory.id, desired])}>
          <Form.Input
            disabled={desiredLoading}
            type='number'
            min={0}
            max={9999}
            style={{ width: '6em' }}
            action={{
              color: 'green',
              disabled: false,
              icon: 'refresh',
              size: 'small',
              loading: desiredLoading,
            }}
            onClick={(e) => {
              e.stopPropagation()
            }}
            onChange={(e) => setDesired(e.target.value)}
            placeholder='#'
            value={desired}
            size='small'
          />
        </Form>
      </Table.Cell>
      <Table.Cell verticalAlign='middle'>
            <Button size='small' 
              loading={verifyInventoryLoading} 
              color='green'
              icon='check'
              attached='left'
              onClick={(e) => {
                e.stopPropagation()
                verifyInventoryMutation.mutate(inventory.id);
              }}
              />
      </Table.Cell>
      <Table.Cell>
        {inventory.counted_at && (
          <>
          { new Date(inventory.counted_at).toLocaleString() } < br />
          { inventory.counted_by_customer_name }
          </>
        )}
      </Table.Cell>
      <Table.Cell>
        <Form.Button
          color='red'
          size='small'
          icon='trash'
          disabled={inventory.stock !== 0}
          loading={deleteInventoryLoading}
          onClick={(e) => {
            e.stopPropagation()
            deleteInventoryMutation.mutate(inventory.id);
          }}
        >
        </Form.Button>
      </Table.Cell>
    </Table.Row>
  );
}

export default AdminInventoryRow;
