import { atom } from 'jotai';
import {
  productsAtom,
  productGroupsAtom,
  productRelationsAtom,
} from '../state.js';
import { filteredProductsOptionsAtom } from './filteredProductsAtom.js';

export const relevantFitsAtom = atom((get) => {
  const products = get(productsAtom);
  const productGroups = get(productGroupsAtom);
  const productRelations = get(productRelationsAtom);
  const options = get(filteredProductsOptionsAtom);

  const group = options.tag ? productGroups[options.tag] : null;
  const relevantFits = [];

  Object.values(products).forEach(product => {
    if (!group || !product.product_group_ids?.includes(group?.related_product_group_id)) return;
    if (!product.primary) return;

    relevantFits.push(product);
  });

  relevantFits.sort((a, b) => {
    const aValue = a.short_name || '';
    const bValue = b.short_name || '';
    return aValue.localeCompare(bValue);
  });

  return relevantFits;
});

export const relevantFitsSelectAtom = atom((get) => {
  const relevantFits = get(relevantFitsAtom);

  return relevantFits.map(product => ({
    key: product.url,
    value: product.id,
    text: product.short_name,
  }));
});
