import { atom } from 'jotai';
import {
  inventoryLocationsAtom,
} from '../state.js';

export const inventoryLocationsOptionsAtom = atom((get) => {
  const inventoryLocations = get(inventoryLocationsAtom);

  return Object.values(inventoryLocations)
    .filter((inventoryLocation) => inventoryLocation.published)
    .map((inventoryLocation) => ({
      key: inventoryLocation.id,
      text: inventoryLocation.name,
      value: inventoryLocation.id,
    }));
});
