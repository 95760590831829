import React, { useState } from 'react';
import { useAtom } from 'jotai';
import { } from '@tanstack/react-query';
import { Form, Tab, Table } from 'semantic-ui-react';
import {
} from '../state.js';
import { userAtom } from '../atoms/authAtom';
import AdminPriceRow from './AdminPriceRow.js';

const AdminPrices = ({ product }) => {
  const [user] = useAtom(userAtom);

  const [showVat, setShowVat] = useState(false);
  const [vatPercentage, setVatPercentage] = useState(25);

  if (!user?.admin) {
    return (
      <></>
    );
  }

  return (
    <>
      <Form>
        <Form.Group inline>
          <Form.Checkbox
            label='Including'
            checked={showVat}
            onChange={() => setShowVat(!showVat)}
          />
          <Form.Input
            type='number'
            value={vatPercentage}
            style={{ width: '5em' }}
            onChange={(e) => setVatPercentage(e.target.value)}
          />% VAT
        </Form.Group>
      </Form>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Price List</Table.HeaderCell>
            <Table.HeaderCell>Currency</Table.HeaderCell>
            <Table.HeaderCell>Price</Table.HeaderCell>
            <Table.HeaderCell>New price</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
            {product?.product_prices?.sort((a, b) => a.price_list_id - b.price_list_id)?.map((price) => (
            <AdminPriceRow
              key={price.id}
              price={price}
              showVat={showVat}
              vatPercentage={vatPercentage}
            />
          ))}
        </Table.Body>
      </Table>
    </>
  );
}

export default AdminPrices;
