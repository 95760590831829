import { useEffect } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useAtom } from 'jotai';
import { useQueryClient } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import {
  checkoutHtmlAtom,
} from '../state.js';
import { cartAtom } from '../atoms/cartAtom.js';

const useCancelCheckout = () => {
  const [cart, setCart] = useAtom(cartAtom);
  const [checkoutHtml, setCheckoutHtml] = useAtom(checkoutHtmlAtom);

  const queryClient = useQueryClient()

  async function postCancelCheckout(url) {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    return response.json();
  }

  const cancelCheckoutMutation = useMutation({
    mutationFn: (uuid) => postCancelCheckout(`/cancel_checkout/${uuid}`),
    onSuccess: (data) => {
      unstable_batchedUpdates(() => {
      })
    },
    onError: (error) => {
      unstable_batchedUpdates(() => {
      })
    },
    onLoading: () => {
      unstable_batchedUpdates(() => {
      })
    },
  });

  const cancelCheckout = () => {
    cancelCheckoutMutation.mutate(cart.uuid);
    setCart({ ...cart, checkingOut: false });
    setCheckoutHtml(null);
  };

  return {
    cancelCheckout
  };
};

export default useCancelCheckout;
