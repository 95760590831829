import React, { useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Button, Dimmer, DimmerDimmable, Divider, Grid, Image, Label, Modal } from 'semantic-ui-react';
import { smallThumbnailUrl, bigThumbnailUrl } from '../utils/thumbnailUrl';

const AdminProductImage = ({ product, image }) => {
  const [active, setActive] = useState(false);
  const [deleteImageLoading, setDeleteImageLoading] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [primaryLoading, setPrimaryLoading] = useState(false);

  const deleteImage = async (data) => {
    const [imageId] = data;
    const response = await fetch(`/api/product_images/${imageId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  }

  const setPrimary = async (data) => {
    const [imageId] = data;
    const response = await fetch(`/api/product_images/${imageId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ "primary": true }),
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  }

  const deleteImageMutation = useMutation({
    mutationFn: deleteImage,
    onMutate: (data) => {
      setDeleteImageLoading(true);
    },
    onError: (error, data, context) => {
      setDeleteImageLoading(false);
      setDeleteConfirmOpen(false);
      console.log(error);
    },
    onSuccess: (data) => {
      deleteImageLoading(false);
      setDeleteConfirmOpen(false);
    },
  });

  const setPrimaryMutation = useMutation({
    mutationFn: setPrimary,
    onMutate: (data) => {
      setPrimaryLoading(true);
    },
    onError: (error, data, context) => {
    },
    onSuccess: (data) => {
      setPrimaryLoading(false);
    },
  });

  useEffect(() => {
  }, []);

  return (
    <>
    <Modal
      open={deleteConfirmOpen}
      onClose={() => setDeleteConfirmOpen(false)}
      size='small'
      closeIcon
    >
      <Modal.Header>
        Are you sure you want to delete this image?
      </Modal.Header>
      <Modal.Content>
        <Image
          src={bigThumbnailUrl(image)}
          alt={product.part_number}
          centered
        />
      </Modal.Content>
      <Modal.Actions>
        <Button
          negative
          icon='cancel'
          content='No'
          onClick={() => setDeleteConfirmOpen(false)}
        />
        <Button
          positive
          icon='trash'
          content='Yes'
          loading={deleteImageLoading}
          onClick={() => {
            deleteImageMutation.mutate([image.id]);
          }}
        />
      </Modal.Actions>
    </Modal>

    <DimmerDimmable
      dimmed={active}
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
      size='medium'
      src={bigThumbnailUrl(image)}
    >
      {image.primary && (
        <Label
          corner='right'
          color='green'
          icon='star'
        />
      )}
      <Image
        src={bigThumbnailUrl(image)}
        alt={product.part_number}
        style={{ minHeight: '300px', objectFit: 'contain' }}
        centered
      />
      <Dimmer
        active={active}
        onClickOutside={() => setActive(false)}
      >
        <Button
          color='green'
          content='Make Primary'
          icon='star'
          loading={primaryLoading}
          onClick={() => setPrimaryMutation.mutate([image.id])}
          size='big'
          disabled={image.primary}
        />
        <Divider />
        <Button
          color='red'
          content='Delete'
          icon='trash'
          onClick={() => setDeleteConfirmOpen(true)}
          size='big'
        />
      </Dimmer>
    </DimmerDimmable>
    </>
  );
}

export default AdminProductImage;
