import React, { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import {
} from '../state.js';
import { userAtom } from '../atoms/authAtom';
import { Button, Icon, Menu, Table } from 'semantic-ui-react';
import Title from '../components/Title.js';
import ErrorPage from '../ErrorPage.js';
import AdminApiTable from '../components/AdminApiTable.js';
import AdminCustomerDetails from '../components/AdminCustomerDetails.js';

const AdminCustomers = () => {
  const { t } = useTranslation();

  const [user] = useAtom(userAtom);

  if (!user?.admin) {
    return (
      <ErrorPage />
    );
  }

  return (
    <>
      <Title title={t('admin-customers')} />

      <AdminApiTable
        endpoint='customers'
        fields={[
          { key: 'id', label: 'ID', inTable: true },
          { key: 'name', label: 'Name', inTable: true },
          { key: 'company', label: 'Company', inTable: true },
          { key: 'email', label: 'Email', inTable: true },
        ]}
        DetailComponent={AdminCustomerDetails}
        newEnabled={false}
      />
    </>
  );
}

export default AdminCustomers;
