import React, { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { useMutation } from '@tanstack/react-query';
import { Divider, Form, Icon, Label } from 'semantic-ui-react';
import {
  productsAtom,
  productRelationsAtom,
} from '../state.js';
import { userAtom } from '../atoms/authAtom.js';
import ErrorPage from '../ErrorPage.js';

const AdminProductFits = ({ product }) => {
  const [products] = useAtom(productsAtom);
  const [productRelations] = useAtom(productRelationsAtom);
  const [user] = useAtom(userAtom);

  const [fits, setFits] = useState([]);
  const [loading, setLoading] = useState(false);
  const [primaryProducts, setPrimaryProducts] = useState([]);
  const [selectedPrimaryProduct, setSelectedPrimaryProduct] = useState(null);

  const createRelation = async (data) => {
    const [parentId, childId] = data;
    const response = await fetch(`/api/product_relations`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        parent_product_id: parentId,
        child_product_id: childId,
      }),
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  }

  const deleteRelation = async (data) => {
    const [relationId] = data;
    const response = await fetch(`/api/product_relations/${relationId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  }

  const createRelationMutation = useMutation({
    mutationFn: createRelation,
    onMutate: (data) => {
      setLoading(true);
    },
    onSuccess: (data) => {
      setLoading(false);
    },
  });

  const deleteRelationMutation = useMutation({
    mutationFn: deleteRelation,
    onMutate: (data) => {
      setLoading(true);
    },
    onSuccess: (data) => {
      setLoading(false);
    },
  });

  useEffect(() => {
    // Is productRelations ready, as in is it not null and is an array?
    if (productRelations && Array.isArray(productRelations)) {
      const relations = productRelations.filter(relation => relation.child_product_id === product.id).map(relation => {
        const parentProduct = Object.values(products).find(product => product.id === relation.parent_product_id);
        relation.product = parentProduct;
        return relation;
      });

      setFits(relations);

      const primaryProducts = Object.values(products).filter(product => product.primary && !relations.some(relation => relation.product.id === product.id));
      setPrimaryProducts(primaryProducts);
    }
  }, [product, products, productRelations]);

  if (!user?.admin) {
    return (
      <ErrorPage />
    );
  }

  return (
    <>
      <Label.Group>
        {fits?.sort((a, b) => a.product.short_name.localeCompare(b.product.short_name)).map((fit, index) => {
          return (
            <Label key={fit.product.id}>
              {fit.product.short_name || fit.product.name}
              <Icon name='delete'
                onClick={(e) => {
                  e.preventDefault();
                  deleteRelationMutation.mutate([fit.id]);
                }}
              />
            </Label>
          );
        })}
      </Label.Group>
      <Form loading={loading}>
        <Form.Dropdown
          fluid
          search
          selection
          options={primaryProducts.map(product => ({
            key: product.id,
            text: product.short_name || product.name,
            value: product.id,
          }))
            .sort((a, b) => a.text.localeCompare(b.text))}
          onChange={(e, { value }) => {
            setSelectedPrimaryProduct(value);
          }}
        />
        <Form.Button
          positive
          onClick={(e) => {
            e.preventDefault();
            createRelationMutation.mutate([selectedPrimaryProduct, product.id]);
          }}
        >
          Add
        </Form.Button>
      </Form>
    </>
  );
}

export default AdminProductFits;
