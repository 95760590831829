import React, { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { Card } from 'semantic-ui-react'
import InfiniteScroll from 'react-infinite-scroller';
import { useLocation } from "react-router-dom";
import {
} from '../state.js';
import { filteredProductsAtom } from '../atoms/filteredProductsAtom.js';
import ProductCard from './ProductCard.js';
import ProductFilter from './ProductFilter.js';

const ProductList = ({ disableFilter, primary }) => {
  const [filteredProducts] = useAtom(filteredProductsAtom);
  const [itemsCount, setItemsCount] = useState(40);

  const location = useLocation();

  useEffect(() => {
    setItemsCount(40);
  }, [location]);

  const loadMoreProducts = () => {
    setItemsCount(itemsCount + 40);
  };

  return (
    <React.Fragment>
      {!disableFilter && <ProductFilter primary={primary} />}
      <br />
      <InfiniteScroll
        loadMore={() => loadMoreProducts()}
        hasMore={itemsCount < (filteredProducts ? filteredProducts.length : 0)}
        loader={(
          <h4 key="scroll">Loading...</h4>
        )}
      >
        <Card.Group stackable doubling fluid style={{ margin: '1px' }}>
          {filteredProducts.slice(0, itemsCount).map(product => (
            <ProductCard key={product.part_number} product={product} />
          ))}
        </Card.Group>
      </InfiniteScroll>
    </React.Fragment>
  );
};

export default React.memo(ProductList);
