import React, { } from 'react';
import { useTranslation } from 'react-i18next';
import Title from '../components/Title.js';
import AdminProductDetails from './AdminProductDetails.js';

const AdminAddProduct = ({ }) => {
  const { t } = useTranslation();

  return (
    <>
      <Title title={t('admin-add_product')} />
      <br />
      <AdminProductDetails />
    </>
  );
}

export default React.memo(AdminAddProduct);
