import { atom } from 'jotai';
import {
  productsAtom,
} from '../state.js';

export const replacedBySearchAtom = atom('');

export const replacedByOptionsAtom = atom((get) => {
  const search = get(replacedBySearchAtom);
  const products = get(productsAtom);

  return Object.values(products)
    .filter((product) => 
      product.part_number.toLowerCase().includes(search.toLowerCase())
    )
    .slice(0, 10)
    .map((product) => ({
      key: product.id,
      text: `${product.part_number} - ${product.name}`,
      value: product.id,
    }));
});
