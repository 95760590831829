import React, { useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Dimmer, Icon, Loader, Segment, Table, Transition } from 'semantic-ui-react';
import AdminProductDownloadRow from './AdminProductDownloadRow.js';

const AdminProductDownloads = ({ product }) => {
  const [loading, setLoading] = useState(false);
  const [uploadAnimationVisible, setUploadAnimationVisible] = useState(false);

  const upload = async (data) => {
    const [productId, file] = data;
    const formData = new FormData();
    formData.append('files', file);
    formData.append('product_id', productId);
    const response = await fetch(`/api/product_downloads`, {
      method: 'POST',
      body: formData,
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  }

  const uploadMutation = useMutation({
    mutationFn: upload,
    onMutate: (data) => {
      setLoading(true);
    },
    onSuccess: (data) => {
      setLoading(false);
    },
  });

  useEffect(() => {
  }, []);

  return (
    <>
      <Segment
        placeholder
        textAlign='center'
        style={{ cursor: 'pointer' }}
        onDragOver={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setUploadAnimationVisible(!uploadAnimationVisible);
        }}
        onDrop={(e) => {
          e.preventDefault();
          e.stopPropagation();
          const file = e.dataTransfer.files[0];
          uploadMutation.mutate([product.id, file]);
        }}
        onClick={() => {
          const input = document.createElement('input');
          input.type = 'file';
          input.accept = '*';
          input.onchange = (e) => {
            const file = e.target.files[0];
            uploadMutation.mutate([product.id, file]);
          };
          input.click();
        }}
      >
        <Dimmer active={loading}>
          <Loader active={loading} />
        </Dimmer>
        <Transition visible={!uploadAnimationVisible} animation='pulse' duration={500}>
          <div><Icon name='plus' size='massive' /></div>
        </Transition>
      </Segment>
      <Table celled selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>File Name</Table.HeaderCell>
            <Table.HeaderCell>File Size</Table.HeaderCell>
            <Table.HeaderCell>Delete</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {product?.product_downloads?.map(download => (
            <AdminProductDownloadRow
              key={download.id}
              download={download}
            />
          ))}
        </Table.Body>
      </Table>
    </>
  );
}

export default React.memo(AdminProductDownloads);
