import React, { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { Divider, Input, Segment } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next';
import { fullBundleFinishedAtom } from '../state.js';
import { searchProductsOptionsAtom } from '../atoms/searchProductsAtom.js';
import SearchResults from './SearchResults';

const MobileSearch = ({ }) => {
  const [fullBundleFinished] = useAtom(fullBundleFinishedAtom);
  const [searchProductsOptions, setSearchProductOptions] = useAtom(searchProductsOptionsAtom);
  const [popupSearchResultsOpen, setPopupSearchResultsOpen] = useState(false);

  const { t } = useTranslation();

  return (
    <Segment basic>

      <Input
        tabIndex={0}
        icon='search'
        placeholder={t('search')}
        loading={!fullBundleFinished}
        onChange={(e) => {
          setSearchProductOptions(e.target.value);
        }}
        value={searchProductsOptions}
        onClick={() => setPopupSearchResultsOpen(true)}
        style={{ marginTop: '0.5em', marginLeft: '0px', marginRight: '0px', marginBottom: '0px', padding: '0px', width: '100%' }}
      />

      <Divider />

      <SearchResults />
    </Segment>
  )
}

export default MobileSearch;
