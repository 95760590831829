import React, { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { unstable_batchedUpdates } from 'react-dom';
import { Button, Divider, Form, Header, Message, Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import {
  showVatAtom,
  specialPricesAtom,
} from '../state';
import { authenticatedAtom, userAtom, userPopupOpenAtom } from '../atoms/authAtom';

const fetcher = async (url, method = 'POST', body = null) => {
  const options = {
    method,
    headers: { 'Content-Type': 'application/json' },
    body: body ? JSON.stringify(body) : null,
  };

  const response = await fetch(url, options);
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return response.json();
};

const User = () => {
  const [authenticated, setAuthenticated] = useAtom(authenticatedAtom);
  const [user, setUser] = useAtom(userAtom);
  const [open, setOpen] = useAtom(userPopupOpenAtom);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showVat, setShowVat] = useAtom(showVatAtom);
  const [specialPrices, setSpecialPrices] = useAtom(specialPricesAtom);

  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const loginMutation = useMutation({
    mutationFn: (data) => fetcher('/login', 'POST', data),
    onSuccess: (data) => {
      unstable_batchedUpdates(() => {
        if (data.success) {
          setUser({
            email: data.email,
            name: data.name,
            company: data.company,
            b2b: data.b2b,
            admin: data.admin,
          });
          setAuthenticated(true);
          if (data.b2b) {
            setShowVat(false);
          }
        }
      });
      if (location.pathname === '/reset-password') {
        navigate('/')
      }
      if (data?.b2b || data?.admin || data?.special_prices) {
        window.location.reload()
      }
    },
  });

  const logoutMutation = useMutation({
    mutationFn: () => fetcher('/logout'),
    onSuccess: (data) => {
      const wasAdmin = user.admin;
      const wasB2B = user.b2b;
      unstable_batchedUpdates(() => {
        if (data.success) {
          setUser({});
          setAuthenticated(false);
          setShowVat(true);
          setSpecialPrices({});
        }
      });
      if (wasAdmin) {
        window.location.reload()
      }
    },
  });

  const handleLogin = () => loginMutation.mutate({ email, password });
  const handleLogout = () => logoutMutation.mutate();

  if (authenticated) {
    return (
      <Segment basic textAlign='center'>
        <Header as='h3' textAlign='center'>
          {user.name}
          <Header.Subheader>
            {user.company}
          </Header.Subheader>
          <Header.Subheader>
            {user.email}
          </Header.Subheader>
        </Header>
        <Divider />
        <Link to='/orders' onClick={() => setOpen(false)}>{t('my_orders')}</Link>
        <Divider />
        <Button negative onClick={handleLogout}>{t('logout')}</Button>
      </Segment>
    );
  }

  return (
    <Segment basic style={{ minWidth: '20em' }}>
      <Form>
        <Form.Input
          fluid
          type='email'
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          icon='user'
          iconPosition='left'
          placeholder={t('email_address')}
        />
        <Form.Input
          fluid
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          icon='lock'
          iconPosition='left'
          placeholder={t('password')}
          type='password'
        />
        <Button positive fluid size='large' onClick={handleLogin} loading={loginMutation.isLoading}>
          {t('login')}
        </Button>
        {loginMutation.isError ? (
          <Message negative>
            <Message.Header>{t('error')}</Message.Header>
            <p>{t('login_failed')}</p>
          </Message>
        ) : null}
      </Form>
      <Segment basic textAlign='center' style={{ padding: '0.3em' }}>
        <Link to='/reset-password' onClick={() => setOpen(false)}>{t('forgot_your_password')}</Link>
      </Segment>
      {/* <Divider horizontal>{t('or')}</Divider>
      <Button secondary fluid size='large' as={Link} to='/register' onClick={() => setOpen(false)}>
        {t('register')}
      </Button> */}
    </Segment>
  );
}

export default User;
