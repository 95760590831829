import { atom } from 'jotai';
import {
  brandsAtom,
} from '../state.js';

export const brandOptionsAtom = atom((get) => {
  const brands = get(brandsAtom);

  return Object.values(brands)
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((brand) => ({
      key: brand.id,
      text: brand.name,
      value: brand.id,
    }));
});
