import React, { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { Card } from 'semantic-ui-react'
import { searchProductsAtom, searchProductsOptionsAtom } from '../atoms/searchProductsAtom.js';
import ProductCard from './ProductCard.js';

const SearchResults = ({ }) => {
  const [searchProducts] = useAtom(searchProductsAtom);
  const [searchProductsOptions] = useAtom(searchProductsOptionsAtom);

  if (searchProductsOptions.length === 0 || Object.keys(searchProducts).length === 0) {
    return <p>No results</p>;
  }

  return (
    <Card.Group>
      {Object.values(searchProducts).slice(0, 20).map(product => (
        <ProductCard key={product.part_number} product={product} />
      ))}
    </Card.Group>
  )
}

export default SearchResults;
