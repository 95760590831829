import React, { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Divider, Grid, Header, Icon, Loader, Message, Table } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { countriesAtom, currenciesAtom, showVatAtom } from "../state";
import { userAtom } from "../atoms/authAtom";
import { formattedPrice } from "../utils/price";
import OrderRow from "../components/OrderRow.js";
import Title from "../components/Title.js";
import AdminOrderStatus from "../admin/AdminOrderStatus.js";

const fetchOrder = async ({ queryKey }) => {
  const [, orderId] = queryKey;
  const response = await fetch(`/userapi/orders/${orderId}`);
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
};

const mutateOrder = async (variables) => {
  const { orderId, data } = variables;
  const response = await fetch(`/api/orders/${orderId}/shipments`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
};

const fetchInventoryLogs = async ({ queryKey }) => {
  const [, orderId, admin] = queryKey;
  if (!admin) {
    return [];
  }

  const response = await fetch(`/api/orders/${orderId}/inventory_logs`);
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
};

const OrderDetails = ({ orderId, adminMode }) => {
  const queryClient = useQueryClient();

  const [countries] = useAtom(countriesAtom);
  const [currencies] = useAtom(currenciesAtom);
  const [showVat] = useAtom(showVatAtom);
  const [user] = useAtom(userAtom);

  const { t } = useTranslation();

  const {
    data: orderData,
    isLoading: orderIsLoading,
    isError: orderIsError,
    error: orderError,
  } = useQuery({
    queryKey: ["order", orderId],
    queryFn: fetchOrder,
  });

  const mutation = useMutation({
    mutationFn: mutateOrder,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["order", orderId]);
      queryClient.invalidateQueries(["adminOrders"]);
      queryClient.invalidateQueries(["orders"]);
    },
  });

  const {
    data: inventoryLogsData,
    isLoading: logsIsLoading,
    isError: logsIsError,
    error: logsError,
  } = useQuery({
    queryKey: ["orderInventoryLogs", orderId, user?.admin],
    queryFn: fetchInventoryLogs,
  });

  const order = orderData?.order;

  const shipments = orderData?.shipments;

  const [countryName, setCountryName] = useState("");
  const [customer, setCustomer] = useState({
    name: "",
    company: "",
    address1: "",
    address2: "",
    postal_code: "",
    city: "",
    email: "",
    phone: "",
  });
  const [messageSeverity, setMessageSeverity] = useState("info");
  const [message, setMessage] = useState(null);
  const [picked, setPicked] = useState({});
  const [total, setTotal] = useState(0);
  const [tracking, setTracking] = useState(null);

  const [sortedOrderRows, setSortedOrderRows] = useState([]);

  useEffect(() => {
    if (order?.order_rows) {
      const total = order?.order_rows.reduce((acc, item) => {
        let subtotal = 0;
        if (!showVat) {
          subtotal = Number(item.subtotal_excl_vat);
        } else {
          subtotal = Number(item.subtotal_incl_vat);
        }
        return acc + subtotal;
      }, 0);
      setTotal(total);

      const country = countries[order?.customer?.country_id];
      if (country) {
        setCountryName(country.name);
      }

      setCustomer({
        name: order?.customer?.name,
        company: order?.customer?.company,
        address1: order?.customer?.address1,
        address2: order?.customer?.address2,
        postal_code: order?.customer?.postal_code,
        city: order?.customer?.city,
        email: order?.customer?.email,
        phone: order?.customer?.phone,
      });
    }

    if (order?.shipped) {
      setMessageSeverity("info");
      setMessage(t("this_order_has_been_shipped") + ".");

      const trackingRef = Object.values(shipments)[0]?.tracking_reference;
      if (trackingRef && trackingRef !== "") {
        setTracking(trackingRef);
      }
    } else {
      setMessageSeverity("warning");
      setMessage(t("this_order_has_not_been_shipped") + ".");
    }
  }, [countries, order, shipments, showVat, t]);

  useEffect(() => {
    if (inventoryLogsData && inventoryLogsData.logs && order) {
      const updatedOrderRows = order.order_rows.map((row) => {
        const log = inventoryLogsData.logs.find((log) => log.product_id === row.product_id);
        if (!row.inventory_locations) {
          row.inventory_locations = [];
        }
        if (log) {
          row.inventory_locations.push({
            name: log.inventory_location,
            change: log.change,
          });
        }
        return row;
      });

      // Sort order rows by inventory location name
      const sortedRows = updatedOrderRows.sort((a, b) => {
        const locationA = a.inventory_locations[0]?.name || "";
        const locationB = b.inventory_locations[0]?.name || "";
        return locationA.localeCompare(locationB);
      });

      setSortedOrderRows(sortedRows);
    } else {
      if (order) {
        setSortedOrderRows(order.order_rows);
      }
    }
  }, [inventoryLogsData, order]);

  if (orderIsLoading || logsIsLoading) {
    return <Loader active inline="centered" />;
  }

  if (orderIsError) {
    return <Message error header="Error" content="Order could not be retrieved" />;
  }

  if (logsIsError) {
    return <Message error header="Error" content="Inventory logs could not be retrieved" />;
  }

  const pickCallback = (row) => {
    const newPicked = { ...picked };
    if (newPicked[row.id]) {
      newPicked[row.id] = newPicked[row.id] + 1;
    } else {
      newPicked[row.id] = 1;
    }
    setPicked(newPicked);
  };

  const unpickCallback = (row) => {
    const newPicked = { ...picked };
    if (newPicked[row.id]) {
      if (newPicked[row.id] === 1) {
        delete newPicked[row.id];
      } else {
        newPicked[row.id] = newPicked[row.id] - 1;
      }
    }
    setPicked(newPicked);
  };

  return (
    <>
      <Title title={`Order #${order?.id || ""}`} subtitle={new Date(order?.created_at).toLocaleString()} />

      <Grid stretched doubling stackable columns={adminMode ? 3 : 2} style={{ marginTop: "1em" }}>
        <Grid.Column width={adminMode ? 6 : 8}>
          <Message>
            <Message.Content>
              <Message.Header>
                {customer.name}
                {customer.company && (
                  <>
                    <br />
                    <small>{customer.company}</small>
                  </>
                )}
              </Message.Header>
              <Divider />
              <Icon name="mail" />
              {customer.address1}
              <br />
              {customer.address2 && (
                <>
                  {customer.address2}
                  <br />
                </>
              )}
              {customer.postal_code} {customer.city}
              <br />
              {countryName}
              <br />
              <br />
              <Icon name="mail" />
              {customer.email}
              <br />
              <br />
              <Icon name="phone" />
              {customer.phone}
            </Message.Content>
          </Message>
        </Grid.Column>
        <Grid.Column width={adminMode ? 4 : 8}>
          <Message icon positive={messageSeverity === "info"} warning={messageSeverity === "warning"}>
            <Icon name="shipping fast" />
            <Message.Content>
              <Message.Header>{t("order_status")}</Message.Header>
              <p>
                <i>{order?.shipment_method?.product?.name}</i>
              </p>
              <p>{message}</p>
              {tracking && (
                <p>
                  {t("tracking")}:{" "}
                  <strong>
                    <a href={`${order.shipment_method?.tracking_url}${tracking}`} target="_blank" rel="noreferrer">
                      {tracking}
                    </a>
                  </strong>
                </p>
              )}
            </Message.Content>
          </Message>
        </Grid.Column>
        {adminMode && (
          <Grid.Column width={6}>
            <AdminOrderStatus order={order} shipments={shipments} mutation={mutation} picked={picked} />
          </Grid.Column>
        )}
      </Grid>
      <Table celled stackable striped selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{t("product")}</Table.HeaderCell>
            <Table.HeaderCell>{t("price")}</Table.HeaderCell>
            <Table.HeaderCell>{t("quantity")}</Table.HeaderCell>
            <Table.HeaderCell>{t("subtotal")}</Table.HeaderCell>
            {user?.admin && (
              <>
                <Table.HeaderCell>{t("inventory_location")}</Table.HeaderCell>
                {!order?.shipped && <Table.HeaderCell>{t("stock_pick")}</Table.HeaderCell>}
                <Table.HeaderCell>{t("remaining_in_stock")}</Table.HeaderCell>
              </>
            )}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {sortedOrderRows.map((item, index) => (
            <OrderRow
              currency={order?.currency_id}
              item={item}
              key={index}
              pickCallback={pickCallback}
              unpickCallback={unpickCallback}
              picked={picked[item.id]}
              shipped={order.shipped}
            />
          ))}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan={user?.admin ? "7" : "4"} textAlign="right">
              <Header as="h3">
                {formattedPrice(
                  total,
                  currencies[order?.currency_id]?.symbol,
                  currencies[order?.currency_id]?.right_side,
                )}
                <Header.Subheader>
                  <i>
                    {showVat ? `${t("including")} ${order?.vat_percent}% ` : t("excluding")} {t("vat")}
                  </i>
                </Header.Subheader>
              </Header>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </>
  );
};

export default React.memo(OrderDetails);
