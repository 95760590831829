import React, { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { Card, Divider, Image } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next';
import {
} from './state.js';
import { newProductsAtom } from './atoms/newProductsAtom.js';
import { promotedProductsAtom } from './atoms/promotedProductsAtom.js';
import {
  mobileSize,
} from './atoms/responsiveAtom';
import ProductCard from './components/ProductCard.js';
import Title from './components/Title.js';

const Home = () => {
  const [newProducts] = useAtom(newProductsAtom);
  const [promotedProducts] = useAtom(promotedProductsAtom);

  const { t } = useTranslation();

  return (
    <>
      {window.innerWidth < mobileSize && (
        <Image src="/assets/logos/latera-logo-black.svg" alt="Logo" style={{ width: '80%', marginBottom: '2em' }} centered />
      )}
      <Title title={t('popular_and_recommended')} />
      <Divider hidden />

      <Card.Group stackable doubling fluid style={{ margin: '1px' }}>
        {promotedProducts.map(product => (
          <ProductCard key={product.id} product={product} />
        ))}
      </Card.Group>

      <Divider hidden />

      <Title title={t('news')} />
      <Divider hidden />

      <Card.Group stackable doubling fluid style={{ margin: '1px' }}>
        {newProducts.map(product => (
          <ProductCard key={product.id} product={product} />
        ))}
      </Card.Group>
    </>
  )
}

export default Home;
